import './Datenschutz.css';
import React, {useEffect } from 'react';

function Datenschutz() {

      useEffect(() => {
        document.title = "Go With The Gurus | Datenschutz";
        window.scrollTo(0, 0); // Scrollt beim Laden der Seite nach oben
      }); 
    return (
        <>
        <div className="datenschutz-image"></div>
            <div className="impressum-container">
                <br></br>
                <h1>Datenschutz</h1>
                für die Webseite 
                <strong> gowiththegurus.com</strong> 
                <br></br>
                Stand: März 2025
                <br></br>
                <br></br>
                <h1>Allgemeines zur Datenverarbeitung</h1>
                Die Nutzung dieser Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Es werden keine Cookies gesetzt, kein Tracking eingesetzt und keine Nutzungsprofile erstellt.
                <br></br>
                <br></br>
                <h1>Formulare</h1>
                Wenn du ein Formular auf der Webseite nutzt, werden deine Angaben (z. B. Name, E-Mail-Adresse und Nachricht) über den Dienstleister Netlify Forms an mich übermittelt.
                <br></br>
                <br></br>
                <strong>Zweck der Verarbeitung</strong> 
                <br></br>
                Die Daten werden ausschließlich zur Bearbeitung deines Vorschlags oder Aufnahme in den Newsletter-Verteiler verwendet.
                <br></br>
                <br></br>
                <strong>Rechtsgrundlage</strong> 
                <br></br>
                Die Verarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) bzw. Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse an Kommunikation mit Nutzern).  
                <br></br>
                <br></br>
                <strong>Empfänger der Daten</strong> 
                <br></br>
                Netlify, Inc.
                <br></br>
                44 Montgomery Street, Suite 300,
                <br></br>
                San Francisco, CA 94104, USA
                <br></br>
                <a href='https://www.netlify.com/'>www.Netlify.com</a>
                <br></br>
                <br></br>
                Netlify verarbeitet die Daten in unserem Auftrag, kann jedoch unter US-Recht bestimmten Zugriffsanforderungen unterliegen.
                <br></br>
                <br></br>
                <strong>Dauer der Speicherung</strong> 
                <br></br>
                Die Daten werden so lange gespeichert, wie es für die Bearbeitung erforderlich ist. Danach werden sie gelöscht.
                <br></br>
                <br></br>
                <strong>Keine Weitergabe an Dritte</strong> 
                <br></br>
                Die Daten werden nicht an Dritte weitergegeben oder für andere Zwecke verwendet.
                <br></br>
                <br></br>
                <h1>Newsletter</h1>
                Auf der Webseite hast du die Möglichkeit, dich für den kostenlosen Newsletter anzumelden. Dabei werden dein Name (optional) und deine E-Mail-Adresse erfasst und über Mailchimp verarbeitet. Der Newsletter enthält Hinweise zu neuen 13F-Filings, Updates zur Plattform und neuen Blogbeiträgen.
                <br></br>
                <br></br>
                <strong>Rechtsgrundlage</strong> 
                <br></br>
                Die Datenverarbeitung erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO.
                <br></br>
                <br></br>
                <strong>Versanddienstleister</strong> 
                <br></br>
                Der Versand erfolgt über:
                <br></br>
                Intuit Inc. (Mailchimp)
                <br></br>
                675 Ponce de Leon Ave NE, Suite 5000
                <br></br>
                Atlanta, GA 30308, USA
                <br></br>
                <a href='https://www.mailchimp.com/'>www.Mailchimp.com</a>
                <br></br>
                <br></br>
                Mailchimp speichert Anmeldedaten, Versandzeiten sowie statistische Informationen (z. B. Öffnungen, Klicks), um den Newsletter zu optimieren. Die Verarbeitung erfolgt auf Servern in den USA. Mailchimp ist Teil des EU-U.S. Data Privacy Frameworks.
                <br></br>
                <br></br>
                <strong>Widerruf der Einwilligung</strong> 
                <br></br>
                Du kannst den Newsletter jederzeit über den Abmeldelink in jeder Mail oder durch eine formlose E-Mail an <a href='mailto:datenschutz@gowiththegurus.de'>datenschutz@gowiththegurus.de</a> abbestellen. Deine Daten werden anschließend gelöscht.
                <br></br>
                <h1>Deine Rechte</h1>
                Du hast gemäß DSGVO folgende Rechte:
                <br></br>
                <br></br>
                <li>Auskunft über deine gespeicherten Daten (Art. 15 DSGVO)</li>
                <li>Berichtigung unrichtiger Daten (Art. 16 DSGVO)</li>
                <li>Löschung („Recht auf Vergessenwerden“, Art. 17 DSGVO)</li>
                <li>Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
                <li>Datenübertragbarkeit (Art. 20 DSGVO)</li>
                <li>Widerspruch gegen die Verarbeitung (Art. 21 DSGVO)</li>
                <li>Widerruf einer erteilten Einwilligung (Art. 7 Abs. 3 DSGVO)</li>
                <li>Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO</li>
                <br></br>
                <br></br>
                Wenn du eines dieser Rechte ausüben möchtest, genügt eine E-Mail an:
                <br></br>
                <a href='mailto:datenschutz@gowiththegurus.de'>datenschutz@gowiththegurus.de</a>
                <br></br>
                <br></br>
                <h1>Beschwerderecht bei der Aufsichtsbehörde</h1>
                Wenn du glaubst, dass deine Daten unrechtmäßig verarbeitet werden, hast du das Recht, dich bei der zuständigen Datenschutzaufsichtsbehörde zu beschweren.
                <br></br>
                <h1>Änderungen dieser Datenschutzerklärung</h1>
                Diese Datenschutzerklärung kann bei Änderungen an der Webseite oder der rechtlichen Grundlage aktualisiert werden. 
                Die jeweils aktuelle Version ist jederzeit unter www.gowiththegurus.com/datenschutz abrufbar.
                <br></br>
                <br></br>


            </div>

        </>
    );
}

export default Datenschutz;
