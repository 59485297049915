import React, { useEffect } from "react";
import GuruTable from "./GuruTable";
import "./GuruOverviewPage.css";

function GuruOverviewPage() {

    // Tab-Titel mit document.title setzen
    useEffect(() => {
      document.title = "Guru Liste - Alle Super Investoren, die wir tracken.";
      window.scrollTo(0, 0); // Scrollt beim Laden der Seite nach oben
    }, []); // Leeres Array sorgt dafür, dass der Effekt nur einmal bei Mount ausgeführt wird

  return (<>
      <div className="overview-header"></div>
    <div className="guru-overview-page-container">
      <div className="headline-guru-overview-page">Gurus</div>
      <div className="guru-overview-page-line">
        Entdecke alle Gurus, die wir aktuell tracken
      </div>
      <GuruTable />
    </div>
    </>
  );
}

export default GuruOverviewPage;
