import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import usePortfolioData from "../backend/usePortfolioData";
import "./PortfolioChart.css";

function PortfolioChart() {
  const [displayHoldings, setDisplayHoldings] = useState(false); // Schalter für Wert/Holdings
  const { completePortfolioData } = usePortfolioData();

// Mapping-Funktion für Quarter
const formatQuarter = (quarter) => {
    const [year, q] = quarter.split("_");
    const shortYear = year.slice(-2); // Nur die letzten zwei Ziffern des Jahres
    return `${q} ${shortYear}`; // Format wie "Q3 24"
  };

  // Hole die Quartale und die Daten aus `completePortfolioData`
  const quarters = completePortfolioData
    .map((data) => formatQuarter(data[0]?.YEAR_QUARTER || ""))
    .reverse();
  const values = completePortfolioData
    .map((data) => data.reduce((sum, item) => sum + parseFloat(item.value), 0))
    .reverse();
  const holdings = completePortfolioData.map((data) => data.length).reverse();

  // Funktion zum Runden auf ein bestimmtes Intervall
  const roundToNearest = (value, interval) =>
    Math.ceil(value / interval) * interval;

  // Min- und Max-Werte berechnen (unterschiedlich je nach Modus) + Puffer
// Dynamische Werte für Y-Achse
const minValue = displayHoldings
  ? Math.min(...holdings) - 1 // Puffer für Holdings
  : Math.min(...values.map((v) => v / 1_000_000)) * 0.9; // Werte leicht reduzieren

const maxValue = displayHoldings
  ? Math.max(...holdings) + 1 // Puffer für Holdings
  : Math.max(...values.map((v) => v / 1_000_000)) * 1.1; // Werte leicht erhöhen

// Dynamisches Intervall berechnen
const range = maxValue - minValue;
const interval = displayHoldings
  ? Math.ceil(range / 5) // 5 Schritte für Holdings
  : Math.ceil(range / 5 / 10_000) * 10_000; // Schritte in 10.000 Mio. bei Assets


  const formatValue = (value) =>
    value?.toLocaleString("de-DE", { minimumFractionDigits: 3 });

  // Standardmäßig die aktuellsten Daten anzeigen
  const [hoveredData, setHoveredData] = useState({
    quarter: "Lade Daten...",
    value: "Lade Daten...",
  });

  // Effekt zur Aktualisierung von hoveredData, sobald die Daten verfügbar sind
  useEffect(() => {
    if (completePortfolioData.length > 0) {
      const quarters = completePortfolioData
        .map((data) => formatQuarter(data[0]?.YEAR_QUARTER || ""))
        .reverse();
      const values = completePortfolioData
        .map((data) => data.reduce((sum, item) => sum + parseFloat(item.value), 0))
        .reverse();
      const holdings = completePortfolioData.map((data) => data.length).reverse();
  
      const lastIndex = quarters.length - 1;
      setHoveredData({
        quarter: quarters[lastIndex],
        value: displayHoldings
          ? holdings[lastIndex]
          : `${Math.trunc(values[lastIndex] / 1_000_000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} Mio. $`,

      });
    }
  }, [completePortfolioData, displayHoldings]); // Abhängigkeit auf die Hauptdaten und den Anzeigezustand beschränken
  
  const isMobile = window.innerWidth < 1000;
  const gridRight = isMobile ? "4%" : "2%"; // Mehr Platz für mobile Geräte

  // Chart-Konfiguration
  const chartOption = {
    title: {
        text: displayHoldings ? "Holdings" : "Assets under Management",
        right: "2%",
        top: "2%",
      },
      grid: {
        left: "1.5%",
        right: gridRight,
        top: "5%",
        bottom: "5%",
        containLabel: true,
      },
    xAxis: {
      type: "category",
      data: quarters,
      boundaryGap: false, // Linie startet direkt am Rand
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        margin: 30, // Abstand der Y-Achsen-Beschriftung nach unten
        color: "#444",
        fontSize: 12,
      },
      
      axisTick: {
        show: false,
        alignWithLabel: true, // Sorgt für bündige Ausrichtung der Achsenbeschriftung
      },

    },
    yAxis: {
      type: "value",
      min: minValue,
      max: maxValue,
      interval: displayHoldings ? 1 : 20_000, // Logische Schritte: 20.000 Mio. oder 1 bei Holdings
      splitLine: {
        show: false, // Entfernt die horizontalen Gridlinien
      },
      axisLabel: {
        color: "#444",
        fontSize: 12,
        formatter: function (value) {
          return displayHoldings
          ? value.toFixed(0) // Ganze Zahlen für Holdings
          : `${(value / 1000).toFixed(0)} Mrd.`; // Zwei Dezimalstellen für Werte
      },
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        data: displayHoldings
          ? holdings.map((h) => Math.round(h)) // Werte auf ganze Zahlen runden
          : values.map((v) => v / 1_000_000), // Werte in Mio. umrechnen, wenn nötig
        type: "line",
        showSymbol: false, // Entfernt die Punkte auf der Linie
        smooth: true,
        lineStyle: {
          width: 2,
          color: "rgb(0, 200, 150)",
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: "rgb(0, 200, 150)" },
              { offset: 1, color: "rgba(255, 255, 255, 0)" },
            ],
          },
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line", // Zeigt nur die senkrechte Linie
        lineStyle: {
          color: "rgba(0, 0, 0, 0.5)", // Farbe der senkrechten Linie
          width: 2,
          type: "solid",
        },
      },
      formatter: (params) => {
        const index = params[0].dataIndex;
        const quarter = quarters[index];
        const value = displayHoldings
        ? holdings[index]
        : `${Math.trunc(values[index] / 1_000_000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} Mio. $`;
        setHoveredData({ quarter, value });
        return ""; // Tooltip-Inhalt leer lassen, da wir die Werte separat anzeigen
      },
    },
  };

  return (
    <>

      {/* Dynamische Anzeige */}
      {hoveredData && (
        <div className="big-portfolio-info-container">

          <div className="portfolio-button">
        <label>
          <input
            type="checkbox"
            checked={displayHoldings}
            onChange={(e) => setDisplayHoldings(e.target.checked)}
          />
        </label>
      </div>
        </div>
      )}



      <div className="portfolio-chart">
      <div className="hovered-container">
              <strong>{hoveredData.quarter} </strong>
              <strong>{hoveredData.value}</strong>
          </div>
        <ReactECharts
          option={chartOption}
          style={{ height: 400, width: "100%" }}
        />
      </div>

    </>
  );
}

export default PortfolioChart;
