import React, { useState, useEffect } from 'react';
import './BigPortfolioTable.css';
import useBigPortfolioData from '../backend/useBigPortfolioData';
import arrow from '../assets/arrow.svg';

function BigPortfolioTable() {
  const { bigPortfolioData, loading, error } = useBigPortfolioData();
  const [sortedData, setSortedData] = useState([]);
  const [totalPortfolioValue, setTotalPortfolioValue] = useState(0);
  const [uniqueCusips, setUniqueCusips] = useState(0);
  const [uniqueGurus, setUniqueGurus] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: 'percentageValue', direction: 'desc' });
  const [isExpanded, setIsExpanded] = useState(false); // Neuer Zustand für Aufklapp-/Zuklapp-Funktion
  const [expandedRow, setExpandedRow] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000); // Passe die Breite an, ab wann es als "mobile" gilt
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleRowExpand = (index) => {
    if (!isMobile) return; // Keine Aktion in der Desktop-Version
    setExpandedRow(expandedRow === index ? null : index);
  };

  const parseValue = (value) => {
    if (typeof value === 'string') {
      if (value.includes('%')) {
        return parseFloat(value.replace('%', '').trim());
      } else if (value.includes('$')) {
        return parseFloat(value.replace('$', '').replace(',', '').trim());
      } else if (!isNaN(parseFloat(value))) {
        return parseFloat(value.trim());
      }
      return value.toLowerCase();
    }
    return value;
  };

  useEffect(() => {
    if (!loading && bigPortfolioData) {
      const totalValue = bigPortfolioData.reduce((sum, item) => {
        return sum + parseFloat(item.value.replace('$', '').replace(',', ''));
      }, 0);
      setTotalPortfolioValue(totalValue);

      const sorted = bigPortfolioData
        .map((item) => {
          const guruCount = item.CIK.split('|').length;
          const logoUrl = item.TICKER ? `https://www.gowiththegurus.de/images/logos/${item.TICKER}.svg` : '';
          return {
            ...item,
            guruCount,
            percentageValue: (parseFloat(item.value.replace('$', '').replace(',', '')) / totalValue) * 100,
            logoUrl: logoUrl,
          };
        })
        .sort((a, b) => {
          const aValue = parseValue(a[sortConfig.key]);
          const bValue = parseValue(b[sortConfig.key]);

          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
          }

          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortConfig.direction === 'asc'
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          }

          return typeof aValue === 'number' ? -1 : 1;
        });

      setSortedData(sorted);

      const cusips = new Set(sorted.map((item) => item.cusip));
      setUniqueCusips(cusips.size);

      const allGurus = sorted
        .flatMap((item) => item.CIK.split('|'))
        .map((guru) => guru.trim());
      setUniqueGurus(new Set(allGurus).size);
    }
  }, [bigPortfolioData, loading, sortConfig]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatPercentage = (value) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const displayedData = isExpanded ? sortedData : sortedData.slice(0, 10);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data.</p>;

  return (
    <>
      <div className="big-portfolio-info-container">
        <div className="big-portfolio-info-spot-container">
          <p style={{fontSize:"24px"}}><strong>AUM</strong> </p>

          <p style={{fontSize:"16px", fontWeight:"900",  color:"rgba(255,255,255,1)"}}> {((totalPortfolioValue / 1000000)).toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} Mio. $</p>
        </div>
        <div className="big-portfolio-info-spot-container">
        <p style={{fontSize:"24px"}}><strong>HOLDINGS</strong> </p>
        <p style={{fontSize:"16px", fontWeight:"900", color:"rgba(255,255,255,1)"}}> {uniqueCusips}</p>
        </div>
        <div className="big-portfolio-info-spot-container">
        <p style={{fontSize:"24px"}}><strong>GURUS</strong> </p>
        <p style={{fontSize:"16px", fontWeight:"900",  color:"rgba(255,255,255,1)"}}> {uniqueGurus}</p>
        </div>
      </div>

      <div className="big-portfolio-table">
        <table>
          <thead>
            <tr>
              <th>Logo</th>
              <th onClick={() => handleSort('CUSIP_NAME')}>Name {sortConfig.key === 'CUSIP_NAME' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('TICKER')}>SYMBOL {sortConfig.key === 'TICKER' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('percentageValue')}>Anteil {sortConfig.key === 'percentageValue' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('value')}>AUM {sortConfig.key === 'value' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('sshPrnamt')}>Aktien {sortConfig.key === 'sshPrnamt' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('guruCount')}>Gurus {sortConfig.key === 'guruCount' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('GICS_SECTOR')}>GICS {sortConfig.key === 'GICS_SECTOR' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
            </tr>
          </thead>
          <tbody>
  {displayedData.map((item, index) => (
    <React.Fragment key={index}>
      <tr
        className="table-row"
        onClick={() => toggleRowExpand(index)}
        style={{ cursor: "pointer" }}
      >
        <td style={{ width: '30px' }}>
          <img
            src={item.logoUrl}
            className="holding-logo"
            style={{ width: '30px', height: '30px', marginRight: '5px' }}
            onError={(e) => (e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg')}
          />
        </td>
        <td>{item.CUSIP_NAME}</td>
        <td>{item.TICKER}</td>
        <td>{formatPercentage(item.percentageValue / 100)}</td>
        <td className="desktop-only">{formatCurrency(item.value)} $</td>
        <td className="desktop-only">{formatCurrency(parseInt(item.sshPrnamt))}</td>
        <td className="desktop-only">{item.guruCount}</td>
        <td className="desktop-only">{item.GICS_SECTOR}</td>
      </tr>
      {isMobile && expandedRow === index && (
        <tr className="expanded-row mobile-only">
          <td colSpan="3">
            <div className="expanded-content">
              <p><strong style={{opacity:0.8}}>Name</strong> {item.CUSIP_NAME}</p>
              <p><strong style={{opacity:0.8}}>Gurus</strong> {item.guruCount}</p>
              <p><strong style={{opacity:0.8}}>Anteil</strong> {formatPercentage(item.percentageValue / 100)}</p>
              <p><strong style={{opacity:0.8}}>AUM</strong> {formatCurrency(item.value)} $</p>
              <p><strong style={{opacity:0.8}}>GICS Sektor</strong> {item.GICS_SECTOR}</p>
              <p><strong style={{opacity:0.8}}>Aktien</strong> {formatCurrency(parseInt(item.sshPrnamt))}</p>
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  ))}
</tbody>


        </table>

        {/* Expand/Collapse Controls */}
        <div className="expand-controls">
  <button onClick={handleToggleExpand} className={isExpanded ? 'expanded' : ''}>
    <div className="plus-icon"></div>
  </button>
</div>
      </div>
    </>
  );
}

export default BigPortfolioTable;
