import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import TickerSearch from './TickerSearch'; // Importiere die TickerSearch-Komponente
import StockChart from './StockChart'; // Importiere die StockChart-Komponente
import GuruHoldings from './GuruHoldings'; // Importiere die GuruHoldings-Komponente
import { Helmet } from 'react-helmet-async'; // Importiere Helmet
import './StockPage.css'; // Importiere die CSS-Datei
import useTickerData from "../backend/useTickerData";

function StockPage() {
  const { ticker } = useParams();
  const [selectedTicker, setSelectedTicker] = useState(ticker || "AAPL");
  const { tickerData } = useTickerData();

  const selectedTickerName = Object.entries(tickerData).map(([ticker, company]) => ({
    TICKER: ticker,
    COMPANY_NAME: company,
  })).find(item => item.TICKER === selectedTicker)?.COMPANY_NAME || selectedTicker;


  return (
    <div className="stock-page-container">
      <Helmet>
        <title>{`${selectedTickerName} | Go With The Gurus`}</title>
        <meta name="description" content={`Wie hat sich ${selectedTickerName} in den Portfolios der Gurus entwickelt?`} />
        <meta name="keywords" content={`Aktien, ${selectedTickerName}, Portfolio, Investoren, Gurus, Börse`} />
        <meta name="author" content="Go With The Gurus" />
        <meta property="og:title" content={`${selectedTickerName} | Go With The Gurus`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={`https://www.gowiththegurus.de/images/logos/${selectedTicker}.svg`}  /> {/* Beispiel-Bild für Social Media */}
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="stock-page-header-container">


        <TickerSearch onTickerSelect={setSelectedTicker} />
            </div>  



      {/* Stock Chart */}


      
      <div className="stock-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {selectedTickerName}
        <img 
          style={{ 
            maxWidth: '150px', 
            height: '50px', 
            objectFit: 'contain',  // Bild füllt den Bereich
            objectPosition: 'bottom',  // Setzt die Unterkante als Referenz
            display: 'block'  // Entfernt unnötige Inline-Abstände
          }} 
          src={`https://www.gowiththegurus.de/images/logos/${selectedTicker}.svg`} 
          alt="Logo" 
          onError={(e) => (e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg')}
        />
      </div>
      
      <StockChart/>




        {/* Guru Holdings */}
        <GuruHoldings selectedTicker={selectedTicker} selectedTickerName={selectedTickerName} />


    </div>
  );
}

export default StockPage;