import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './NotFoundPage.css';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const redirect = setTimeout(() => {
      navigate('/');
    }, 10000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirect);
    };
  }, [navigate]);

  return (
    <div id="main">
      <div className="fof">
        <h1>Error 404</h1>
        <p>Hier gibt es leider nichts.</p>
        <p>Du wirst in {countdown} Sekunde{countdown !== 1 && 'n'} zur <Link to="/">Startseite</Link> weitergeleitet.</p>
      </div>
    </div>
  );
};

export default NotFoundPage;
