import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './TickerSearch.css';
import useTickerData from "../backend/useTickerData";

function TickerSearch({ onTickerSelect }) {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const { tickerData } = useTickerData();

  const formattedTickerData = Object.entries(tickerData).map(([ticker, company]) => ({
    TICKER: ticker,
    COMPANY_NAME: company,
  }));

  const getSuggestions = (input) => {
    const inputValue = input.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : formattedTickerData
          .filter(
            (d) =>
              d.TICKER.toLowerCase().includes(inputValue) ||
              d.COMPANY_NAME.toLowerCase().includes(inputValue)
          )
          .slice(0, 10);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setValue(value);
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionClick = (suggestion) => {
    onTickerSelect(suggestion.TICKER);
    setValue(suggestion.TICKER);
    setSuggestions([]);
    navigate(`/stockpage/${suggestion.TICKER}`);
  };

  return (
    <div className="search-container">
      <input
        type="text"
        placeholder="Suche"
        value={value}
        onChange={handleInputChange}
        className="search-input"
      />
      {suggestions.length > 0 && (
        <ul className="ticker-suggestions">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className="suggestion-item"
            >
              <img
                src={`https://www.gowiththegurus.de/images/logos/${suggestion.TICKER}.svg`}
                alt={suggestion.TICKER}
                className="ticker-logo"
              />
              {suggestion.COMPANY_NAME} ({suggestion.TICKER})
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default TickerSearch;