import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import useReturnData from "../backend/useReturnData";
import "./GuruCurrentPortfolioTable.css";

const ReturnBarChart = () => {
  const { loading, error, returnData } = useReturnData();
  const [chartOptions, setChartOptions] = useState({});
  const [hoveredContent, setHoveredContent] = useState({
    quarter: "Lade Daten...",
    sp500Index: "Lade Daten...",
    portfolioIndex: "Lade Daten...",
  });
  // Neuer State für den Anzeigemodus: "last10" oder "all"
  const [displayMode, setDisplayMode] = useState("last10");

  const formatQuarter = (quarter) => {
    const [year, q] = quarter.split("_");
    const shortYear = year.slice(-2);
    return `${q} ${shortYear}`;
  };

  const formatPercentage = (value) =>
    `${parseFloat(value).toLocaleString("de-DE", { minimumFractionDigits: 2 })}%`;

  useEffect(() => {
    if (loading || error || returnData.length === 0) return;

    // Zuerst werden alle Daten aus returnData in Arrays gepackt
    let sp500Index = 100;
    let portfolioIndex = 100;

    const fullXAxisData = [];
    const fullSp500Data = [];
    const fullPortfolioData = [];

    returnData.forEach((item) => {
      const quarter = formatQuarter(item.YEAR_QUARTER);
      fullXAxisData.push(quarter);

      const sp500Return = item.spx_return ? parseFloat(item.spx_return.replace(",", ".")) : 0;
      const portfolioReturn = item.return ? parseFloat(item.return.replace(",", ".")) : 0;
      sp500Index = sp500Return;
      portfolioIndex = portfolioReturn;

      fullSp500Data.push(sp500Index.toFixed(2));
      fullPortfolioData.push(portfolioIndex.toFixed(2));
    });

    // Auswahl der anzuzeigenden Daten basierend auf dem displayMode
    let xAxisData = fullXAxisData;
    let sp500Data = fullSp500Data;
    let portfolioData = fullPortfolioData;

    if (displayMode === "last10") {
      // Wähle die letzten 10 Datenpunkte
      xAxisData = fullXAxisData.slice(-12);
      sp500Data = fullSp500Data.slice(-12);
      portfolioData = fullPortfolioData.slice(-12);
    }
    // Überspringe den ersten (ältesten) Datenpunkt, sofern vorhanden
    if (xAxisData.length > 0) {
      xAxisData = xAxisData.slice(1);
      sp500Data = sp500Data.slice(1);
      portfolioData = portfolioData.slice(1);
    }

    // Setze den Hover-Zustand mit den zuletzt angezeigten Daten
    const lastIndex = xAxisData.length - 1;
    setHoveredContent({
      quarter: xAxisData[lastIndex],
      sp500Index: formatPercentage(sp500Data[lastIndex]),
      portfolioIndex: formatPercentage(portfolioData[lastIndex]),
    });

    const isMobile = window.innerWidth < 1000;
    const gridRight = isMobile ? "2%" : "0%";

    setChartOptions({
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          lineStyle: {
            color: "rgba(0, 0, 0, 0.5)",
            width: 2,
            type: "solid",
          },
        },
        formatter: (params) => {
          if (!params || params.length < 2) {
            return "";
          }
          const [sp500, portfolio] = params;
          setHoveredContent({
            quarter: sp500?.axisValue || "Unbekannt",
            sp500Index: sp500?.data ? formatPercentage(sp500.data) : "0",
            portfolioIndex: portfolio?.data ? formatPercentage(portfolio.data) : "0",
          });
          return "";
        },
      },
      legend: {
        data: [
          { name: "S&P 500", icon: "circle", itemStyle: { color: "rgba(0,0,0,0.4)" } },
          { name: "Portfolio", icon: "circle", itemStyle: { color: "rgb(0, 200, 150)" } },
        ],
        top: "5%",
        right: "1%",
        orient: "horizontal",
        textStyle: {
          color: "#444",
          fontSize: 12,
        },
        itemWidth: 10,
        itemHeight: 10,
        itemGap: 10,
      },
      grid: {
        left: "1%",
        right: gridRight,
        top: "6%",
        bottom: "5%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        boundaryGap: true,
        axisLine: {
          lineStyle: {
            color: "#fff",
          },
        },
        axisLabel: {
          margin: 20,
          color: "#444",
          fontSize: 12,
        },
        axisTick: {
          show: false,
          alignWithLabel: true,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          margin: 10,
          color: "#444",
          fontSize: 12,
          formatter: (value) => `${value}%`,
        },
        axisTick: {
          show: false,
          alignWithLabel: true,
        },
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: "S&P 500",
          type: "bar",
          data: sp500Data,
          showSymbol: false,
          symbol: "none",
          itemStyle: {
            color: "rgba(0,0,0,0.4)",
          },
        },
        {
          name: "Portfolio",
          type: "bar",
          data: portfolioData,
          showSymbol: false,
          symbol: "none",
          itemStyle: {
            color: "rgb(0, 200, 150)",
          },
        },
      ],
    });
  }, [loading, error, returnData, displayMode]);

  if (loading) return <div>Lade Daten...</div>;
  if (error) return <div>Fehler: {error.message}</div>;

  return (

    <>
            <div className='headline'>Return pro Quartal</div>
            <div className='gics-line' style={{display:"flex"}}> <div style={{minWidth:"200px"}}>Time Weighted Return</div>

            <div className="portfolio-button" style={{display:"flex", justifyContent:"flex-end"}}>
  <label className="switch">
    <input
      type="checkbox"
      checked={displayMode === "all"}
      onChange={(e) =>
        setDisplayMode(e.target.checked ? "all" : "last10")
      }
    />
  </label>
              {/* Toggle-Buttons für die Anzeige der Quartale */}
</div>

            </div>


    
    <div className="portfolio-chart">
      
      {/* Hovered Content */}
      {hoveredContent && (
        <div className="hovered-container">
          <div className="hovered-left">
            <strong>{hoveredContent.quarter}</strong>
          </div>
          <div className="hovered-right">
            <>
              S&P 500 <strong>{hoveredContent.sp500Index}</strong>
              <br />
              Portfolio <strong>{hoveredContent.portfolioIndex}</strong>
            </>
          </div>
        </div>
      )}

      {/* Chart */}
      <ReactECharts option={chartOptions} style={{ height: 400 }} />

    </div>
    </>
  );
};

export default ReturnBarChart;
