import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Für Parameter aus der URL

const useReturnData = () => {
  const { cik } = useParams(); // Holt die CIK aus der URL
  const url = `https://www.gowiththegurus.de/data/${cik}/${cik}_TWR.json`;
  const [returnData, setReturnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {


    const controller = new AbortController(); // To cancel fetch if component unmounts
    const { signal } = controller;

    const loadData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(url, { signal });

        if (!response.ok) {
          throw new Error(`HTTP-Fehler! Status: ${response.status}`);
        }

        const data = await response.json();
        setReturnData(data);
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    };

    loadData();

    return () => controller.abort(); // Cleanup fetch on unmount
  }, [cik]);

  return { returnData, loading, error };
};
export default useReturnData;
