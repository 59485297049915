import React, { useState } from "react";
import "./KofiWidget.css";
import coffee_mug from "../../assets/coffee_mug.svg";

function KofiWidget() {
  const [popupVisible, setPopupVisible] = useState(false);

  const openPopup = () => setPopupVisible(true);
  const closePopup = () => setPopupVisible(false);

  const handleClickOutside = (e) => {
    if (e.target.classList.contains("kofi-popup")) {
      closePopup();
    }
  };

  return (
    <>
      {/* Haupt-Widget */}
      <div className="kofi-widget-container">
        <div onClick={openPopup} className="kofi-button">
          <div className="kofi-widget">
            <div className="kofi-icon">
              <img src={coffee_mug} alt="Coffee Icon" />
            </div>
            <div className="kofi-content">
              <h3>Unterstütze unsere Arbeit</h3>
              <p>Buy me a coffee</p>
              <small>Vielen Dank für Euren Support</small>
            </div>
          </div>
        </div>
      </div>

      {/* Popup */}
      {popupVisible && (
          <div
          id="kofiPopup"
          className="kofi-popup active"
          onClick={handleClickOutside} // Event-Listener auf den Hintergrund
          >
          <div className="kofi-popup-content">
          <div className="kofi-popup-content-header">         <div className="kofi-icon">
              <img onClick={closePopup} src={coffee_mug} alt="Coffee Icon" />

            </div> </div>              
            <div id="kofiFrameContainer">
              <iframe
                id="kofiframe"
                src="https://ko-fi.com/gowiththegurus/?hidefeed=true&widget=true&embed=true&preview=true"
                height="100%"
                title="gowiththegurus"
                style={{
                padding:"50px 0px",
                minHeight:"700px",
                  border: "none",
                  borderRadius:"0px 0px 15px 15px",
                  width: "100%",
                  background: "rgb(255, 255, 255)",
                }}
              ></iframe>
            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default KofiWidget;
