import React, { useState, useEffect } from "react";
import usePortfolioData from "../backend/usePortfolioData";
import ReactECharts from "echarts-for-react";
import "./SectorTable.css";
import arrow from '../assets/arrow.svg';

function SectorTable() {
  const { completePortfolioData } = usePortfolioData();
  const [colSpan, setColSpan] = useState(4);
  const [sortConfig, setSortConfig] = useState({ key: "percentage", direction: "desc" });
  const [sortedData, setSortedData] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  // Neuer State: Auswahl des Anzeigemodus für den Barchart
  const [displayMode, setDisplayMode] = useState("last10"); // "last10" oder "all"

  const flattenedData = completePortfolioData?.flat() || [];

  const latestQuarter = Math.max(
    ...flattenedData.map((entry) => {
      const [year, quarter] = entry.YEAR_QUARTER.split("_");
      return parseInt(year) * 10 + parseInt(quarter.slice(1));
    })
  );

  const getColorByIndex = (index) => {
    const colors = [
      "rgb(0, 12, 116)",
      "rgba(0, 12, 116, 0.8)",
      "rgba(0, 12, 116, 0.6)",
      "rgba(0, 12, 116, 0.4)",
      "rgba(0, 12, 116, 0.2)",
      "rgb(255, 255, 255)",
      "rgba(255, 255, 255, 0.8)",
      "rgba(255, 255, 255, 0.6)",
      "rgba(255, 255, 255, 0.4)",
      "rgba(255, 255, 255, 0.2)",
      "rgba(0, 0, 0, 0.2)",
      "rgba(0, 0, 0, 1)",
    ];
    return colors[index % colors.length];
  };

  const currentQuarterData = flattenedData.filter((entry) => {
    const [year, quarter] = entry.YEAR_QUARTER.split("_");
    const quarterValue = parseInt(year) * 10 + parseInt(quarter.slice(1));
    return quarterValue === latestQuarter;
  });

  const totalValue = currentQuarterData.reduce((sum, entry) => {
    return sum + parseFloat(entry.value || 0);
  }, 0);

  const sectorData = currentQuarterData.reduce((acc, entry) => {
    const sector = entry.GICS_SECTOR;
    const value = parseFloat(entry.value || 0);

    if (sector in acc) {
      acc[sector] += value;
    } else {
      acc[sector] = value;
    }

    return acc;
  }, {});

  const sectorPercentages = Object.entries(sectorData).map(([sector, value]) => ({
    sector,
    value,
    percentage: ((value / totalValue) * 100).toFixed(2),
  }));

  // Sammle alle Quartale und Sektoren aus den Daten
  const allQuarters = [...new Set(flattenedData.map((entry) => entry.YEAR_QUARTER))];
  const allSectors = [...new Set(flattenedData.map((entry) => entry.GICS_SECTOR))];

  // Sortiere die Quartale chronologisch (angenommenes Format: "YYYY_Qx")
  const sortedQuarters = allQuarters.sort((a, b) => {
    const [yearA, quarterA] = a.split("_");
    const [yearB, quarterB] = b.split("_");
    return parseInt(yearA) - parseInt(yearB) || parseInt(quarterA.slice(1)) - parseInt(quarterB.slice(1));
  });

  // Initialisiere alle Quartale und alle Sektoren mit 0
  const barChartData = sortedQuarters.reduce((acc, quarter) => {
    acc[quarter] = {};
    allSectors.forEach((sector) => {
      acc[quarter][sector] = 0;
    });
    return acc;
  }, {});

  // Füge die Werte aus den Daten hinzu
  flattenedData.forEach((entry) => {
    const { YEAR_QUARTER, GICS_SECTOR, value } = entry;
    if (YEAR_QUARTER && GICS_SECTOR && value) {
      barChartData[YEAR_QUARTER][GICS_SECTOR] += parseFloat(value || 0);
    }
  });

  useEffect(() => {
    const updateColSpan = () => {
      if (window.innerWidth <= 1000) {
        setColSpan(3);
      } else {
        setColSpan(4);
      }
    };

    window.addEventListener('resize', updateColSpan);
    updateColSpan();

    return () => window.removeEventListener('resize', updateColSpan);
  }, []);

  // Wähle die anzuzeigenden Quartale basierend auf dem displayMode
  let quartersToDisplay = [];
  if (displayMode === "last10") {
    quartersToDisplay = sortedQuarters.slice(-10);
  } else {
    quartersToDisplay = sortedQuarters;
  }
  // Überspringe den ersten (ältesten) Datenpunkt
  if (quartersToDisplay.length > 0) {
    quartersToDisplay = quartersToDisplay.slice(1);
  }

  const barChartOption = {
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        let tooltipContent = `${params.name}<br/>`;
        const value = params.value;
        const formattedValue = value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace('.', ',');
        tooltipContent += ` <strong>${params.seriesName}</strong> ${formattedValue}%<br/>`;
        return tooltipContent;
      },
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      top: "top",
      textStyle: {
        color: "white",
      },
    },
    xAxis: {
      type: "category",
      data: quartersToDisplay, // Verwende die gefilterten Quartale
      axisLabel: {
        color: "white",
        formatter: function (value) {
          const [year, quarter] = value.split('_');
          return `${quarter} ${year.slice(2)}`;
        }
      },
      inverse: false,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        color: "white",
        formatter: (value) => `${value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`,
      },
      splitLine: {
        show: false,
      },
      max: 100,
    },
    series: allSectors.map((sector, index) => ({
      name: sector,
      type: "bar",
      stack: "total",
      emphasis: {
        focus: "series",
      },
      data: quartersToDisplay.map((quarter) => {
        const totalQuarterValue = Object.values(barChartData[quarter]).reduce((sum, value) => sum + value, 0);
        const sectorValue = barChartData[quarter][sector] || 0;
        return totalQuarterValue > 0 ? ((sectorValue / totalQuarterValue) * 100).toFixed(2) : 0;
      }),
      itemStyle: {
        color: getColorByIndex(index),
      },
    })),
    grid: {
      left: "0%",
      right: "0%",
      top: "25%",
      bottom: "0%",
      containLabel: true,
    },
  };

  useEffect(() => {
    const sorted = [...sectorPercentages].sort((a, b) => {
      const aValue = sortConfig.key === "percentage" ? parseFloat(a[sortConfig.key]) : a[sortConfig.key];
      const bValue = sortConfig.key === "percentage" ? parseFloat(b[sortConfig.key]) : b[sortConfig.key];

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
      }
      return sortConfig.direction === "asc"
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });

    setSortedData((prevSortedData) => {
      if (JSON.stringify(sorted) !== JSON.stringify(prevSortedData)) {
        return sorted;
      }
      return prevSortedData;
    });
  }, [sortConfig, sectorPercentages]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") direction = "desc";
    setSortConfig({ key, direction });
  };

  const handleSectorClick = (sector) => {
    setSelectedSector(selectedSector === sector ? null : sector);
  };

  const getSectorIcon = (sector) => {
    const formattedSector = sector.toUpperCase().replace(/\s+/g, "_");
    return `https://www.gowiththegurus.de/images/icons/${formattedSector}_ICON.svg`;
  };

  const filteredStocks = selectedSector
    ? currentQuarterData.filter((entry) => entry.GICS_SECTOR === selectedSector)
    : [];

  const formatValue = (value) => {
    return `${(value).toLocaleString("de-DE", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })} $`;
  };

  const formatPercentage = (percentage) => {
    return `${percentage.replace(".", ",")} %`;
  };

  const pieChartOption = sectorPercentages.length > 0 ? {
    tooltip: {
      trigger: "item",
      formatter: ({ name, value, percent }) =>
        `<strong>${name} </strong> ${formatValue(value)} (${formatPercentage(percent.toFixed(2))})`,
    },
    legend: {
      orient: "vertical",
      right: "0%",
      top: "center",
      align: "right",
      textStyle: {
        color: "white",
      },
    },
    series: [
      {
        name: "Sektoren",
        type: "pie",
        radius: ['40%', '70%'],
        center: ["35%", "50%"],
        data: sectorPercentages.map(({ sector, value }, index) => ({
          name: sector,
          value,
          itemStyle: {
            color: getColorByIndex(index),
          },
        })),
        label: {
          show: false,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  } : null;

  if (!completePortfolioData || completePortfolioData.length === 0) {
    return <div>Lade Daten...</div>;
  }

  return (
    <div className="sector-overview">

      
      <div className="table-chart-container">
        <table className="sector-table">
          <thead>
            <tr>
              <th>Icon</th>
              <th onClick={() => handleSort("sector")}>
                Sektor {sortConfig.key === 'sector' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}
              </th>
              <th onClick={() => handleSort("value")}>
                AUM {sortConfig.key === 'value' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}
              </th>
              <th onClick={() => handleSort("percentage")}>
                Anteil {sortConfig.key === 'percentage' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map(({ sector, value, percentage }) => (
              <React.Fragment key={sector}>
                <tr
                  onClick={() => handleSectorClick(sector)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: selectedSector === sector ? "#f0f0f0" : "transparent",
                  }}
                >
                  <td style={{ width: "30px" }}>
                    <img
                      src={getSectorIcon(sector)}
                      className="holding-logo"
                      style={{ marginRight: "5px" }}
                      onError={(e) =>
                        (e.target.src = "https://www.gowiththegurus.de/images/LOGO_BLACK.svg")
                      }
                    />
                  </td>
                  <td>{sector}</td>
                  <td>{formatValue(value)}</td>
                  <td>{formatPercentage(percentage)}</td>
                </tr>
                {selectedSector === sector && (
                  <tr className="details-row">
                    <td colSpan={colSpan}>
                      <div className="details-container">
                        {filteredStocks.length > 0 ? (
                          <div className="stock-details">
                            {filteredStocks.map(({ TICKER, value, CUSIP_NAME }) => (
                              <div key={TICKER} className="stock-item">
                                <div className="stock-info">
                                  <img
                                    src={`https://www.gowiththegurus.de/images/logos/${TICKER}.svg`}
                                    className="holding-logo"
                                    alt="Logo"
                                    onError={(e) => (e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg')}
                                  />
                                  <span>{CUSIP_NAME}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p>Keine Aktien für diesen Sektor verfügbar.</p>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="chart-main-container">
        <div className="chart-container">
          <ReactECharts option={pieChartOption} />
        </div>
              <div className="portfolio-button" style={{display:"flex", justifyContent:"flex-end"}}>
  <label className="switch">
    <input
      type="checkbox"
      checked={displayMode === "all"}
      onChange={(e) =>
        setDisplayMode(e.target.checked ? "all" : "last10")
      }
    />
  </label>
              {/* Toggle-Buttons für die Anzeige der Quartale */}
</div>
        <div className="chart-container">
          
          <ReactECharts option={barChartOption} style={{ height: "500px" }} />
        </div>
      </div>
    </div>
  );
}

export default SectorTable;
