import './Impressum.css';
import React, {useEffect } from 'react';

function Impressum() {

      useEffect(() => {
        document.title = "Go With The Gurus | Impressum";
        window.scrollTo(0, 0); // Scrollt beim Laden der Seite nach oben
      }); // Jetzt wird der Titel aktualisiert, wenn sich `name` ändert
    return (
        <>
        <div className="impressum-iphone"></div>
            <div className="impressum-container">
                <h1>Impressum</h1>
                <p>
                    Angaben gemäß § 5 TMG:
                </p>
                <p>
                    Maximilian Eilers<br />
                    Am Palstek 1<br />
                    26122 Oldenburg<br />
                </p>
                <p>
                    <a href="mailto:hi@gowiththegurus.de">hi@gowiththegurus.de</a>
                </p>
                <p>
                    Diese Webseite wird aus rein privatem Interesse betrieben. Einnahmen können optional über die Plattform Ko-fi erzielt werden.
                </p>
                <p>
                    Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br />
                    Maximilian Eilers<br />
                    Am Palstek 1<br />
                    26122 Oldenburg<br />
                </p>
            </div>
        </>
    );
}

export default Impressum;
