import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Für Parameter aus der URL

const useStockData = () => {
  const { ticker } = useParams(); // Holt die CIK aus der URL
  const url = `https://www.gowiththegurus.de/data/stocks/${ticker}_holding_history.json`;
  const [stockData, setStockData] = useState([]);



  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP-Fehler! Status: ${response.status}`);
        }

        const data = await response.json();

        setStockData(data);
      } catch (error) {
      }
    };

    loadData();

  }, [url]);


  return { stockData };
};

export default useStockData;
