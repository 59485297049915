import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Für Parameter aus der URL

const useCompletePortfolioData = () => {
  const { cik } = useParams(); // Holt die CIK aus der URL
  const url = `https://www.gowiththegurus.de/data/${cik}/${cik}_total_data.json`;

  const [completePortfolioData, setPortfolioData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const controller = new AbortController(); // Zum Abbrechen des Fetch, wenn die Komponente unmontiert wird
    const { signal } = controller;

    const loadData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(url, { signal });
    
        if (!response.ok) {
          throw new Error(`HTTP-Fehler! Status: ${response.status}`);
        }
    
        const data = await response.json();
        const groupedData = groupDataByQuarter(data);
        setPortfolioData(groupedData);
      } catch (err) {
        if (err.name !== "AbortError") {
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    };
    

    loadData();

    return () => controller.abort(); // Cleanup fetch on unmount
  }, [url]);

  // Funktion, um die Daten nach `YEAR_QUARTER` zu gruppieren
  const groupDataByQuarter = (data) => {
    // Daten nach YEAR_QUARTER sortieren (absteigend)
    const sortedData = data.sort((a, b) => {
      const [yearA, quarterA] = a.YEAR_QUARTER.split("_Q").map(Number);
      const [yearB, quarterB] = b.YEAR_QUARTER.split("_Q").map(Number);

      if (yearA === yearB) {
        return quarterB - quarterA; // Quartale vergleichen
      }
      return yearB - yearA; // Jahre vergleichen

    });

    // Nach YEAR_QUARTER gruppieren
    const groupedData = sortedData.reduce((acc, item) => {
      const key = item.YEAR_QUARTER;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    // In ein Array der Form [aktuelles Quartal, vorheriges Quartal] umwandeln
    return Object.keys(groupedData)
      .sort((a, b) => {
        const [yearA, quarterA] = a.split("_Q").map(Number);
        const [yearB, quarterB] = b.split("_Q").map(Number);

        if (yearA === yearB) {
          return quarterB - quarterA; // Quartale vergleichen
        }
        return yearB - yearA; // Jahre vergleichen
      })
      .map((key) => groupedData[key]);
  };

  return { completePortfolioData, loading, error };
};

export default useCompletePortfolioData;
