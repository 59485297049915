import React, { useState, useEffect, useMemo } from 'react';
import usePortfolioData from '../backend/usePortfolioData';
import './BuysAndSellsTable.css';
import arrow from '../assets/arrow.svg';

const BuysAndSellsTable = () => {
  const { completePortfolioData } = usePortfolioData();
  const [filter, setFilter] = useState('all');
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'percentageChange', direction: 'desc' });
  const [dataIndex, setDataIndex] = useState(0); // Index für den aktuellen Datensatz
  const [expandedRow, setExpandedRow] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); // Neuer Zustand für Aufklapp-/Zuklapp-Funktion

  const array0 = completePortfolioData?.[dataIndex + 1];
  const array1 = completePortfolioData?.[dataIndex];
  const year = completePortfolioData?.[dataIndex]?.[0]?.YEAR_QUARTER?.split('_')[0] || 'Unbekannt';
  const quarter = completePortfolioData?.[dataIndex]?.[0]?.YEAR_QUARTER?.split('_')[1] || 'Unbekannt';

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedData = isExpanded ? sortedData : sortedData.slice(0, 10);

  const calculatePercentageChange = (oldValue, newValue) => {
    const oldVal = parseFloat(oldValue || 0);
    const newVal = parseFloat(newValue || 0);

    if (oldVal === 0) return 100;
    return (((newVal - oldVal) / oldVal) * 100).toFixed(2);
  };

  const calculateValuePerShare = (stock, isCurrent) => {
    const value = parseFloat(stock?.value || 0);
    const shares = parseInt(stock?.sshPrnamt || 0);
    return shares > 0 ? value / shares : isCurrent ? NaN : null;
  };


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleRowExpand = (index) => {
    if (!isMobile) return;
    setExpandedRow(expandedRow === index ? null : index);
  };

  const changes = useMemo(() => {
    if (!array0 || !array1) return [];

    const mapByTicker = (array) =>
      array.reduce((map, item) => {
        map[item.TICKER] = map[item.TICKER] || [];
        map[item.TICKER].push(item);
        return map;
      }, {});

    const result = [];
    const array0ByTicker = mapByTicker(array0);
    const array1ByTicker = mapByTicker(array1);

    for (const ticker in array1ByTicker) {
      const oldStocks = array0ByTicker[ticker] || [];
      const newStocks = array1ByTicker[ticker];

      let oldShares = 0;
      let newShares = 0;

      oldStocks.forEach((stock) => (oldShares += parseInt(stock.sshPrnamt || 0)));
      newStocks.forEach((stock) => (newShares += parseInt(stock.sshPrnamt || 0)));

      const sharesChanged = newShares - oldShares;

      if (oldShares === 0) {
        // Neu
        result.push({
          ...newStocks[0],
          change: 'Neu',
          sharesChanged,
          percentageChange: calculatePercentageChange(oldShares, newShares),
          valuePerShare: calculateValuePerShare(newStocks[0], true) || 0,
        });
      } else if (newShares > oldShares) {
        // Erhöht
        result.push({
          ...newStocks[0],
          change: 'Erhöht',
          sharesChanged,
          percentageChange: calculatePercentageChange(oldShares, newShares),
          valuePerShare: calculateValuePerShare(newStocks[0], true) || 0,
        });
      } else if (newShares < oldShares) {
        // Reduziert
        result.push({
          ...oldStocks[0],
          change: 'Reduziert',
          sharesChanged,
          percentageChange: calculatePercentageChange(oldShares, newShares),
          valuePerShare: calculateValuePerShare(oldStocks[0], false) || 0,
        });
      }
    }

    for (const ticker in array0ByTicker) {
      if (!array1ByTicker[ticker]) {
        const stock = array0ByTicker[ticker][0];
        // Verkauft
        result.push({
          ...stock,
          change: 'Verkauft',
          sharesChanged: -parseInt(stock.sshPrnamt || 0),
          percentageChange: -100,
          valuePerShare: calculateValuePerShare(stock, false) || 0,
        });
      }
    }

    return result;
  }, [array0, array1]);

  const filteredData = useMemo(() => {
    return changes.filter((item) => {
      switch (filter) {
        case 'buys':
          return item.change === 'Erhöht' || item.change === 'Neu';
        case 'sells':
          return item.change === 'Reduziert' || item.change === 'Verkauft';
        default:
          return true;
      }
    });
  }, [filter, changes]);

  useEffect(() => {
    const sorted = [...filteredData].sort((a, b) => {
      let aValue, bValue;
  
      if (sortConfig.key === 'percentageChange') {
        aValue = parseFloat(a[sortConfig.key]);
        bValue = parseFloat(b[sortConfig.key]);
      } else if (sortConfig.key === 'value') {
        aValue = (a.valuePerShare || 0) * (a.sharesChanged || 0);
        bValue = (b.valuePerShare || 0) * (b.sharesChanged || 0);
      } else {
        aValue = a[sortConfig.key];
        bValue = b[sortConfig.key];
      }
  
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      }
      return sortConfig.direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });
  
    setSortedData(sorted);
  }, [sortConfig, filteredData]);
  

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') direction = 'desc';
    setSortConfig({ key, direction });
  };



  if (!completePortfolioData || completePortfolioData.length < 2) {
    return <div>Keine ausreichenden Daten vorhanden</div>;
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatCurrency2 = (value) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const formatPercentage = (value) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  return (
    <>
      {/* Navigation */}


      {/* Filter Buttons */}
      <div className="buys-and-sells-button">
        <label>
          <input
            type="radio"
            name="filter"
            value="all"
            checked={filter === 'all'}
            onChange={() => setFilter('all')}
          />{' '}
          Alle
        </label>
        <label>
          <input
            type="radio"
            name="filter"
            value="buys"
            checked={filter === 'buys'}
            onChange={() => setFilter('buys')}
          />{' '}
          Käufe
        </label>
        <label>
          <input
            type="radio"
            name="filter"
            value="sells"
            checked={filter === 'sells'}
            onChange={() => setFilter('sells')}
          />{' '}
          Verkäufe
        </label>
      </div>

      <div className="year-navigation">
        <span>
          <p style={{minWidth:"70px"}}><strong>{quarter}  {year} </strong></p>
        </span>
        <input 
          type="range" 
          min="0" 
          max={completePortfolioData.length - 1} 
          value={completePortfolioData.length - 1 - dataIndex} 
          onChange={(e) => setDataIndex(completePortfolioData.length - 1 - Number(e.target.value))} 
          className="year-slider"
        />
      </div>

      {/* Tabelle */}
      <div className="guru-current-table">
        <table>
          <thead>
            <tr>
              <th>Logo</th>
              <th onClick={() => handleSort('nameOfIssuer')}>Name {sortConfig.key === 'nameOfIssuer' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('TICKER')}>Ticker {sortConfig.key === 'TICKER' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('change')}>Anteil {sortConfig.key === 'change' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('value')}>Wert {sortConfig.key === 'value' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('valuePerShare')}>Preis pro Aktie {sortConfig.key === 'valuePerShare' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>              
              <th onClick={() => handleSort('sharesChanged')}>Aktien {sortConfig.key === 'sharesChanged' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>              
              <th onClick={() => handleSort('percentageChange')}> % Change {sortConfig.key === 'percentageChange' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>              
              <th onClick={() => handleSort('GICS_SECTOR')}>GICS {sortConfig.key === 'GICS_SECTOR' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>                          </tr>
          </thead>
          <tbody>
            {displayedData.map((item, index) => (
              <React.Fragment key={index}>
                <tr className="table-row" onClick={() => toggleRowExpand(index)}>
                  <td>
                    <img
                      src={`https://www.gowiththegurus.de/images/logos/${item.TICKER || ''}.svg`}
                      onError={(e) => (e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg')}
                      alt="Logo"
                      className="holding-logo"
                    />
                  </td>
                  <td>{item.CUSIP_NAME}</td>
                  <td>{item.TICKER}</td>
                  <td
                    style={{
                      color:
                        item.change.includes('Neu') || item.change.includes('Erhöht')
                          ? 'rgb(67, 185, 82)'
                          : 'rgb(231, 94, 94)',
                    }}
                  >
                    {item.change}
                  </td>
                  <td>{formatCurrency(item.valuePerShare * item.sharesChanged )} $</td>
                  <td>
                    {item.valuePerShare === null || isNaN(item.valuePerShare)
                      ? '—'
                      : `${formatCurrency2(item.valuePerShare)} $`}
                  </td>
                  <td>{formatCurrency(item.sharesChanged)}</td>
                  <td>{formatPercentage(item.percentageChange / 100)}</td>
                  <td>{item.GICS_SECTOR}</td>

                </tr>
                {isMobile && expandedRow === index && (
                  <tr className="expanded-row mobile-only">
                    <td colSpan="3">
                      <div className="expanded-content">
                        <p><strong>Name</strong> {item.CUSIP_NAME}</p>
                        <p><strong>Ticker</strong> {item.TICKER}</p>
                        <p><strong>Veränderung</strong> {item.change}</p>
                        <p><strong>Aktien Veränderung</strong> {formatCurrency(item.sharesChanged)} Stück</p>
                        <p><strong>Preis pro Aktie</strong> {item.valuePerShare === null || isNaN(item.valuePerShare) ? '—' : `${formatCurrency2(item.valuePerShare)} $`}</p>
                        <p><strong>Prozentuale Veränderung</strong> {formatPercentage(item.percentageChange / 100)}</p>
                        <p><strong>GICS</strong> {item.GICS_SECTOR}</p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
                        {/* Expand/Collapse Controls */}
                        <div className="expand-controls" style={{ display: sortedData.length - 1 <= 10 ? 'none' : 'flex' }}>
  <button onClick={handleToggleExpand} className={isExpanded ? 'expanded' : ''}>
    <div className="plus-icon"></div>
  </button>
</div>
      </div>
    </>
  );
};

export default BuysAndSellsTable;
