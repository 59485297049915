import { useState, useEffect } from 'react';

const useBigPortfolioData = () => {
  const url = "https://www.gowiththegurus.de/data/combined_prepared.json";
  const [bigPortfolioData, setbigPortfolioData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP-Fehler! Status: ${response.status}`);
        }

        const data = await response.json();

        setbigPortfolioData(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    loadData();

  }, [url]);


  return { bigPortfolioData };
};

export default useBigPortfolioData;
