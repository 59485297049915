import './FaqPage.css';
import React, { useEffect, useState } from 'react';
import arrowBlack from '../assets/arrow_black.svg'; // Importiere das Pfeilsymbol

function FaqPage() {
    const faqs = [
        {
            question: "Was ist Go With The Gurus?",
            answer: "Bei Go With The Gurus bereiten wir die 13F-Daten der SEC auf, analysieren sie und machen sie durch visuelle Darstellungen greifbar. Wir verfolgen die Strategien von Super-Investoren und zeigen dir, wie du von ihren Ansätzen lernen kannst. Das Ganze ist ein Hobbyprojekt, entstanden aus unserer Begeisterung für Finanzmärkte. Unser Ziel: dir einen einfachen Zugang zu den Investmentstrategien der erfolgreichsten Anleger zu bieten."
        },
        {
            question: "Wer sind Gurus/Super Investoren?",
            answer: "Gurus oder Super Investoren sind institutionelle oder legendäre Anleger, die durch außergewöhnliche Erfolge an den Finanzmärkten bekannt geworden sind. Dazu zählen beispielsweise Warren Buffett, Bill Gates oder Michael Burry. Sie verwalten oft Milliardenbeträge und ihre Investmentstrategien dienen vielen Anlegern als Vorbild. Die 13F-Filings ermöglichen es, die Portfolios dieser Investoren zu analysieren und von ihren Entscheidungen zu lernen."
        },        
        {
            question: "Was sind 13F-Filings?",
            answer: "13F-Filings sind vierteljährliche Berichte, die von institutionellen Investmentmanagern in den USA bei der Securities and Exchange Commission (SEC) eingereicht werden müssen. Sie bieten einen detaillierten Einblick in die Aktienpositionen großer Vermögensverwalter, die mindestens 100 Millionen US-Dollar in bestimmten Wertpapieren verwalten. Zu diesen institutionellen Managern zählen Hedgefonds, Pensionsfonds, Investmentgesellschaften und andere große Investoren."
        },
        {
            question: "Wann müssen die Filings eingereicht werden?",
            answer: "Die 13F-Daten werden vierteljährlich veröffentlicht, das heißt alle drei Monate. Institutionelle Investmentmanager sind verpflichtet, ihre Berichte spätestens 45 Tage nach dem Ende eines Quartals bei der SEC einzureichen. Dadurch stehen die Daten immer leicht zeitverzögert zur Verfügung. Für das erste Quartal (Januar bis März) werden die Berichte beispielsweise bis spätestens 15. Mai veröffentlicht, für das zweite Quartal (April bis Juni) bis spätestens 15. August, für das dritte Quartal (Juli bis September) bis spätestens 15. November und für das vierte Quartal (Oktober bis Dezember) bis spätestens 15. Februar des folgenden Jahres. Fällt der Stichtag auf einen Samstag, Sonntag oder Feiertag, verschiebt sich die Frist automatisch auf den nächsten Werktag. Trotz der Automatisierung durch Python gibt es viele Arbeitsschritte, die manuell erledigt werden müssen. Dazu gehört die Kontrolle der Daten auf Vollständigkeit, das Beheben von Fehlern und die abschließende Feinarbeit, um sicherzustellen, dass die Ergebnisse präzise und übersichtlich sind. Dieser kombinierte Ansatz aus automatisierter Datenverarbeitung und sorgfältiger Handarbeit garantiert höchste Datenqualität und eine verständliche Darstellung."
        },
        {
            question: "Woher bezieht ihr die Daten?",
            answer: "Die Daten für die 13F-Filings stammen direkt von der SEC-Webseite, wo institutionelle Investmentmanager ihre Berichte vierteljährlich einreichen. Von dort laden wir die Rohdaten herunter, die zunächst in einem unstrukturierten Format vorliegen. Anschließend übernehmen unsere Python-Skripte die Hauptarbeit: Sie konvertieren die Daten in ein lesbares Format, berechnen wichtige Kennzahlen und bereiten die Informationen für die weitere Analyse auf. Python ermöglicht es uns, große Datenmengen effizient zu verarbeiten und grundlegende Auswertungen schnell durchzuführen."
        },
        {
            question: "Nutzt ihr Cookies?",
            answer: "Nein, wir sind komplett cookie-frei. Unsere Plattform verwendet keinerlei Cookies, da wir keine persönlichen Daten speichern oder verfolgen."
        },
        {
            question: "Welche Gurus trackt ihr?",
            answer: (
                <span>
                    Eine vollständige Liste der Gurus, die wir tracken, findest du auf unserer Seite{" "}
                    <a href="/gurus" className="link">
                        hier
                    </a>.
                </span>
            )
        }
    ];

    useEffect(() => {
        document.title = "Go With The Gurus | Häufig gestellte Fragen";
        window.scrollTo(0, 0); // Scrollt beim Laden der Seite nach oben
    }, []);

    return (
        <div className="faq-page">
            <div className="faq-header">
                <h1>Häufig gestellte Fragen</h1>
                <p>Finde Antworten auf die häufigsten Fragen zur Nutzung unserer Plattform.</p>
            </div>
            <div className="accordion">
                {faqs.map((faq, index) => (
                    <AccordionItem key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </div>
    );
}

function AccordionItem({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`accordion-item ${isOpen ? 'open' : ''}`}>
            <div className="accordion-question" onClick={() => setIsOpen(!isOpen)}>
                {question}
                <img
                    src={arrowBlack}
                    alt="Toggle Arrow"
                    className={`accordion-arrow ${isOpen ? 'open' : ''}`}
                />
            </div>
            <div
                className="accordion-answer"
                style={{
                    maxHeight: isOpen ? '1000px' : '0',
                    transition: 'max-height 0.4s ease',
                    overflow: 'hidden',
                }}
            >
                <p>{answer}</p>
            </div>
        </div>
    );
}

export default FaqPage;
