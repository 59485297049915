import "./css/Post1.css";
import pdfIcon from "../../assets/PDF_ICON.png"
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useEffect } from "react";

function Post2() {



          // Tab-Titel mit document.title setzen
          useEffect(() => {
            document.title = "Go with the Gurus | 13F Filings und was wir wissen";
            window.scrollTo(0, 0); // Scrollt beim Laden der Seite nach oben
          }, []); // Leeres Array sorgt dafür, dass der Effekt nur einmal bei Mount ausgeführt wird


    return (
        
        <>

            <Helmet>
                <title>Go with the Gurus | 13F Filings und was wir wissen</title>
                <meta name="description" content="Aktueller Forschungsstand der 13F Filings. Wo stehen wir und was sind die Ergebnisse?" />
            </Helmet>
        

        


        <div className="blog-post-header-image-post-2">
            <div className="landingpage-iphone-text-container">
            <h1 style={{ margin:"10px"}}>13F Filings und was wir wissen</h1>
            <h5 style={{fontWeight:"300", margin:"0", fontSize:"16px"}}>Aktueller Forschungsstand</h5>
            <h5 style={{fontWeight:"300", margin:"5px", color:"rgba(255, 255, 255, 0.5)"}}>03. März 2025</h5>
            </div>  
        </div>

        <div className="post-container-header-background">
        <div className="post-container-header">

<h2 style={{margin:"0", padding:"20px 0"}}>Inhalt</h2>
<p><a className="index-list" href="#Systematic-13F-Hedge-Fund-Alpha">Systematic 13F Hedge Fund Alpha</a></p>
<p><a className="index-list" href="#Outperforming-the-Market">Outperforming the Market</a></p>
</div>
</div>

        <div className="post-container">

            
            <div id="Systematic-13F-Hedge-Fund-Alpha" className="post-content">

                <ul>
      
                                     
                        <li >
                           <a target="blank" href="https://wp.lancs.ac.uk/fofi2020/files/2020/04/FoFI-2020-090-Farouk-Jivraj.pdf" style={{display:"flex", gap:"20px", alignItems:"center"}}>
                            <img 
                                src={pdfIcon} 
                                onError={(e) => e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg'}
                            
                                className="holding-logo-blog" 
                            />
                            <strong>Systematic 13F Hedge Fund Alpha</strong>
                        </a>
                        </li>

                </ul>

            <h3>Die Autoren</h3>
            Die Autoren waren zum Zeitpunkt der Veröffentlichung der Studie im Jahr 2019 mit Barclays Quantitative Investment Strategies (QIS) verbunden. Sie haben an der systematischen Analyse von Hedgefonds-Strategien basierend auf 13F-Filings gearbeitet.
            Ihre Forschung konzentriert sich auf quantitative Finanzmodelle, systematische Anlagestrategien und den Einfluss institutioneller Anleger auf den Markt.
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Luca Angelini</h4>
            Er ist Experte für quantitative Strategien und arbeitete im Research-Team von Barclays QIS.<br></br>
            Spezialisiert auf systematische Alpha-Strategien, Hedgefonds-Analyse und Marktineffizienzen.<br></br>
            Hat mehrere Studien zu quantitativen Investmentstrategien veröffentlicht.
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Mobeen Iqbal</h4>
            Ebenfalls ein Analyst mit Fokus auf Hedgefonds-Daten und systematische Strategien.<br></br>
            Arbeitete an Methoden zur Identifikation von Alpha-Quellen in institutionellen Portfolios.
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Farouk Jivraj</h4>
            Analyst mit Schwerpunkt auf Factor Investing und Hedgefonds-Strategien.<br></br>
            Beteiligte sich an der Entwicklung von modellbasierten Handelsstrategien für institutionelle Investoren.
            <br></br>
            <br></br>

 
            <h3>Hintergrund & Motivation</h3>
            Die Studie untersucht, ob Hedgefonds durch ihre „besten Ideen“ überdurchschnittliche Renditen erzielen können. Dabei nutzen die Autoren die 13F-Berichte, die Hedgefonds mit über 100 Millionen USD verwaltetem Kapital vierteljährlich bei der Securities and Exchange Commission (SEC) einreichen müssen. Ziel ist es, aus diesen Daten eine Strategie zu entwickeln, die systematisch „Hedge Fund Alpha“ generiert, also überdurchschnittliche risikoadjustierte Renditen.
            <br></br>
            <br></br>
            <h3>Kernaussagen & Methodik</h3>
            <h4 style={{margin:"10px 0px"}}>Die besten Ideen von Hedgefonds liefern Alpha</h4>
            Basierend auf der Methodik von Cohen, Polk und Silli (2010) zeigt die Studie, dass Hedgefonds-Strategien, die auf „besten Ideen“ basieren, den S&P 500 um 3,8 % pro Jahr übertreffen.
            Diese Strategien erzielen einen Sharpe Ratio von 0,75 über den Zeitraum von Mai 2004 bis Juni 2019.
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Alternative Messmethoden für Hedgefonds</h4>
            Da Hedgefonds im Gegensatz zu Mutual Funds nicht an Benchmarks gebunden sind, wird eine neue Methodik zur Identifikation der besten Ideen entwickelt.
            Zwei Hauptkriterien werden verwendet:<br></br>
            <strong>Conviction:</strong> Aktien mit den höchsten Portfolio-Gewichtungen innerhalb eines Hedgefonds.<br></br>
            <strong> Consensus: </strong> Aktien, die von möglichst vielen Hedgefonds gehalten werden.            <br></br>
            Beide Strategien liefern vergleichbare Sharpe Ratios von 0,65.   
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Die Auswahl der richtigen Hedgefonds ist entscheidend („Who matters“)</h4>
            Die Autoren filtern Fundamental Equity Hedge Funds (FEHF) heraus – also Hedgefonds, die langfristige Aktienpositionen halten.
            Strategien, die sich auf diese Fonds konzentrieren, schlagen sowohl den gesamten Hedgefonds-Index als auch den S&P 500 deutlich.
            Die besten Strategien basieren auf Aktien, in denen Hedgefonds sowohl hohe Überzeugung (conviction) als auch breite Zustimmung (consensus) zeigen.
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Praktische Handelsstrategie zur Umsetzung</h4>
            Die optimale Strategie kombiniert conviction & consensus, indem die 50 meistgehaltenen Aktien von FEHF-Hedgefonds mit mindestens 7,5 % Portfoliogewichtung gewählt werden.
            Diese Strategie erzielt ein sechs-Faktor-Alpha von 0,32 % pro Monat (ca. 3,8 % pro Jahr).
            Wird stattdessen eine breitere Gruppe von Hedgefonds genutzt (Hedgefonds Universe ex FEHF), verschwindet das Alpha.           
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Fazit & Bedeutung</h4>
            Die besten Ideen von Hedgefonds liefern tatsächlich Alpha, wenn die richtigen Fonds selektiert werden.
            Eine Kombination aus hoher Überzeugung & breiter Zustimmung verbessert die Performance.
            Copycat-Investing funktioniert, wenn Hedgefonds mit langfristigem Fokus gezielt ausgewählt werden.
            Die zeitliche Verzögerung der 13F-Daten (45 Tage nach Quartalsende) reduziert das Alpha nicht signifikant.         
               </div>
               <br></br>
               <br></br>
               <br></br>
               <br></br>



               <div id="Outperforming-the-Market" className="post-content">
                <ul>
      
                                     
                        <li >
                           <a target="blank" href="https://download.ssrn.com/2024/8/2/4767576.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHoaCXVzLWVhc3QtMSJIMEYCIQDJT4L2GAI0zjfRgvY2FjIf0LpxxuEZYJ50ovtHotqykQIhALTxlikPGxec2SWI645Hu9vouE6mmUevjZnXrwzW8zrFKsYFCLP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQBBoMMzA4NDc1MzAxMjU3IgwhgvN8tLlIdzN8idwqmgW3%2FWSEl0Kc1Q64PX%2FV4152mNYAhCRCcYc9XWdaIT6K5pgpCblAJyrENm7q4U5ecRla1oX19kR9C4zWYvs%2FH%2BWfOY48u08fM%2Bc%2FZozCVQTKeCMkYyJ2hg3HXR%2F48TSPjndUBGCQk5P0KrVym9OiaV2bi0HI%2F50qiGGTcdi5Ndd9dIXGudiLxfxYCBKH9G9jS2Sv8RLrZPca%2FBI%2FdA0vb%2FfsvlrlmqHYrqRJVF%2FDrpXUA%2BQrEfIQp4llC48wBx9%2Fo1VMgBqM9cbngyP0c%2FqLq1m%2FxgY0LYl1NWH14ezlgfsQD4L47sIpuCDxY%2FLcyB0pk42%2FMaVq%2Fh9%2BQE51wxhZ%2FMlDOm1FV8ZcJgKUCyFgDLK%2B%2Fx4MVUKB%2BT9puGcG5njHlbFHnDusDxLz%2B1q3VgSl1%2BL3uMM%2BFvxjeL0G3VwPT%2BBaNj5iGye4002%2F9XzpY1727dHtPVQA0%2BwAHvdES8IScj82FYLTiuFXNi71G0HiukCZYNkgiHz3ErTHT6h5vhCQroXO40pfGU9c8EqI3MRN6Q2h%2BRNicVyI7qK6%2FAGrrraE%2B6f8rnT7b4jyJjUvHWLfFrAx98zghCVLKCtyod4yr%2F7yY5ZmgKt%2BVsHbVotQkhyosW4EDSgkmCLfy1Jw23pqURTsmvjoXLjqRR7q14im55hqxDt2y2YPQJ2rpqx1vK07f5EzkqgiO%2B2LotMG9lXL3WyLNbVjpZj6ipd1gGi8ZwCmdbzSXQjEUh6ERrm48WKMJ0fcUis8IOpyoiQD%2F%2FeYqBENrStQzeJUze4%2FizvTsYEdHlAiK%2BMIDjgyvsiTzyDW4A1RbzXboZL3SOiDf7RmVYYSZK%2B0tIpOzYBuNv5Irf4TJ5lswUKXsz8UHHPYFRnRoLJ6pOXhYEl%2Fu4swj%2BmOvgY6sAFyZKeT9dO%2Fc4GH7R6dXtTUNc0331wdiC9bgslrY60uQUxNhnlohK3z9pag4SBP1fs1niATLbgYqu3OfmmuYhsnAjJs2s0V2slQ6SVd8LokmVLBSmoXspGZY%2BVo2di66i6nCKSrWsMk1vTs8xBSWG9xQDBkVJEshrJfGhAIGIfbxRAT4nKmnIKj%2BZI98Uii0ruhTXL483f8R8bEMUaLy7st4EjKt2vHbGsCYTwl85auEA%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20250302T024417Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAUPUUPRWEZKNUQM7V%2F20250302%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=03e4f2b7cfe69ca0761a516abf66b9dfbc832bc35b332e0094e6fd1666272c9d&abstractId=4767576" style={{display:"flex", gap:"20px", alignItems:"center"}}>
                            <img 
                                src={pdfIcon} 
                                onError={(e) => e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg'}
                            
                                className="holding-logo-blog" 
                            />
                            <strong>Outperforming the Market: Portfolio Strategy Cloning from SEC 13F Filings</strong>
                        </a>
                        </li>

                </ul>

            <h3>Die Autoren</h3>
            ​Peter N. Posch ist ein renommierter Wissenschaftler im Bereich der Finanzwissenschaften und leitet den Lehrstuhl für Finance an der Universität Duisburg-Essen. 
            Seine Forschungsschwerpunkte umfassen quantitative Finanzmarktforschung, Risikomanagement und die Analyse von Hedgefonds-Strategien. 
            Er hat zahlreiche Publikationen in internationalen Fachzeitschriften veröffentlicht und ist bekannt für seine praxisorientierten Forschungsansätze.            
            <br></br>
            <br></br>
            Jan Schröder ist als externer Forschungspartner am Lehrstuhl für Finance der Technischen Universität Dortmund tätig.
            <br></br>
            <br></br>
 
            <h3>Zusammenfassung</h3>
            Die Studie von Jan L. Schroeder und Peter N. Posch untersucht, ob das Spiegeln von Hedgefonds-Strategien zu einer Marktrendite führt, die den S&P 500 übertrifft. 
            Dazu wurde die Performance von cloned portfolios, die aus den SEC 13F-Filings abgeleitet wurden, mit den Originalportfolios und dem Markt verglichen.            
            <br></br>
            <br></br>
            <h3>Wichtigste Erkenntnisse</h3>
            Cloned Portfolios im obersten Quartil konnten die Originalfonds erfolgreich replizieren und schnitten im Durchschnitt 24,3 % besser als der S&P 500 ab, gemessen auf annualisierter risikobereinigter Basis.<br></br>
            Die Analyse basiert auf über 150.000 Portfolios von mehr als 13.000 institutionellen Investmentmanagern zwischen 1998 und 2023.<br></br>
            12 Performance-Metriken wurden verglichen, darunter Alpha, Beta, Sharpe Ratio, Volatilität, Drawdown und andere.<br></br>
            Cloned Portfolios performten in den meisten Metriken vergleichbar oder besser als die Originalfonds – außer bei höherer Volatilität, Drawdown und Tracking Error.<br></br>
            Rebalancing am Veröffentlichungsdatum der 13F-Filings statt zum Quartalsende führte zu einer genaueren Nachbildung der Fondsperformance.<br></br>
            <br></br>
            <br></br>
            <h3>Methodik</h3>
            <h4 style={{margin:"10px 0px"}}>Datenquellen & Berechnungsmethoden</h4>
            Daten wurden aus der SEC EDGAR-Datenbank (Form 13F) extrahiert.<br></br>
            Vergleich mit historischen Preisen aus AlgoSeek zur Berechnung von Returns.<br></br>
            Berechnung der Portfolio-Renditen anhand von Buy-and-Hold-Simulationen, wobei zu den veröffentlichten Daten Positionen gekauft/verkauft wurden.<br></br>
            Backtests über 10 Jahre (2013–2023) mit über 161.000 Portfolios.<br></br>
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Hypothesen-Testung</h4>
            <strong>H0 (Nullhypothese):</strong> Geklonte Portfolios performen nicht besser als die Originalfonds oder der Markt.<br></br>
            <strong>H1 (Alternative Hypothese):</strong> Cloned Portfolios können die Performance der Originalfonds nachbilden oder sogar übertreffen.<br></br>
            Getestet mit Wilcoxon Signed-Rank Test, um Unterschiede zwischen Original- und Cloned Portfolios zu bewerten.<br></br>
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Ergebnisse der Faktor-Analyse</h4>
            Alpha (Überrendite gegenüber S&P 500) → Cloned Portfolios: +20,82%, Originalfonds: +7,94%.<br></br>
            Beta (Marktvolatilität) → Cloned Portfolios: 0,88, Originalfonds: 0,58.<br></br>
            Sharpe Ratio (risikobereinigte Rendite) → Cloned Portfolios: 1,91, Originalfonds: 2,05.<br></br>
            Maximaler Drawdown (größter Verlust) → Cloned Portfolios: -7,76%, Originalfonds: -8,98%.<br></br>
            Tracking Error war leicht erhöht, was bedeutet, dass Cloned Portfolios leicht von den Originalfonds abweichen.<br></br>
            <br></br>
            <br></br>
            <h3>Implikationen & Fazit</h3>
            Marktübertreffung ist durch Portfolio-Kloning möglich, insbesondere mit Fokus auf Top-Performer unter den Hedgefonds.
            Cloned Portfolios sind kein perfekter Ersatz, da sie höhere Volatilität und Drawdowns aufweisen.
            Automatisierte Rebalancing-Strategien können genutzt werden, um Hedgefonds-Strategien zu replizieren und anzuwenden.    
            Der Hauptvorteil liegt in der Möglichkeit, ohne Hedgefonds-Gebühren in ähnliche Strategien zu investieren.
            <br></br>
            <br></br>

               </div>
       
       
       
        </div>


        </>
    );

}

export default Post2;
