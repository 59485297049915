import React from 'react';
import './LastIn.css';
import { Link } from 'react-router-dom';

function LastIn() {
  const newsData = [
    {
      id: 3,
      name: 'Norbert Lou',
      fonds: 'Punch Card Capital',
      lastUpdate: '18.03.2025',
      reportingDate: '31.12.2024',
      volume: 'Bearish',
      change: '-13 Mio. $',
      imageUrl:
        'https://www.gowiththegurus.de/images/landingpage/GoWithTheGurus_NewsBackground_2.webp',
      guruUrl:
        '/watchaguru/1631664/Norbert%20Lou',
    },
    {
      
      id: 1,
      name: 'William von Mueffling',
      fonds: 'Cantillon Capital Management',
      lastUpdate: '18.03.2025',
      reportingDate: '31.12.2024',
      volume: 'Bullish',
      change: '25 Mio. $',
      imageUrl:
        'https://www.gowiththegurus.de/images/landingpage/GoWithTheGurus_NewsBackground_4.webp',
      guruUrl:
        '/watchaguru/1279936/William%20von%20Mueffling',
    },
    {
      id: 2,
      name: 'Dodge & Cox',
      fonds: 'Dodge & Cox',
      lastUpdate: '14.02.2025',
      reportingDate: '31.12.2024',
      volume: 'Bearish',
      change: '-844 Mio. $',
      imageUrl:
        'https://www.gowiththegurus.de/images/landingpage/GoWithTheGurus_NewsBackground_1.webp',
      guruUrl:
        '/watchaguru/0200217/Dodge%20&%20Cox?tab=topInfo',
    },


  ];

  return (
    <>

    <div className="news-section-container">
      {newsData.map((item) => (
        <Link to={item.guruUrl} >
        <div
          className="news-container"
          key={item.id}
          style={{
            backgroundImage: `url(${item.imageUrl})`,
          }}
        >
          <div className="info-section">
            <p>
              <strong>Update</strong> <br></br>{item.lastUpdate}
            </p>
            <p>
              <strong>Filing Date</strong> <br></br>{item.reportingDate}
            </p>
            <p>
              <strong>Trend</strong> <br></br>{item.volume}
            </p>
            <p
              className={`volume ${
                item.change.startsWith('-') ? 'decrease' : ''
              }`}
            >
              <strong>Volumen</strong> <br></br>{item.change}
            </p>
          </div>
          <div className="just-in-info-container">
          <span className="fonds-name">{item.name}</span> <br></br> <span style={{fontSize:"16px", textAlign:"right"}}>{item.fonds}</span> 
          </div>
        </div>
         </Link>
      ))}
    </div>
    </>
  );
}

export default LastIn;
