import React, { useEffect } from 'react';
import './GuruTopInfo.css';
import useMainData from '../backend/useMainData';
import { useParams } from 'react-router-dom';


function GuruTopInfo() {
  const { guruData } = useMainData();
  const { cik } = useParams();
  const guru = guruData.find(item => item.CIK === cik);



  // Hilfsfunktion: Berechne das Ende des aktuellen Quartals
  const getCurrentQuarterEnd = (reportingDate) => {
    const date = new Date(reportingDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Monate starten bei 0, daher +1
  
    let quarterEnd;
  
    if (month <= 3) {
      // Q1: Januar bis März -> Quartalsende 31.03
      quarterEnd = new Date(year, 2, 31);
    } else if (month <= 6) {
      // Q2: April bis Juni -> Quartalsende 30.06
      quarterEnd = new Date(year, 5, 30);
    } else if (month <= 9) {
      // Q3: Juli bis September -> Quartalsende 30.09
      quarterEnd = new Date(year, 8, 30);
    } else {
      // Q4: Oktober bis Dezember -> Quartalsende 31.12
      quarterEnd = new Date(year, 11, 31);
    }
  
    // Wenn das Quartalsende schon in der Vergangenheit liegt, nimm das nächste Quartal
    if (date > quarterEnd) {
      quarterEnd.setMonth(quarterEnd.getMonth() + 3); // Nächstes Quartal hinzufügen
    }
  
    return quarterEnd;
  };
  

  // Hilfsfunktion: Berechne Deadline (+45 Tage, Wochenendanpassung)
  const getDeadlineDate = (quarterEndDate) => {
    const deadline = new Date(quarterEndDate);
    deadline.setDate(deadline.getDate() + 46);

    // Anpassung für Wochenenden: Samstag -> Freitag, Sonntag -> Freitag
    if (deadline.getDay() === 6) deadline.setDate(deadline.getDate() - 1); // Samstag
    if (deadline.getDay() === 0) deadline.setDate(deadline.getDate() - 2); // Sonntag

    return deadline;
  };

  // Stelle sicher, dass die Daten vorhanden sind
  const reportingDate = guru?.REPORTING_DATE ? new Date(guru.REPORTING_DATE) : null;
  const holdingMonths = guru?.AVG_HOLDING_MONTHS
  const quarterEndDate = reportingDate ? getCurrentQuarterEnd(reportingDate) : null;
  const deadlineDate = quarterEndDate ? getDeadlineDate(quarterEndDate) : null;

  // Berechne Tage bis zur Deadline
  const currentDate = new Date();
  let daysTillDue = deadlineDate
    ? Math.ceil((deadlineDate - currentDate) / (1000 * 60 * 60 * 24) + 1)
    : null;


    let wording = daysTillDue < 0 ? 'vor ' : 'in ';
    let wording2 = ' Tagen';
  
    if (daysTillDue === 0) {
      wording = '';
      wording2 = 'Heute';
      daysTillDue = ''
    }

    if (daysTillDue === 1) {
      wording = '';
      wording2 = 'Morgen';
      daysTillDue = ''
    }

    if (daysTillDue < 0) {
      wording = 'vor ';
      wording2 = ' Tagen';
      daysTillDue = Math.abs(daysTillDue)
    }
    


  // Formatierte Ausgaben
  const formattedReportingDate = reportingDate
    ? reportingDate.toLocaleDateString('de-DE')
    : 'N/A';
  const formattedDeadlineDate = deadlineDate
    ? deadlineDate.toLocaleDateString('de-DE')
    : 'N/A';

  const guruName = guru?.NAME || 'Go With The Gurus';
  const guruFundName = guru?.FUND_NAME || 'Go With The Gurus';


  return (
        <>
        <div className="top-info-header-image-post">
            <div className="landingpage-iphone-text-container">
            <h1 style={{margin:"10px"}}>{guruFundName}</h1>
            <h5 style={{fontWeight:"300", margin:"0", fontSize:"16px"}}>{guruName}</h5>
            </div>  
        </div>

  </>
  );
}

export default GuruTopInfo;
