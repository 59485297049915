import './AboutGuruPage.css';
import useGuruInfoData from '../backend/useGuruInfoData';


function AboutGuru() {
  const { guruInfoData, loading, error } = useGuruInfoData(); // Richtiges Destrukturieren

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!guruInfoData || !guruInfoData.length) return <p>Keine Daten verfügbar</p>;

  const data = guruInfoData[0]; // Zugriff auf das erste Element im Array

  return (

    <>


    <div className="about-guru">
      <div className='about-guru-text-container'>
        <h1>Über {data.NAME}</h1>
        <section>
          <h2>Anlagestrategie</h2>
          <p>{data.STRATEGY}</p>
          <h2>Historie</h2>
          <p>{data.HISTORY}</p>
          <h2>Philosophie</h2>
          <p>{data.PHILOSOPHY}</p>
          <p className='link-container'>
            <a className='link'
              href={data.LINK} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Mehr über {data.NAME} erfahren
            </a>
          </p>
        </section>
      </div>
    </div>
    </>
  );
}

export default AboutGuru;
