import React, { useMemo, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import usePortfolioData from "../backend/usePortfolioData";
import "./GuruCurrentPortfolioTable.css"; // CSS übernehmen

const CashflowChart = () => {
  const { completePortfolioData } = usePortfolioData();
  const [chartOptions, setChartOptions] = useState({});
  const [hoveredContent, setHoveredContent] = useState({
    quarter: "Lade Daten...",
    inflows: "Lade Daten...",
    outflows: "Lade Daten...",
  }); // Standardmäßige Hover-Daten

  // Funktion zum Umformatieren der Quartalsanzeige
  const formatQuarter = (quarter) => {
    const [year, q] = quarter.split("_");
    return `${q} ${year}`; // Ausgabe: Q3 2024
  };

  const cashflowData = useMemo(() => {
    if (!completePortfolioData || completePortfolioData.length < 2) return []; // Mindestens 2 Quartale

    const calculateInflowsOutflows = (current, previous) => {
      const mapByTicker = (array) =>
        array.reduce((map, item) => {
          map[item.TICKER] = map[item.TICKER] || [];
          map[item.TICKER].push(item);
          return map;
        }, {});

      const currentByTicker = mapByTicker(current);
      const previousByTicker = mapByTicker(previous);

      let inflow = 0;
      let outflow = 0;

      for (const ticker in currentByTicker) {
        const oldStocks = previousByTicker[ticker] || [];
        const newStocks = currentByTicker[ticker];

        let oldShares = 0;
        let newShares = 0;

        oldStocks.forEach((stock) => (oldShares += parseInt(stock.sshPrnamt || 0)));
        newStocks.forEach((stock) => (newShares += parseInt(stock.sshPrnamt || 0)));

        const sharesChanged = newShares - oldShares;

        if (oldShares === 0) {
          outflow += parseFloat(newStocks[0]?.value || 0); // Neu -> jetzt als Outflow
        } else if (newShares > oldShares) {
          outflow += (newShares - oldShares) * (parseFloat(newStocks[0]?.value) / newShares); // Erhöht -> jetzt als Outflow
        } else if (newShares < oldShares) {
          inflow += (oldShares - newShares) * (parseFloat(oldStocks[0]?.value) / oldShares); // Reduziert -> jetzt als Inflow
        }
      }

      for (const ticker in previousByTicker) {
        if (!currentByTicker[ticker]) {
          const stock = previousByTicker[ticker][0];
          inflow += parseFloat(stock?.value || 0); // Verkauft -> jetzt als Inflow
        }
      }

      return { inflow, outflow };
    };

    const relevantData = completePortfolioData.slice(1);

    const cashflows = relevantData.map((current, index) => {
      const previous = completePortfolioData[index]; // Hier wird das richtige Quartal aus dem Originalarray referenziert
      if (!previous) return null;

      const { inflow, outflow } = calculateInflowsOutflows(current, previous);

      return { quarter: formatQuarter(previous[0]?.YEAR_QUARTER), inflow, outflow: outflow * -1 }; // Outflow negativ
    });

    return cashflows.filter(Boolean).reverse(); // Älteste Quartale zuerst (links)
  }, [completePortfolioData]);

  useEffect(() => {
    if (!cashflowData || cashflowData.length === 0) return;

    const xAxisData = cashflowData.map((data) => data.quarter);
    const inflowsData = cashflowData.map((data) => Math.round(data.inflow / 1000000)); // In Millionen umrechnen
    const outflowsData = cashflowData.map((data) => Math.round(data.outflow / 1000000)); // In Millionen umrechnen

    // Standardmäßig die letzten Werte im Hover anzeigen
    const lastIndex = xAxisData.length - 1;
    setHoveredContent({
      quarter: xAxisData[lastIndex],
      inflows: inflowsData[lastIndex].toLocaleString("de-DE"),
      outflows: outflowsData[lastIndex].toLocaleString("de-DE"),
    });

    setChartOptions({
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params) => {
          if (!params || params.length < 2) {
            return ""; // Keine Anzeige, wenn keine Daten vorhanden sind
          }

          const [inflows, outflows] = params;

          setHoveredContent({
            quarter: inflows?.axisValue || "Unbekannt",
            inflows: inflows?.data ? inflows.data.toLocaleString("de-DE") : "0",
            outflows: outflows?.data ? outflows.data.toLocaleString("de-DE") : "0",
          });

          return ""; // Keine Standardanzeige im Tooltip
        },
      },
      legend: {
        data: [
          { name: "Inflows", icon: "circle", itemStyle: { color: "rgba(0, 255, 170, 0.8)" } }, // Blau
          { name: "Outflows", icon: "circle", itemStyle: { color: "rgba(0,0,0,0.6)" } }, // Schwarz
        ],
        top: "5%",
        right: "2%",
        orient: "horizontal",
        textStyle: {
          color: "#fff",
          fontSize: 12,
        },
      },
      grid: {
        left: "1%",
        right: "2%",
        top: "6%",
        bottom: "5%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        axisLine: {
          lineStyle: {
            color: "#ccc",
          },
        },
        axisLabel: {
          color: "#fff",
          fontSize: 12,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: "#fff",
          fontSize: 12,
          formatter: (value) => `${value} Mio. $`, // "Mio" an die Y-Achsen-Werte anhängen
        },
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: "Inflows",
          type: "bar",
          stack: "cashflow", // Gruppiert Inflows und Outflows in einer Linie
          data: inflowsData,
          itemStyle: {
            color: "rgba(0, 255, 170, 0.8)", // Blau
          },
        },
        {
          name: "Outflows",
          type: "bar",
          stack: "cashflow", // Gruppiert Inflows und Outflows in einer Linie
          data: outflowsData,
          itemStyle: {
            color: "rgba(0, 0, 0, 0.4)", // Schwarz
          },
        },
      ],
    });
  }, [cashflowData]);

  if (!completePortfolioData || completePortfolioData.length < 2)
    return <div>Keine ausreichenden Daten vorhanden</div>;

  return (
    <div className="cashflow-chart">
      {/* Hovered Content anzeigen */}
      {hoveredContent && (
        <div className="hovered-container">
          <div className="hovered-left">
            <strong>{hoveredContent.quarter}</strong>
          </div>
          <div className="hovered-right">
            <>Inflows: <strong>{hoveredContent.inflows} Mio $</strong><br /> Outflows: <strong>{hoveredContent.outflows} Mio $</strong></>
          </div>
        </div>
      )}

      {/* Chart */}
      <ReactECharts option={chartOptions} style={{ height: 400 }} />
    </div>
  );
};

export default CashflowChart;
