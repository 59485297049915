import './BuysAndSellsPage.css';
import BuysAndSellsTable from './BuysAndSellsTable';
import CashflowChart from './CashflowChart';

function BuysAndSellsPage() {

  return (
    <div className='main-container'>
<BuysAndSellsTable/>
<div className="cashflow-chart-background">
<div className='headline'>In & Outflows</div>
          <div className='gics-line' >Entwicklung der Käufe und Verkäufe im Zeitverlauf
          </div>
<CashflowChart/>
    </div>
    </div>
  );
}

export default BuysAndSellsPage;
