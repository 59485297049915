import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import './GuruPage.css';
import GuruCurrentPortfolioTable from './GuruCurrentPortfolioTable';
import GuruTopInfo from './GuruTopInfo';
import GuruSecondInfo from './GuruSecondInfo';
import BuysAndSellsPage from './BuysAndSellsPage';
import PortfolioChart from './PortfolioChart';
import SectorTable from './SectorTable';
import ReturnChart from './ReturnChart';
import ReturnBarChart from './ReturnBarChart';
import AboutGuru from './AboutGuruPage';
import { Helmet } from 'react-helmet-async'; // Neuer Import


function GuruPage() {
  const { cik, name } = useParams(); // Holt die dynamischen Teile der URL
  const [searchParams, setSearchParams] = useSearchParams(); // Holt die Query-Parameter
  const navigate = useNavigate();

  const tabFromUrl = searchParams.get('tab') || 'topInfo'; // Standardwert ist 'topInfo'
  const [activeTab, setActiveTab] = useState(tabFromUrl);

  useEffect(() => {
    // Aktualisiert den aktiven Tab, wenn sich die URL-Parameter ändern
    setActiveTab(tabFromUrl);
  }, [tabFromUrl]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSearchParams({ tab }); // Ändert die URL, ohne die Seite zu reloaden
  };

  useEffect(() => {
    document.title = `${name} Portfolio | Go With The Gurus`;
    window.scrollTo(0, 0); // Scrollt beim Laden der Seite nach oben
  }, [name]); // Jetzt wird der Titel aktualisiert, wenn sich `name` ändert
  

  return (

    


    <div className="main-container">

<Helmet>
        <title>{`${name} Portfolio | Go With The Gurus`}</title>
        <meta name="description" content={`Erfahre mehr über das Portfolio von ${name} | Go With The Gurus`} />
        <meta name="keywords" content={`Portfolio, ${name}, 13F Filings, Super Investoren, Investments, Aktien, Börse, Go With The Gurus`} />
        <meta name="author" content="Dein Name oder deine Marke" />
        <meta property="og:title" content={`${name} Portfolio | 13F Filings der Super Investoren`} />
        <meta property="og:description" content={`Erfahren Sie mehr über das Portfolio von ${name}. Analyse der 13F-Filings und die besten Investitionen der Super-Investoren.`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/path/to/image.jpg" /> {/* Beispiel-Bild für Social Media */}
        <link rel="canonical" href={window.location.href} />
      </Helmet>


      <GuruTopInfo />

      {/* Tab-Navigation */}
      <div className="tab-container">
        <button
          className={`tab-button ${activeTab === 'topInfo' ? 'active' : ''}`}
          onClick={() => handleTabClick('topInfo')}
        >
          Portfolio
        </button>
        <button
          className={`tab-button ${activeTab === 'portfolio' ? 'active' : ''}`}
          onClick={() => handleTabClick('portfolio')}
        >
          Activity
        </button>
        <button
          className={`tab-button ${activeTab === 'about-guru' ? 'active' : ''}`}
          onClick={() => handleTabClick('about-guru')}
        >
          About
        </button>
      </div>



      {/* Inhalt basierend auf dem aktiven Tab */}
      {activeTab === 'topInfo' && (
        <>
      <GuruSecondInfo/>
          <GuruCurrentPortfolioTable />
          <div className="portfolio-chart-background">
            <div className='headline'>Portfolio</div>
            <div className='gics-line'>Entwicklung der Assets under Management / Holdings im Zeitverlauf</div>
            <PortfolioChart />

            <div className='headline'>Return</div>
            <div className='gics-line'>Time Weighted Return</div>
            <ReturnChart />


            <ReturnBarChart />
            <div className="disclaimer-header">Disclaimer</div>
            <div className="disclaimer-line">
              Die hier dargestellten Berechnungen basieren auf dem Time Weighted Return (TWR)-Verfahren. Dieses Verfahren eliminiert den Einfluss von Ein- und Auszahlungen auf die Rendite und misst die Performance einer Investition unabhängig von der zeitlichen Verteilung der Kapitalflüsse. Dadurch ermöglicht der TWR eine objektive Bewertung der reinen Anlageperformance. Bitte beachten Sie, dass sich die Berechnungen derzeit in der Beta-Phase befinden und noch überprüft werden sollten.
            </div>
          </div>

          <div className='portfolio-chart-container'>
            <div className='headline'>Sektorverteilung</div>
            <div className='gics-line'>Die Global Industry Classification Standard (GICS) teilt Unternehmen weltweit in 11 Hauptsektoren ein</div>
            <SectorTable />
          </div>
        </>
      )}
      {activeTab === 'portfolio' && (
        <>
          <BuysAndSellsPage />
        </>
      )}
      {activeTab === 'about-guru' && (
        <>
          <AboutGuru />
        </>
      )}
    </div>
  );
}

export default GuruPage;
