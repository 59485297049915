import "./Newsletter.css";

function Newsletter() {
  return (
    <div className="newsletter-background">
      <div className="newsletter-container">
      <div className="newsletter-headline">Abonniere unseren Newsletter</div>
<div className="newsletter-subheadline">
Bleibe immer informiert über die neuesten 13F Filings und erhalte exklusive Einblicke in institutionelle Investitionen!
</div>
<form name="contact" method="POST" data-netlify="true" className="newsletter-form">

          <input type="hidden" name="form-name" value="contact" />
          <input 
            type="email" 
            name="email" 
            placeholder="Gib deine E-Mail-Adresse ein" 
            required 
            className="newsletter-input" 
          />
          <button type="submit" className="newsletter-button">jetzt abonnieren</button>
        </form>
      </div>
    </div>
  );
}

export default Newsletter;
