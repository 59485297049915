import React, { useState, useEffect } from 'react';
import './GuruTable.css';
import useMainData from '../backend/useMainData';
import arrow from '../assets/arrow.svg';
import { Link } from 'react-router-dom';

function GuruTable() {
  const { guruData, loading, error } = useMainData();
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'VALUE', direction: 'desc' });
  const [isMobile, setIsMobile] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const formatNumber = (number) => {
    return number
      .toFixed(0)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const parseValue = (key, data) => {
    if (key === 'VALUE') {
      return parseFloat(data.VALUE);
    }
    if (key === 'percentage') {
      return (data.TOP_HOLDING / data.VALUE) * 100;
    }
    if (typeof data[key] === 'string') {
      return data[key].toLowerCase();
    }
    return data[key];
  };

  const calculateNextDeadline = (reportingDate) => {
    const reportingDateObj = new Date(reportingDate);

    const quarterEndDates = [
      new Date(reportingDateObj.getFullYear(), 2, 31),
      new Date(reportingDateObj.getFullYear(), 5, 30),
      new Date(reportingDateObj.getFullYear(), 8, 30),
      new Date(reportingDateObj.getFullYear(), 11, 31),
    ];

    let nextQuarterEnd = quarterEndDates.find(date => date > reportingDateObj);

    if (!nextQuarterEnd) {
      nextQuarterEnd = new Date(reportingDateObj.getFullYear() + 1, 2, 31);
    }

    nextQuarterEnd.setDate(nextQuarterEnd.getDate() + 45);

    if (nextQuarterEnd.getDay() === 6) {
      nextQuarterEnd.setDate(nextQuarterEnd.getDate() - 1);
    } else if (nextQuarterEnd.getDay() === 0) {
      nextQuarterEnd.setDate(nextQuarterEnd.getDate() - 2);
    }

    return nextQuarterEnd;
  };

  useEffect(() => {
    if (guruData && guruData.length > 0) {
      const sorted = [...guruData].sort((a, b) => {
        const aValue = parseValue(sortConfig.key, a);
        const bValue = parseValue(sortConfig.key, b);

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });

      setSortedData(sorted);
    }
  }, [guruData, sortConfig]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const toggleRowExpand = (index) => {
    if (!isMobile) return;
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <div className='main-container'>
      <main className='content'>
        <div className="guru-table">
          {loading ? (
            <p className='loading'>Lade Daten...</p>
          ) : error ? (
            <p className='error'>Fehler beim Laden der Daten: {error.message}</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th onClick={() => handleSort('NAME')}>
                    Guru {sortConfig.key === 'NAME' && (
                      <img
                        src={arrow}
                        alt="Sort"
                        className={sortConfig.direction === 'asc' ? 'sort-arrow-up' : 'sort-arrow-down'}
                      />
                    )}
                  </th>
                  <th onClick={() => handleSort('VALUE')}>
                    AUM {sortConfig.key === 'VALUE' && (
                      <img
                        src={arrow}
                        alt="Sort"
                        className={sortConfig.direction === 'asc' ? 'sort-arrow-up' : 'sort-arrow-down'}
                      />
                    )}
                  </th>
                  <th onClick={() => handleSort('HOLDINGS')}>
                    Holdings {sortConfig.key === 'HOLDINGS' && (
                      <img
                        src={arrow}
                        alt="Sort"
                        className={sortConfig.direction === 'asc' ? 'sort-arrow-up' : 'sort-arrow-down'}
                      />
                    )}
                  </th>
                  <th onClick={() => handleSort('percentage')}>
                    Top Holding {sortConfig.key === 'percentage' && (
                      <img
                        src={arrow}
                        alt="Sort"
                        className={sortConfig.direction === 'asc' ? 'sort-arrow-up' : 'sort-arrow-down'}
                      />
                    )}
                  </th>
                  <th onClick={() => handleSort('REPORTING_DATE')}>
                    Reporting Date {sortConfig.key === 'REPORTING_DATE' && (
                      <img
                        src={arrow}
                        alt="Sort"
                        className={sortConfig.direction === 'asc' ? 'sort-arrow-up' : 'sort-arrow-down'}
                      />
                    )}
                  </th>
                  <th>Nächste Deadline</th>
                </tr>
              </thead>
              <tbody>
                {sortedData.length > 0 ? (
                  sortedData.map((guru, index) => {
                    const formattedDate = new Date(guru.REPORTING_DATE).toLocaleDateString('de-DE');
                    const deadline = calculateNextDeadline(guru.REPORTING_DATE);
                    const formattedDeadline = deadline.toLocaleDateString('de-DE');
                    const today = new Date();
                    const diffInDays = (deadline - today) / (1000 * 60 * 60 * 24);
                    const portfolioValue = formatNumber(guru.VALUE / 1);
                    const percentage = ((guru.TOP_HOLDING / guru.VALUE) * 100).toFixed(1).replace('.', ',');

                    return (
                      <React.Fragment key={index}>
                        <tr
                          className="table-row"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <td>
                            <Link to={`/watchaguru/${guru.CIK}/${guru.NAME}`}>
                              <div className="holding-info">
                                <div className="holding-name">{guru.NAME}</div>
                                <div className="holding-percentage">{guru.FUND_NAME}</div>
                              </div>
                            </Link>
                          </td>
                          <td>{portfolioValue} $</td>
                          <td>{guru.HOLDINGS}</td>
                          <td>
                            <div className="top-holding">
                              <div className="holding-details">
                                <img
                                  src={`https://www.gowiththegurus.de/images/logos/${guru.TOP_HOLDING_TICKER}.svg`}
                                  alt={`${guru.TOP_HOLDING_NAME} Logo`}
                                  className="holding-logo"
                                  onError={(e) => (e.target.src = '/path/to/fallback-image.svg')}
                                />
                                <div className="holding-info">
                                  <div className="holding-name">{guru.TOP_HOLDING_NAME}</div>
                                  <div className="holding-percentage">{percentage} %</div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>{formattedDate}</td>
                          <td>
                            <div className="top-holding">
                              <div className="holding-details">
                                <div className="holding-info">
                                  <div className="holding-name">{formattedDeadline}</div>
                                  <div className="holding-percentage">
                                    {diffInDays < -1 ? (
                                      <div className="lamp-container">
                                        <div className="lamp red-bg"></div>
                                        <span className='red-c'>Daten veraltet</span>
                                      </div>
                                    ) : diffInDays <= 10 ? (
                                      <div className="lamp-container">
                                        <div className="lamp orange-bg"></div>
                                        <span className='orange-c'>Update bald fällig</span>
                                      </div>
                                    ) : (
                                      <div className="lamp-container">
                                        <div className="lamp green-bg"></div>
                                        <span className='green-c'>Daten aktuell</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {isMobile && expandedRow === index && (
                         
                          <tr className="expanded-row mobile-only">
                            <td colSpan="2">
                              <div className="expanded-content">
                              <div className="holding-percentage">
                              <div>
  {diffInDays < -1 ? (
    <div className="lamp-container">
      <div className="lamp red-bg"></div>
      <span className='red-c'>Daten veraltet</span>
    </div>
  ) : diffInDays <= 10 ? (
    <div className="lamp-container">
      <div className="lamp orange-bg"></div>
      <span className='orange-c'>Update bald fällig</span>
    </div>
  ) : (
    <div className="lamp-container">
      <div className="lamp green-bg"></div>
      <span className='green-c'>Daten aktuell</span>
    </div>
  )}
</div>

                                  </div>
                              <p><strong style={{opacity:0.8}}>Name</strong> {guru.NAME}</p>
                        <p><strong style={{opacity:0.8}}>Fonds</strong> {guru.FUND_NAME}</p>
                        <p><strong style={{opacity:0.8}}>AUM</strong> {portfolioValue} $</p>
                        <p><strong style={{opacity:0.8}}>Top Holding</strong> {guru.TOP_HOLDING_NAME}</p>
                        <p><strong style={{opacity:0.8}}>Nächste Deadline</strong> {formattedDeadline}</p>
                              </div>
                              
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="2" className='no-data'>Keine Daten verfügbar</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </main>
    </div>
  );
}

export default GuruTable;
