import "./css/GuruPost.css";
import { useState, useEffect } from "react";
import arrow from "../../assets/arrow.svg"
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function DevKantesaria() {

          // Tab-Titel mit document.title setzen
          useEffect(() => {
            window.scrollTo(0, 0); // Scrollt beim Laden der Seite nach oben
          }, []); // Leeres Array sorgt dafür, dass der Effekt nur einmal bei Mount ausgeführt wird

    return (
        
        <>

            <Helmet>
                <title>Dev Kantesaria | Vom Harvard-Mediziner zum Hedgefonds-Manager</title>
                <meta name="description" content="Dev Kantesaria ist ein bekannter Investor und Hedgefonds-Manager, dessen ungewöhnlicher Werdegang – vom Harvard-Mediziner zum Leiter eines Milliardenfonds – ebenso beeindruckend ist wie seine Anlagestrategie. Als Gründer des Hedgefonds Valley Forge Capital Management verwaltet er heute mehrere Milliarden US-Dollar und hat über Jahrzehnte den Markt deutlich geschlagen." />
            </Helmet>
        



        

        <div className="blog-post-header-image-DK">
            <div className="landingpage-iphone-text-container">
            <h1 style={{ margin:"10px"}}>Vom Harvard-Mediziner zum Hedgefonds-Manager</h1>
            <h5 style={{fontWeight:"300", margin:"0", fontSize:"16px"}}>Dev Kantesaria</h5>
            <h5 style={{fontWeight:"300", margin:"5px", color:"rgba(255, 255, 255, 0.5)"}}>22. März 2025</h5>
            </div>
        </div>


        <div className="post-container-header-background">
        <div className="post-container-header">

<h2 style={{margin:"0", padding:"20px 0"}}>Inhalt</h2>
<p><a className="index-list" href="#Devkantesaria">Dev Kantesaria</a></p>
<p><a className="index-list" href="#Hintergrund und Karriere">Hintergrund und Karriere</a></p>
<p><a className="index-list" href="#Anlagestrategie">Anlagestrategie: Qualität vor Quantität</a></p>
<p><a className="index-list" href="#Erfolge und Herausforderungen">Erfolge und Herausforderungen</a></p>
<p><a className="index-list" href="#Philosophie und Denkweise">Philosophie und Denkweise</a></p>
<p><a className="index-list" href="#Einfluss und Vermächtnis">Einfluss und Vermächtnis</a></p>
<p><a className="index-list" href="#Quellen">Quellen</a></p>
<p><a className="index-list" href="#Weiterführende Informationen">Weiterführende Informationen</a></p>

</div>
</div>




        <div className="post-container">



            
            <div className="post-content">
            <h2 id="Devkantesaria"><strong>Dev Kantesaria</strong></h2>
            <div className="blog-image-container">
            <img src="https://www.gowiththegurus.de/images/blog/Header_background-DK.jpg"></img>
            </div>
            <p style={{fontStyle:"italic", color:"rgba(0,0,0,0.6)", fontSize:"14px"}}>Dev Kantesaria</p>
            <p>
            Dev Kantesaria ist ein bekannter Investor und Hedgefonds-Manager, dessen ungewöhnlicher Werdegang – vom Harvard-Mediziner zum Leiter eines Milliardenfonds – ebenso beeindruckend ist wie seine Anlagestrategie. Als Gründer des Hedgefonds Valley Forge Capital Management verwaltet er heute mehrere Milliarden US-Dollar und hat über Jahrzehnte den Markt deutlich geschlagen​. 
            <br></br>
            Doch wer ist dieser Mann, welche Strategien verfolgt er, und was können Anleger von ihm lernen?
            <br></br>
            <br></br>
            <h2 id="Hintergrund und Karriere"><strong>Hintergrund und Karriere</strong></h2>
            <div className="blog-image-container">
            <img src="https://www.gowiththegurus.de/images/blog/Gordon_Hall_Harvard_Medical.webp"></img>
            </div>
            <p style={{fontStyle:"italic", color:"rgba(0,0,0,0.6)", fontSize:"14px"}}>Dev Kantesaria schlug zunächst einen medizinischen Karriereweg ein – hier die Harvard Medical School, an der er seinen Abschluss machte.</p>
            Bereits in Kindheitstagen entdeckte Dev Kantesaria seine Leidenschaft für Aktien. Trotzdem entschied er sich zunächst für eine akademische Laufbahn in den Naturwissenschaften: Er studierte Biologie am MIT und gehörte an der Harvard Medical School zu den Besten seines Jahrgangs.
            <br></br>
            <br></br>
            Ursprünglich wollte Kantesaria Chirurg werden, doch während des Medizinstudiums merkte er, dass seine eigentliche Passion die Finanzwelt war.
            Nach dem Abschluss wandte er sich deshalb der Wirtschaft zu: Er begann seine Karriere als Berater bei McKinsey & Co., wechselte dann in die Venture-Capital-Branche (u.a. als Principal bei TL Ventures und General Partner bei Devon Park Bioventures). <br></br>
            In diesen Jahren sammelte er wertvolle Erfahrungen, indem er mit hunderten Unternehmensgründern und Vorständen arbeitete – Einblicke, die ihm ein tiefes Verständnis für Geschäftsmodelle vermittelten.
            <br></br>
            <br></br>
            2007 wagte Kantesaria den Schritt, seinen eigenen Hedgefonds zu gründen. Mit nur etwa 300.000 US-Dollar Startkapital – hauptsächlich von Familie und Freunden – hob er Valley Forge Capital Management aus der Taufe.
            Die Anfangsjahre waren herausfordernd: „Ich erinnere mich, wie ich anfangs vier Stunden pro Strecke fuhr, um einen Investor für 300.000 Dollar zu treffen“, erzählt Kantesaria.
            Er präsentierte potenziellen Geldgebern akribisch seine eigenen (von Wirtschaftsprüfern testierten) Investmentergebnisse und baute ein enges Vertrauensverhältnis zu ihnen auf.
            <br></br>
            <br></br>
            Dieser persönliche Einsatz zahlte sich aus – über die Jahre wuchs der Fonds kontinuierlich. Kantesaria verlagerte den Firmensitz während der Covid-Pandemie von Pennsylvania nach Miami, was ihm – ähnlich wie prominenten Branchenkollegen Ken Griffin (Citadel) und Paul Singer (Elliott) – neue Möglichkeiten eröffnete.
            Heute gehört Valley Forge Capital mit einem verwalteten Vermögen von fast 5 Milliarden Dollar zu den großen Hedgefonds und beschäftigt ein kleines Team von rund acht Mitarbeitern.
            Dev Kantesaria ist ein bekannter Investor und Hedgefonds-Manager, dessen ungewöhnlicher Werdegang – vom Harvard-Mediziner zum Leiter eines Milliardenfonds – ebenso beeindruckend ist wie seine Anlagestrategie. Als Gründer des Hedgefonds Valley Forge Capital Management verwaltet er heute mehrere Milliarden US-Dollar und hat über Jahrzehnte den Markt deutlich geschlagen​. 
            <br></br>
            <br></br>

            <h2 id="Anlagestrategie"><strong>Anlagestrategie: Qualität vor Quantität</strong></h2>
            Kantesaria verfolgt einen Quality-Investing-Ansatz und setzt auf wenige, dafür außergewöhnlich hochwertige Aktien. Sein Ziel ist es, die Kaufkraft seines Kapitals über viele Jahre hinweg erheblich zu steigern und den Aktienmarkt langfristig zu übertreffen. Dabei verlässt er sich auf fundamentale Bottom-up-Analysen und strikte Disziplin. Die wichtigsten Grundpfeiler von Kantesarias Strategie sind:
            <br></br>
                
            <ul>
            <strong>Geduld und langfristiger Fokus</strong>
            Kantesaria betont, dass an der Börse Temperament wichtiger als Timing ist. Er vergleicht geduldiges Investieren sogar mit dem berühmten Marshmallow-Test – wer warten kann, wird am Ende mit „dem zweiten Keks“ belohnt.
            <br></br>
            Kurzfristige Schlagzeilen und Quartalszahlen blendet er aus und richtet seinen Blick stattdessen auf „langfristige, berechenbare Wachstumstrends“ in Branchen, die über viele Jahre wachsen können.
            <br></br>
            Diese ruhige, langfristige Herangehensweise erfordert Disziplin, zahlt sich aber aus, wenn andere in hektischen Marktphasen Fehler machen.

            <strong>Konzentriertes Portfolio</strong>
            Anstatt dutzende Aktien zu halten, fokussiert sich Kantesaria auf seine allerbesten Ideen. „Warum sollte man seine 15.-beste Idee besitzen?“ lautet eine seiner provokanten Fragen.
            Tatsächlich umfasst sein Portfolio meist weniger als zehn Titel, in die er mit hoher Überzeugung investiert.
            <br></br>
            Kantesarias Fondsportfolio (Stand Q4 2024) ist auf wenige große Positionen konzentriert. Seine fünf größten Beteiligungen – darunter Fair Isaac Corp. (FICO), S&P Global, Mastercard, Moody's und Visa – machten Ende 2024 zusammen rund 90 % des Gesamtportfolios aus.
            <br></br>
            Diese extreme Fokussierung spiegelt Kantesarias Überzeugung wider, dass nur die qualitativ stärksten Unternehmen einen Platz im Depot verdienen. Er strebt eine Erfolgsquote von 9 aus 10 Investments an und will nicht – wie im Venture Capital üblich – darauf hoffen müssen, dass von zehn Wetten ein oder zwei „Home Runs“ dabei sind.

            <strong>Strenge Qualitätskriterien</strong>

            Kantesaria investiert nur in Unternehmen, die hohe wirtschaftliche Burggräben und berechenbare Geschäftsmodelle aufweisen. Er sagt lieber „Nein“ zu einer Gelegenheit, wenn diese nicht seinen Kriterien entspricht.
            <br></br>
            Kapitalintensive Branchen, in denen ständig große Summen reinvestiert werden müssen – etwa Automobilhersteller – meidet er, da dort wenig freier Cashflow für die Aktionäre übrig bleibt.            
            <br></br>
            Ähnlich skeptisch ist er bei Geschäftsmodellen ohne eindeutige Wettbewerbsvorteile (z.B. Fluggesellschaften) oder in Märkten mit brutalem Preiskampf.
            <br></br>
            Auch unreife „Zukunftsbranchen“ ohne klaren Gewinner (etwa autonome Fahrzeuge) stehen nicht auf seiner Einkaufsliste – zu hoch ist die Unsicherheit über die zukünftige Entwicklung.
            Und Turnaround-Wetten auf strauchelnde Firmen kommen für ihn grundsätzlich nicht in Frage.
            Statt auf den „nächsten heißen Trend“ setzt Kantesaria lieber auf Sektoren, die sich in absehbarer Zukunft kaum verändern werden.
            Er sucht nach etablierten Firmen mit Monopol- oder Oligopolstellung, die nur geringe Reinvestitionen benötigen und dadurch einen Großteil ihrer Gewinne für Wachstum oder Aktionäre verwenden können.
            Solche Unternehmen mit Preissetzungsmacht und langen Wachstumsperspektiven bezeichnet er als „Compounding Machines“ – Rendite-Maschinen, die über Jahrzehnte Erträge für Anleger erwirtschaften können.
             </ul>
             Zusammengefasst lässt sich Kantesarias Ansatz als konzentriertes Value-Investing mit Qualitätsfokus beschreiben. Er agiert vorausschauend wie ein Unternehmer, der sich an herausragenden Firmen beteiligt, und hält an ihnen fest, solange die fundamentale Story stimmt. Dieses Konzept – wenige exzellente Firmen langfristig begleiten – erinnert an die Strategie von Investmentlegenden wie Warren Buffett.
             <br></br>
             <br></br>


             <h2 id="Erfolge und Herausforderungen"><strong>Erfolge und Herausforderungen</strong></h2>     
             <div className="blog-image-container">
            <img src="https://www.gowiththegurus.de/images/blog/spgi.webp"></img>
            </div>
            <p style={{fontStyle:"italic", color:"rgba(0,0,0,0.6)", fontSize:"14px"}}>Standard & Poors</p>
             Kantesarias disziplinierter Ansatz hat sich in beeindruckenden Ergebnissen niedergeschlagen. 
             Seit der Gründung seines Fonds im Jahr 2007 erzielte Valley Forge Capital Schätzungen zufolge eine durchschnittliche Rendite von knapp 15 % pro Jahr, deutlich mehr als die ca. 10 % p.a. des S&P-500-Index im selben Zeitraum.
             Dank dieses überdurchschnittlichen Erfolgs wuchs das von ihm verwaltete Vermögen von anfänglich $300.000 auf heute rund $4–5 Milliarden an. 
             <br></br>
             <br></br>
            Auch auf Einzelinvestment-Ebene kann Kantesaria bemerkenswerte Multibagger vorweisen – also Aktien, die ihren Wert vervielfacht haben. Ein herausragendes Beispiel ist S&P Global (SPGI): Inmitten der Finanzkrise 2008/09, als der Kreditrating-Gigant wegen zahlreicher Klagen unter Druck stand, griff Kantesaria zu und erwarb große Mengen der Aktie für um die $20.
            Diese mutige Entscheidung zahlte sich aus – heute notiert S&P Global bei weit über $400 und bescherte ihm mehr als das 25-Fache seines Einsatzes. 
            Ähnlich erfolgreich war seine Investition in Intuit, den Softwareanbieter hinter QuickBooks und TurboTax: 2016 stieg er bei Kursen um die $100 ein, in den folgenden Jahren kletterte die Aktie auf über $600.
            Sein vielleicht größter Coup ist jedoch Fair Isaac Corporation (FICO) – bekannt für die FICO-Kreditwürdigkeitsscores. Seit 2018 baute er diese Position kontinuierlich aus; insgesamt verzeichnet FICO seit seinen Einstiegen einen Wertzuwachs von über 1000 % (also mehr als eine Verzehnfachung).
            Diese Beispiele illustrieren, wie Kantesaria durch das langfristige Halten von Qualitätsunternehmen enorme Gewinne realisieren konnte.
            <br></br>
            <br></br>
            Doch auch für einen Ausnahmeinvestor wie ihn gab es Herausforderungen. Nur ein Jahr nach Fondsstart stürzte die Welt in die Finanzkrise 2008 – ein Härtetest für Kantesarias junge Firma. Anstatt jedoch in Panik zu verfallen, blieb er seiner Strategie treu und nutzte die Krise gezielt, um günstig in seine Favoriten einzusteigen (wie das Beispiel S&P Global zeigt).
            Seine Fähigkeit, in turbulenten Zeiten Ruhe zu bewahren, wurde später erneut auf die Probe gestellt, etwa während des Corona-Crashs 2020. Kantesaria aber hielt an seinen Überzeugungen fest und vertraute auf die Stärke seiner ausgewählten Unternehmen, selbst als die Märkte kurzfristig einbrachen. Geduld und Disziplin in Krisenzeiten gehören zu seinen obersten Prinzipien: „Die richtige Haltung und Disziplin sind der Schlüssel zu überlegenen Renditen. Geduldig zu bleiben und unemotional durch Marktschwankungen zu gehen, ermöglicht es uns, Chancen zu ergreifen, wenn andere irrational oder ängstlich handeln“, erklärt er.
            Dieses Credo half ihm, schwierige Phasen zu meistern und sogar gestärkt aus ihnen hervorzugehen. Eine weitere Herausforderung war der Aufbau seines Fonds aus dem Nichts – die mühsame Gewinnung der ersten Investoren hat Kantesaria geprägt und ihm gezeigt, wie wichtig langfristiges Vertrauen ist.
            Heute, da sein Hedgefonds etabliert ist, besteht die Herausforderung vor allem darin, die erzielten Erfolge zu halten und weiter auszubauen, ohne von seinen Anlageprinzipien abzuweichen.
            <br></br>
            <br></br>
            <h2 id="Philosophie und Denkweise"><strong>Philosophie und Denkweise</strong></h2>    
            Dev Kantesarias Investmentphilosophie ist von Bodenständigkeit und konsequenter Prinzipientreue geprägt. 
            Obwohl er einen akademischen Hintergrund als Naturwissenschaftler und Mediziner hat, zeigt sich in seiner Denkweise vor allem wirtschaftlicher Scharfsinn und langfristiges Visionärstum. 
            Er betrachtet Aktien als Anteile an echten Unternehmen – nicht als bloße Handelspapiere. 
            Entsprechend gründlich analysiert er Geschäftsmodelle, Managementqualitäten und Wettbewerbsvorteile, bevor er investiert. 
            Seine Erfahrung aus der Venture-Capital-Zeit – als er junge Biotech-Firmen bewertete und Risiken abschätzen musste – lehrt ihn, immer auch nach vorne zu blicken: Was könnte ein Unternehmen in 5–10 Jahren erreichen? 
            Dieses zukunftsorientierte Denken ist zu einem wichtigen Bestandteil seines „Edge“ als Investor geworden.
            <br></br>
            <br></br>
            Ein zentrales Element von Kantesarias Philosophie ist Geduld. Er warnt Anleger davor, in hektischem Aktionismus zu verfallen oder jedem Trend hinterherzujagen. 
            Stattdessen verweist er auf die Macht des Zinseszins-Effekts: Qualitätsunternehmen, die über Jahre Gewinne reinvestieren und wachsen, können wahre Wunder für das Vermögen bewirken. 
            Ihm zufolge braucht es vor allem die richtige mentale Einstellung, um an der Börse erfolgreich zu sein – die Fähigkeit, Emotionen zu kontrollieren und langfristig zu denken.
            <br></br>
            <br></br>
            Kurzfristige Marktvolatilität sieht er eher als Chance denn als Bedrohung, denn sie erlaubt es dem besonnenen Investor, in Phasen der Angst günstig Positionen aufzubauen. 
            Kantesaria lebt damit das Motto: „Be fearful when others are greedy, and greedy when others are fearful“, wie es Warren Buffett formuliert hat – also antizyklisch handeln und niemals der Masse blind folgen.
            <br></br>
            <br></br>
            Tatsächlich bekennt sich Dev Kantesaria offen zu den Einflüssen von Warren Buffett und anderen Value-Investoren auf seine Denkweise. 
            Seine Strategie „spiegelt das Playbook von Buffett“ wider, berichtet die Fachpresse.
            Langfristiges Halten von bewährten, großkapitalisierten Qualitätsunternehmen mit robusten Wettbewerbsvorteilen. 
            Wie Buffett setzt Kantesaria auf konzentrierte Wetten, die er genau versteht, anstatt ein breit gestreutes „Klumpenrisiko“ einzugehen. 
            Dabei erhebt er allerdings seine eigene Messlatte: Während Buffett berühmtermaßen nach „ausreichend guten“ Firmen zu fairen Preisen sucht, fokussiert sich Kantesaria scheinbar ausschließlich auf die allerbesten Unternehmen, die er finden kann – getreu seinem Grundsatz, nur die Top-Ideen ins Portfolio zu nehmen. 
            Seine medizinisch-naturwissenschaftliche Ausbildung verleiht seiner Analysemethodik zudem einen datengetriebenen, analytischen Anstrich. 
            Man könnte sagen, Kantesaria behandelt das Investieren mit der Sorgfalt eines Arztes: Er diagnostiziert die „Gesundheit“ eines Unternehmens akribisch, bevor er ein Rezept – sprich Investment – ausstellt.
            <br></br>
            <br></br>
            <h2 id="Einfluss und Vermächtnis"><strong>Einfluss und Vermächtnis</strong></h2> 
            <div className="blog-image-container">
            <img src="https://www.gowiththegurus.de/images/blog/WS.webp"></img>
            </div>
            <p style={{fontStyle:"italic", color:"rgba(0,0,0,0.6)", fontSize:"14px"}}>Peter Tuchman auf dem Floor der NYSE</p>
            In der Finanzwelt hat Dev Kantesaria trotz seiner relativen Unbekanntheit in der Öffentlichkeit deutliche Spuren hinterlassen. 
            Fachkreise zählen ihn zu den erfolgreichsten Investoren unserer Zeit, auch wenn sein Name nicht so geläufig ist wie der mancher Wall-Street-Legenden.
            Seine eindrucksvollen Renditen und sein stringenter Investmentstil haben ihm Anerkennung und Respekt unter Profi-Anlegern eingebracht. 
            So wird er in Rankings mittlerweile in einem Atemzug mit Größen der Hedgefonds-Branche genannt (ein Beispiel: in einem aktuellen Ranking gehörte er zu den Top 10 Hedgefonds-Managern mit bester Performance). 
            Vor allem aber dient Kantesaria vielen jungen Anlegern als Vorbild: Er beweist, dass man mit Geduld, Konzentration und unabhängiger Denkweise den Markt schlagen kann – und das sogar mit vermeintlich „langweiligen“ Qualitätsaktien.
            In einer Zeit, in der oft riskante Wetten und schnelle Trades im Vordergrund stehen, hält er die Fahne des klassischen Value-Investing hoch und zeigt, dass konservative Prinzipien keineswegs altmodisch sind, sondern sehr profitabel sein können.
            <br></br>
            <br></br>
            Kantesaria teilt sein Wissen und seine Philosophie auch bereitwillig. 
            In Interviews, Podcasts und Vorträgen gibt er Einblick in seine Strategien, was ihn zu einem geschätzten Vordenker für qualitätsorientiertes Investieren macht.
            Seine Botschaften – Fokus auf fundamentales Unternehmensverständnis, langfristiger Horizont, emotionale Standhaftigkeit – inspirieren eine neue Generation von Investoren. 
            Zudem trägt er mit der Ansiedlung seines Fonds in Miami und seiner Erfolgsgeschichte dazu bei, dass sich Finanzzentren und -gemeinschaften abseits von New York weiterentwickeln.
            Obwohl Dev Kantesaria noch mitten in seiner Karriere steht, lässt sich schon jetzt sein Vermächtnis umreißen: Er hat gezeigt, dass man auch auf unkonventionellem Wege (über die Medizin zur Hochfinanz) zu einem der führenden Investoren werden kann. 
            Seine Story verdeutlicht die Kraft von Leidenschaft und Prinzipientreue. Die Finanzwelt hat in ihm einen Akteur, der nicht nur Kapital verwaltet, sondern auch Ideen und Inspiration liefert. 
            Kantesarias Erfolgsbilanz und Ansatz werden vermutlich noch lange als Beispiel dafür dienen, wie man mit einem konzentrierten, qualitätsbasierten Portfolio nachhaltig Wohlstand aufbauen kann – ein Vermächtnis, das über reine Zahlen weit hinausgeht.    
            </p>
            <br></br>
            <h2 id="Quellen"><strong>Quellen</strong></h2> 
            <table className="performance-table" style={{backgroundColor: "white"}}>
                <thead>
                    <tr>
                        <th style={{width:"30%"}}>Quelle</th>
                        <th s style={{textAlign:"right"}}>Link</th>
                    </tr>
                </thead>
                <tbody>
        <tr >
            <td>
                FinChat.io
            </td>
            <td style={{textAlign:"right"}} ><a target="blank" href="https://finchat.io/blog/dev-kantesaria-the-most-selective-investor/">Dev Kantesaria: The Most Selective Investor?</a></td>
        </tr>
        <tr >
            <td>
                Hedgefundalpha.com
            </td>
            <td style={{textAlign:"right"}} ><a target="blank" href="https://hedgefundalpha.com/interview-with-dev-kantesaria-valley-forge-capital-management/#:~:text=Yeah%2C%20I%27ve%20loved%20equities%20since,public%20equities%20that%20I%27m%20analyzing">Interview Dev Kantesaria</a></td>
        </tr>
        <tr >
            <td>
                Asimplemodel.com
            </td>
            <td style={{textAlign:"right"}} ><a target="blank" href="https://www.asimplemodel.com/bips/bip-feed/sp-global-vfcm#:~:text=What%20I%20particularly%20enjoyed%20about,quality%20business">S&P and Valley Forge CM</a></td>
        </tr>
        <tr >
            <td>
                Bizjournal.com
            </td>
            <td style={{textAlign:"right"}} ><a target="blank" href="https://www.bizjournals.com/southflorida/news/2024/12/04/miami-hedge-fund-valley-forge-dev-kantesaria.html">Hedge fund's assets spike 55%</a></td>
        </tr>
        <tr >
            <td>
                Valleforgecapital.com
            </td>
            <td style={{textAlign:"right"}} ><a target="blank" href="https://www.valleyforgecapital.com/firm#:~:text=Our%20mission">Our Mission</a></td>
        </tr>
        <tr >
            <td>
                Valuesider.com
            </td>
            <td style={{textAlign:"right"}} ><a target="blank" href="https://valuesider.com/guru/dev-kantesaria-valley-forge-capital-management/portfolio#:~:text=What%20stocks%20does%20Dev%20Kantesaria,own">Dev Kantesaria's Portfolio</a></td>
        </tr>
        <tr >
            <td>
                GoWithTheGurus.com
            </td>
            <td style={{textAlign:"right"}} ><a target="blank" href="https://gowiththegurus.com/watchaguru/1697868/Dev%20Kantesaria">Dev Kantesaria</a></td>
        </tr>
</tbody>
            </table>

            <br></br>
            <h2 id="Weiterführende Informationen"><strong>Weiterführende Informationen</strong></h2> 
            <a target="blank" href="https://www.linkedin.com/in/devkantesaria">LinkedIn - Dev Kantesaria</a>
            <br></br>
    <div className="responsive-video">
    Good Investing Talks - Dev Kantesaria
  <iframe
    src="https://www.youtube.com/embed/JCAIHYRNbhw"
    title="Good Investing Talks - Dev Kantesaria"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
</div>
              <p style={{fontStyle:"italic", color:"rgba(0,0,0,0.6)", fontSize:"14px"}}><a href="https://www.youtube.com/watch?v=JCAIHYRNbhw">Good Investing Talks mit Tilman Versch</a>
              </p>

            </div>


        </div>

        </>
    );

}

export default DevKantesaria;
