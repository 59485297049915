import "./css/Post1.css";
import { useState, useEffect } from "react";
import arrow from "../../assets/arrow.svg"
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function Post1() {

      // Tab-Titel mit document.title setzen
      useEffect(() => {
        document.title = "Go with the Gurus | Q4 2024: Welche Aktien kauften und verkauften Buffett, Ackman & Co?";
        window.scrollTo(0, 0); // Scrollt beim Laden der Seite nach oben
      }, []); // Leeres Array sorgt dafür, dass der Effekt nur einmal bei Mount ausgeführt wird




    const holdings1 = [
        { type: "Verkauft", ticker: "SPY", name: "SPDR S&P 500 ETF Trust" },
        { type: "Verkauft", ticker: "VOO", name: "Vanguard S&P 500 ETF" },
        { type: "Neu", ticker: "STZ", name: "Constellation Brands" },
        { type: "Erhöht", ticker: "DPZ", name: "Dominos Pizza" },
    ];
    const holdings2 = [
        { type: "Verkauft", ticker: "AAPL", name: "Apple Inc." },
        { type: "Verkauft", ticker: "MCD", name: "McDonalds" },
        { type: "Neu", ticker: "MEDP", name: "Medpace Holdings"},
        { type: "Neu", ticker: "DOCS", name: "Doximity"},

    ];
    const holdings3 = [
        { type: "Verkauft", ticker: "CMG", name: "Chipotle Mexican Grill" },
        { type: "Verkauft", ticker: "HLT", name: "Hilton Worldwide Holdings" },
        { type: "Neu", ticker: "NKE", name: "Nike"},
        { type: "Neu", ticker: "BN", name: "Brookfield Corporation"},

    ];
    const holdings4 = [
        { type: "Verkauft", ticker: "ILMN", name: "Illumina" },
        { type: "Verkauft", ticker: "CTRI", name: "Centuri Holdings" },
        { type: "Neu", ticker: "UAN", name: "CVR Partners"},
        { type: "Neu", ticker: "IEP", name: "Icahn Enterprises"},

    ];

    const [sortConfig, setSortConfig] = useState({ key: 'performance', direction: 'desc' });

    const performanceData = [
        { guru: "Warren Buffett", link: "/watchaguru/1067983/Warren%20Buffett", performance: 2.68, alpha: -0.26 },
        { guru: "Dodge and Cox", link: "/watchaguru/0200217/Dodge%20&%20Cox",  performance: -2.56, alpha: -5.50 },
        { guru: "Chris Hohn", link: "/watchaguru/1647251/Chris%Hohn",  performance: -3.13, alpha: -6.07 },
        { guru: "Bill Gates", link: "/watchaguru/1166559/Bill%20Gates",  performance: -4.04, alpha: -6.98 },
        { guru: "Chase Coleman", link: "/watchaguru/1167483/Chase%20Coleman",  performance: 8.59, alpha: 5.65 },
        { guru: "Terry Smith", link: "/watchaguru/1569205/Terry%20Smith",  performance: -0.28, alpha: -3.22 },
        { guru: "William van Mueffling", link: "/watchaguru/1279936/William%20von%20Mueffling",  performance: 0.68, alpha: -2.26 },
        { guru: "Bill Ackman", link: "/watchaguru/1336528/Bill%20Ackman",  performance: 0.05, alpha: -2.89 },
        { guru: "Chuck Akre", link: "/watchaguru/1112520/Chuck%20Akre",  performance: 1.02, alpha: -1.92 },
        { guru: "Carl Icahn", link: "/watchaguru/0921669/Carl%20Icahn",  performance: -25.29, alpha: -28.23 },
        { guru: "Dev kantesaria", link: "/watchaguru/1697868/Dev%20Kantesaria",  performance: 1.68, alpha: -1.26 },
        { guru: "Li Lu", link: "/watchaguru/1709323/Li%20Lu",  performance: 9.66, alpha: 6.72 },
        { guru: "Guy Spier", link: "/watchaguru/1953324/Guy%20Spier",  performance: 0.70, alpha: -2.24 },
        { guru: "Charlie Munger", link: "/watchaguru/0783412/Charlie%20Munger",  performance: 12.98, alpha: 10.04 },
        { guru: "Michael Burry", link: "/watchaguru/1649339/Michael%20Burry",  performance: -10.56, alpha: -13.50 }
    ];

    const sortedData = [...performanceData].sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        }
        return sortConfig.direction === 'asc'
            ? String(aValue).localeCompare(String(bValue))
            : String(bValue).localeCompare(String(aValue));
    });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') direction = 'desc';
        setSortConfig({ key, direction });
    };

    return (
        
        <>

            <Helmet>
                <title>Go with the Gurus | Q4 2024: Welche Aktien kauften und verkauften Buffett, Ackman & Co?</title>
                <meta name="description" content="Entdecke die neuesten Käufe und Verkäufe der größten Investoren wie Warren Buffett, Bill Ackman und Terry Smith im Q4 2024. Erfahre, welche Aktien sie bevorzugen und wie sich ihre Portfolios verändern." />
            </Helmet>
        



        

        <div className="blog-post-header-image">
            <div className="landingpage-iphone-text-container">
            <h1 style={{ margin:"10px"}}>Was passierte in Q4 '24 bei den Gurus?</h1>
            <h5 style={{fontWeight:"300", margin:"0", fontSize:"16px"}}>Buffett, Smith, Ackman & Co</h5>
            <h5 style={{fontWeight:"300", margin:"5px", color:"rgba(255, 255, 255, 0.5)"}}>24. Februar 2025</h5>
            </div>
        </div>


        <div className="post-container-header-background">
        <div className="post-container-header">

<h2 style={{margin:"0", padding:"20px 0"}}>Inhalt</h2>
<p><a className="index-list" href="#buffett">Warren Buffett</a></p>
        <p><a className="index-list" href="#smith">Terry Smith</a></p>
        <p><a className="index-list" href="#ackman">Bill Ackman</a></p>
        <p><a className="index-list" href="#icahn">Carl Icahn</a></p>
        <p><a className="index-list" href="#performance">Performance Übersicht</a></p>
</div>
</div>




        <div className="post-container">



            
            <div className="post-content">
            <h2 id="buffett"><strong>Warren Buffett | Berkshire Hathaway</strong></h2>
            <p>
            Warren Buffett hat im vierten Quartal 2024 gezielt sein Portfolio angepasst. Seine größte Position bleibt Apple (AAPL). Der Aktienkurs stieg um 7,5 % von 233,00 USD auf 250,42 USD, wodurch der Gesamtwert seiner Beteiligung auf 75,1 Mrd. USD anwuchs. Auch American Express (AXP) profitierte von einer positiven Kursentwicklung mit einem Anstieg um 9,4 %, was den Wert seiner Position auf 45 Mrd. USD erhöhte.
            <br></br>
            <br></br>
            Auf der anderen Seite stand Coca-Cola (KO) unter Druck. Der Kurs fiel um 13,4 % von 71,86 USD auf 62,26 USD, was den Wert seiner Beteiligung um knapp 4 Mrd. USD schrumpfen ließ. Auch Bank of America (BAC) musste mit einem Rückgang um 10,8 % auf 43,95 USD Verluste hinnehmen, wodurch seine Beteiligung um 1,75 Mrd. USD sank.
</p>
                <h3>Verkäufe</h3>
                <p>
                Die Verkäufe der SPDR S&P 500 ETF (SPY) und Vanguard S&P 500 ETF (VOO) sind eher symbolisch. Diese Positionen waren vergleichsweise klein, und Buffett setzt ohnehin auf Einzelwerte. Vielleicht denkt Buffett, dass der Markt etwas heißgelaufen ist.

Deutlich interessanter sind die Reduzierungen bei Bankaktien. Citigroup wurde um 73,5 % abgebaut, Bank of America um 14,72 %. Das zeigt eine klare Zurückhaltung gegenüber Finanzwerten. Steigende Zinsen, wirtschaftliche Unsicherheiten oder eine strategische Neuausrichtung dürften dabei eine Rolle spielen.
                    </p>
                <ul>
                    {holdings1.filter(h => h.type === "Verkauft" || h.type === "Reduziert").map((holding, index) => (
                                     
                        <li key={holding.ticker}>
                           <Link style={{display:"flex", gap:"20px", alignItems:"center"}} to={`/stockpage/${holding.ticker}`}>
                            <img 
                                src={`https://www.gowiththegurus.de/images/logos/${holding.ticker}.svg`} 
                                onError={(e) => e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg'}
                                alt={`${holding.ticker} Logo`} 
                                className="holding-logo-blog" 
                            />
                            <strong>{holding.name} ({holding.ticker})</strong>
                        </Link>
                        </li>
                    ))}
                </ul>
                
                <h2>Käufe</h2>
                <p>
                Constellation Brands (STZ) ist neu im Portfolio – mit über 1,24 Milliarden USD eine beachtliche Investition. Das passt zu Buffetts Strategie, auf starke Marken im Konsumbereich zu setzen. 
                Auch bestehende Positionen wurden weiter ausgebaut: <br></br>
                Domino’s Pizza (DPZ) +86,49 % | Ein weiteres Zeichen für seinen Fokus auf stabile Konsumgüterunternehmen.<br></br>
                Occidental Petroleum (OXY) +3,49 % | Zeigt, dass er seiner langfristigen Strategie im Energiesektor treu bleibt.
                </p>
                                <ul>
                    {holdings1.filter(h => h.type === "Neu" || h.type === "Erhöht").map((holding, index) => (
                        <li key={holding.ticker}>
                           <Link style={{display:"flex", gap:"20px", alignItems:"center"}} to={`/stockpage/${holding.ticker}`}>
                            <img 
                                src={`https://www.gowiththegurus.de/images/logos/${holding.ticker}.svg`} 
                                onError={(e) => e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg'}
                                alt={`${holding.ticker} Logo`} 
                                className="holding-logo-blog" 
                            />
                            <strong>{holding.name} ({holding.ticker})</strong> 
                    </Link>
                        </li>
                    ))}
                </ul>
                
            </div>
        </div>

        <div className="post-container">
            <div className="post-content">
            <h2 id="smith" ><strong>Terry Smith | Fundsmith</strong></h2>
            <p>
            Terry Smith hat sein Portfolio im vierten Quartal 2024 gezielt umstrukturiert. Besonders positiv entwickelten sich seine Beteiligungen an Alphabet (GOOGL), das um 14,1 % von 165,85 USD auf 189,30 USD zulegte, sowie Visa (V), das einen Anstieg von 15 % auf 316,04 USD verzeichnete. Auch Meta (META) konnte um 2,3 % steigen, während Microsoft (MSFT) mit einem Rückgang von 2,0 % leicht nachgab.
<br></br>
<br></br>
Im Gesundheitssektor nahm er größere Anpassungen vor. Während er seine Position in Stryker (SYK) leicht reduzierte, blieb der Kurs mit -0,3 % nahezu unverändert. IDEXX Laboratories (IDXX) hingegen verlor deutlich an Wert und fiel um 18,2 % von 505,22 USD auf 413,44 USD. Auch Mettler-Toledo (MTD) musste mit einem Rückgang von 18,4 % auf 1.223,68 USD Verluste hinnehmen.
            
            </p>

                <h2>Verkäufe</h2>
                <p>
                Auf der Verkaufsseite hat Smith einige große Namen reduziert oder ganz abgestoßen. Apple (AAPL), McDonald's (MCD) und McCormick (MKC) wurden vollständig verkauft. Auch bei Microsoft (MSFT) (-10,34 %), Visa (V) (-16,66 %) und Meta (META) (-6,36 %) hat er Anteile abgebaut. Besonders auffällig ist der Rückgang bei Finanzwerten wie Visa (-16,66 %) und Automatic Data Processing (ADP) (-10,02 %).                    </p>
                <ul>
                    {holdings2.filter(h => h.type === "Verkauft" || h.type === "Reduziert").map((holding, index) => (
                        <li key={holding.ticker}>
                           <Link style={{display:"flex", gap:"20px", alignItems:"center"}} to={`/stockpage/${holding.ticker}`}>
                            <img 
                                src={`https://www.gowiththegurus.de/images/logos/${holding.ticker}.svg`} 
                                onError={(e) => e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg'}
                                alt={`${holding.ticker} Logo`} 
                                className="holding-logo-blog" 
                            />
                            <strong>{holding.name} ({holding.ticker})</strong>
                        </Link>
                        </li>
                    ))}
                </ul>
                
                <h2>Käufe</h2>
                <p>
                Neu im Portfolio sind Medpace Holdings (MEDP) und Doximity (DOCS) – zwei Unternehmen aus dem Healthcare-Bereich. Beide Positionen wurden von Null auf über 40 Millionen USD ausgebaut, was auf ein verstärktes Interesse an diesem Sektor hindeutet.
                </p>
                                <ul>
                    {holdings2.filter(h => h.type === "Neu" || h.type === "Erhöht").map((holding, index) => (
                        <li key={holding.ticker}>
                           <Link style={{display:"flex", gap:"20px", alignItems:"center"}} to={`/stockpage/${holding.ticker}`}>
                            <img 
                                src={`https://www.gowiththegurus.de/images/logos/${holding.ticker}.svg`} 
                                onError={(e) => e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg'}
                                alt={`${holding.ticker} Logo`} 
                                className="holding-logo-blog" 
                            />
                            <strong>{holding.name} ({holding.ticker})</strong> 
                        </Link>
                        </li>
                    ))}
                </ul>
                
            </div>
        </div>

        <div className="post-container">
            <div className="post-content">
            <h2 id="ackman" ><strong>Bill Ackman | Pershing Square Capital</strong></h2>
            <p>
            Bill Ackmans Portfolio zeigte im vierten Quartal 2024 deutliche Veränderungen bei einigen seiner größten Positionen. Besonders stark entwickelten sich seine Beteiligungen an Alphabet (GOOG, GOOGL), die jeweils um rund 14 % zulegten und damit die größten Gewinner waren. Auch die Brookfield Corporation konnte um 8,1 % zulegen, während Hilton Worldwide Holdings mit einem Plus von 7,2 % ebenfalls eine solide Performance zeigte.
                    <br></br>
                    <br></br>
Auf der anderen Seite mussten einige seiner Investments deutliche Rückgänge verzeichnen. Nike verlor 14,4 %, was möglicherweise auf eine schwächere Konsumstimmung oder unternehmensspezifische Herausforderungen hindeutet. Noch stärker traf es Canadian Pacific Kansas City, deren Aktienkurs um 15,4 % nachgab, womöglich aufgrund von wirtschaftlichen Unsicherheiten oder einer schwächeren Nachfrage im Transportsektor. Auch Restaurant Brands International fiel mit einem Kursverlust von 9,6 %, was ein Zeichen für Herausforderungen im Gastronomiesektor sein könnte.
                            </p>

                <h2>Verkäufe</h2>
                <p>
                Ackman reduzierte seine Beteiligung an Chipotle Mexican Grill (CMG) um 14,44 % (-239,8 Mio. USD) und nahm damit Gewinne mit oder sah begrenztes weiteres Wachstumspotenzial. Bei Hilton Worldwide Holdings (HLT) baute er seine Position um 26,18 % (-444,8 Mio. USD) ab, was eine Anpassung seiner Einschätzung des Hotel- und Tourismussektors widerspiegelt – sei es aufgrund wirtschaftlicher Entwicklungen oder strategischer Umschichtungen.
                    </p>
                <ul>
                    {holdings3.filter(h => h.type === "Verkauft" || h.type === "Reduziert").map((holding, index) => (
                        <li key={holding.ticker}>
                           <Link style={{display:"flex", gap:"20px", alignItems:"center"}} to={`/stockpage/${holding.ticker}`}>
                            <img 
                                src={`https://www.gowiththegurus.de/images/logos/${holding.ticker}.svg`} 
                                onError={(e) => e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg'}
                                alt={`${holding.ticker} Logo`} 
                                className="holding-logo-blog" 
                            />
                            <strong>{holding.name} ({holding.ticker})</strong>
                        </Link>
                        </li>
                    ))}
                </ul>
                
                <h2>Käufe</h2>
                <p>
                Nike (NKE) wurde um 15,29 % erhöht (+188,3 Mio. USD). Ackman könnte auf eine Erholung des Sportartikelmarkts setzen.
Brookfield Corporation (BN) wurde um 6,59 % aufgestockt (+123,9 Mio. USD). Dies könnte ein Signal für langfristiges Vertrauen in den Finanzsektor sein.
Seaport Entertainment Group (SEG) wurde um 19,92 % erhöht (+23,3 Mio. USD). Dies könnte auf eine Spekulation auf steigende Freizeit- und Unterhaltungsausgaben hindeuten.
                </p>
                                <ul>
                    {holdings3.filter(h => h.type === "Neu" || h.type === "Erhöht").map((holding, index) => (
                       <li key={holding.ticker}>
                    <Link style={{display:"flex", gap:"20px", alignItems:"center"}} to={`/stockpage/${holding.ticker}`}>
                            <img 
                                src={`https://www.gowiththegurus.de/images/logos/${holding.ticker}.svg`} 
                                onError={(e) => e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg'}
                                alt={`${holding.ticker} Logo`} 
                                className="holding-logo-blog" 
                            />
                            <strong>{holding.name} ({holding.ticker})</strong> 
                        </Link>
                        </li>
                    ))}
                </ul>
                            </div>
        </div>

        <div className="post-container">
            <div className="post-content">
            <h2 id="icahn" ><strong>Carl Icahn | Icahn Capital Management</strong></h2>
            <p>
            Carl Icahn hat sein Portfolio im vierten Quartal 2024 gezielt angepasst, wobei sich deutliche Veränderungen in der Kursentwicklung seiner größten Positionen zeigten. Icahn Enterprises (IEP), seine mit Abstand größte Beteiligung, verlor 35,8 % an Wert und fiel von 13,52 USD auf 8,67 USD pro Aktie. Dies hatte einen erheblichen Einfluss auf sein Gesamtvermögen. Auch International Flavors & Fragrances (IFF) (-19,5 %) und Caesars Entertainment (CZR) (-19,9 %) gehörten zu den größten Verlierern, was auf eine schwächere Marktbewertung in diesen Sektoren hindeutet.

Auf der Gewinnerseite entwickelte sich CVR Partners (UAN) mit einem Kursplus von 13,2 % positiv und notierte zuletzt bei 75,96 USD. JetBlue Airways (JBLU) konnte um 19,8 % zulegen, während Dana (DAN) um 9,5 % stieg.
                            </p>

                <h2>Verkäufe</h2>
                <p>
                Auf der Verkaufsseite trennte er sich von einem bedeutenden Teil seiner Positionen in Southwest Gas Holdings (SWX), Centuri Holdings (CTRI) und Illumina (ILMN). Die größte Reduzierung betraf SWX, wo er 1,39 Millionen Aktien verkaufte und damit 102,5 Mio. USD realisierte. Dies könnte eine Reaktion auf den leichten Kursrückgang (-4,1 %) sein oder darauf hindeuten, dass er langfristig weniger Potenzial in dem Versorgungsunternehmen sieht.
                </p>
                <ul>
                    {holdings4.filter(h => h.type === "Verkauft" || h.type === "Reduziert").map((holding, index) => (
                        <li key={holding.ticker}>
                           <Link style={{display:"flex", gap:"20px", alignItems:"center"}} to={`/stockpage/${holding.ticker}`}>
                            <img 
                                src={`https://www.gowiththegurus.de/images/logos/${holding.ticker}.svg`} 
                                onError={(e) => e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg'}
                                alt={`${holding.ticker} Logo`} 
                                className="holding-logo-blog" 
                            />
                            <strong>{holding.name} ({holding.ticker})</strong>
                     </Link>
                        </li>
                       
                    ))}
                </ul>
                
                <h2>Käufe</h2>

                <p>
                    Zu den größten Zukäufen gehörten Icahn Enterprises (IEP) und CVR Partners (UAN). Seine Beteiligung an IEP wurde um 4,06 % aufgestockt, was einem zusätzlichen Investment von 152,4 Mio. USD entspricht. Trotz des starken Kursrückgangs von 35,8 % setzt Icahn offenbar weiterhin auf sein eigenes Unternehmen. Auch bei CVR Partners erhöhte er seinen Anteil um 4,48 % und investierte weitere 13,2 Mio. USD, während sich der Aktienkurs um 13,2 % verteuerte.
                    </p>
                                <ul>
                    {holdings4.filter(h => h.type === "Neu" || h.type === "Erhöht").map((holding, index) => (
                        <li key={holding.ticker}>
                           <Link style={{display:"flex", gap:"20px", alignItems:"center"}} to={`/stockpage/${holding.ticker}`}>
                            <img 
                                src={`https://www.gowiththegurus.de/images/logos/${holding.ticker}.svg`} 
                                onError={(e) => e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg'}
                                alt={`${holding.ticker} Logo`} 
                                className="holding-logo-blog" 
                            />
                            <strong>{holding.name} ({holding.ticker})</strong> 
                        </Link>
                        </li>
                    ))}
                </ul>
                            </div>
        </div>

        <div className="post-container">
            <div className="post-content">
            <h2 id="performance" ><strong>Performance Q4 2024</strong></h2>
            <p>
             Munger führte die Liste mit einem beeindruckenden Zuwachs von 12,98 % an, gefolgt von Li Lu, der mit 9,66 % ebenfalls stark abschnitt. Chase Coleman sicherte sich mit 8,59 % den dritten Platz unter den Top-Performern.
<br></br>
<br></br>
Am anderen Ende der Skala musste Michael Burry mit -10,56 % bereits deutliche Verluste hinnehmen, doch Carl Icahn erwischte es mit -25,29 % am härtesten. Seine relative Performance von -28,23 % setzte ihn klar ans Ende der Liste. Bill Gates lag mit -4,04 % ebenfalls im negativen Bereich und komplettierte die Bottom 3.
</p>
            <table className="performance-table" style={{backgroundColor: "white"}}>
                <thead>
                    <tr>
                        <th style={{width:"30%"}} onClick={() => handleSort('guru')}>Guru {sortConfig.key === 'guru' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
                        <th  style={{minWidth:"100px"}} onClick={() => handleSort('performance')}>Return {sortConfig.key === 'performance' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
                        <th onClick={() => handleSort('alpha')}>Alpha {sortConfig.key === 'alpha' && (sortConfig.direction === 'asc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
                    </tr>
                </thead>
                <tbody>
    {sortedData.map((row, index) => (
        <tr key={`${row.guru}-${index}`}>
            <td>
                <Link to={row.link}>{row.guru}</Link>
            </td>
            <td style={{color: row.performance > 0 ? "green" : "red"}}>{row.performance.toString().replace(".", ",")} %</td>
            <td style={{color: row.alpha > 0 ? "green" : "red"}}>{row.alpha.toString().replace(".", ",")} %</td>
        </tr>
    ))}
</tbody>
            </table>


        </div>
        </div>
        </>
    );

}

export default Post1;
