import React from "react";
import useStockData from "../backend/useStockData";
import './GuruHoldings.css'; // Importiere die CSS-Datei

function GuruHoldings({ selectedTicker, selectedTickerName }) {
  const { stockData } = useStockData();


  // Gruppiere die Daten nach Quartal
  const groupedByQuarter = stockData
    .filter(d => d.TICKER === selectedTicker)
    .reduce((acc, d) => {
      if (!acc[d.YEAR_QUARTER]) {
        acc[d.YEAR_QUARTER] = [];
      }
      acc[d.YEAR_QUARTER].push(d);
      return acc;
    }, {});

  // Funktion zum Formatieren der Werte
  const formatValue = (value) => {
    return new Intl.NumberFormat('de-DE').format(value);
  };

  const formatValueUSD = (value) => {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(value);
  };

  return (
    <div className="guru-holdings-container">
      <h1 style={{color:"white", width:"95%", maxWidth:"1400px", margin:"auto", paddingTop:"20px"}}>{selectedTickerName}</h1>
      {Object.keys(groupedByQuarter).sort().reverse().map((quarter, index) => (
        <div key={index} className="quarter-section" style={{color:"white"}}>
          <h3>{quarter.replace("_"," ")}</h3>
          <table className="guru-holdings-table">
            <thead>
              <tr>
                <th>Gurus</th>
                <th>Wert</th>
                <th>Anzahl Aktien</th>
                <th>Preis</th>
              </tr>
            </thead>
            <tbody>
              {groupedByQuarter[quarter].map((holding, idx) => (
                <tr key={idx}>
                  <td>{holding.GURU_NAME}</td>
                  <td>{formatValueUSD(holding.value)}</td>
                  <td>{formatValue(holding.sshPrnamt)}</td>
                  <td>{formatValueUSD(holding.value / holding.sshPrnamt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}

export default GuruHoldings;