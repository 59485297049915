import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import useReturnData from "../backend/useReturnData"; // Importieren der Hook
import "./GuruCurrentPortfolioTable.css"; // CSS übernehmen

const ReturnChart = () => {
  const { loading, error, returnData } = useReturnData(); // Daten aus der Hook
  const [chartOptions, setChartOptions] = useState({});
  const [hoveredContent, setHoveredContent] = useState({
    quarter: "Lade Daten...",
    sp500Index: "Lade Daten...",
    portfolioIndex: "Lade Daten...",
  }); // Standardmäßige Hover-Daten

  const formatQuarter = (quarter) => {
    const [year, q] = quarter.split("_");
    const shortYear = year.slice(-2); // Nur die letzten zwei Ziffern des Jahres
    return `${q} ${shortYear}`; // Format wie "Q3 24"
  };

  const formatPercentage = (value) =>
    `${parseFloat(value).toLocaleString("de-DE", { minimumFractionDigits: 2 })}%`;

    
    useEffect(() => {
      if (loading || error || returnData.length === 0) return;
    
      // Index-Berechnung
      let sp500Index = 100;
      let portfolioIndex = 100;
    
      const xAxisData = [];
      const sp500Data = [];
      const portfolioData = [];
    
      returnData.forEach((item) => {
        const quarter = formatQuarter(item.YEAR_QUARTER);
        xAxisData.push(quarter);
    
        // Parsing und Überprüfung
        const sp500Return = item.spx_return
          ? parseFloat(item.spx_return.replace(",", ".")) / 100
          : 0; // Standardwert 0, wenn spx_return fehlt
        const portfolioReturn = item.return
          ? parseFloat(item.return.replace(",", ".")) / 100
          : 0; // Standardwert 0, wenn return fehlt
    
        // Berechnung des neuen Index
        sp500Index *= 1 + sp500Return;
        portfolioIndex *= 1 + portfolioReturn;
    
        // Werte speichern
        sp500Data.push(sp500Index.toFixed(2));
        portfolioData.push(portfolioIndex.toFixed(2));
      });
    
      // Standardmäßig die letzten Werte im Hover anzeigen
      const lastIndex = xAxisData.length - 1;
      setHoveredContent({
        quarter: xAxisData[lastIndex],
        sp500Index: formatPercentage(sp500Data[lastIndex]),
        portfolioIndex: formatPercentage(portfolioData[lastIndex]),
      });
    
      const isMobile = window.innerWidth < 1000;
      const gridRight = isMobile ? "4%" : "2%";
    
      // ECharts Optionen
      setChartOptions({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "rgba(0, 0, 0, 0.5)",
              width: 2,
              type: "solid",
            },
          },
          formatter: (params) => {
            if (!params || params.length < 2) {
              return ""; // Keine Anzeige, wenn keine Daten vorhanden sind
            }
    
            const [sp500, portfolio] = params;
    
            setHoveredContent({
              quarter: sp500?.axisValue || "Unbekannt",
              sp500Index: sp500?.data ? formatPercentage(sp500.data) : "0",
              portfolioIndex: portfolio?.data ? formatPercentage(portfolio.data) : "0",
            });
    
            return ""; // Keine Standardanzeige im Tooltip
          },
        },
        legend: {
          data: [
            { name: "S&P 500", icon: "circle", itemStyle: { color: "rgba(0,0,0,0.4)" } },
            { name: "Portfolio", icon: "circle", itemStyle: { color: "rgb(0, 200, 150)" } },
          ],
          top: "5%",
          right: "1.6%",
          orient: "horizontal",
          textStyle: {
            color: "#444",
            fontSize: 12,
          },
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 10,
        },
        grid: {
          left: "1%",
          right: gridRight,
          top: "10%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xAxisData,
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
          axisLabel: {
            margin: 30,
            color: "#444",
            fontSize: 12,
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            margin: 30,
            color: "#444",
            fontSize: 12,
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "S&P 500",
            type: "line",
            data: sp500Data,
            showSymbol: false,
            symbol: "none",
            smooth: true,
            lineStyle: {
              width: 2,
              color: "rgba(0, 0, 0, 0.2)",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: "rgba(0, 0, 0, 0.4)" },
                  { offset: 1, color: "rgba(255, 255, 255, 0)" },
                ],
              },
            },
          },
          {
            name: "Portfolio",
            type: "line",
            data: portfolioData,
            showSymbol: false,
            symbol: "none",
            smooth: true,
            lineStyle: {
              width: 2,
              color: "rgb(0, 200, 150)",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: "rgb(0, 200, 150)" },
                  { offset: 1, color: "rgba(255, 255, 255, 0)" },
                ],
              },
            },
          },
        ],
      });
    }, [loading, error, returnData]);
    
    

  // Lade- und Fehlerzustände behandeln
  if (loading) return <div>Lade Daten...</div>;
  if (error) return <div>Fehler: {error.message}</div>;

  return (
    <div className="portfolio-chart">
      {/* Hovered Content anzeigen */}
      {hoveredContent && (
        <div className="hovered-container">
          <div className="hovered-left">
            <strong>{hoveredContent.quarter}</strong>
          </div>
          <div className="hovered-right">
            <>S&P 500 <strong>{hoveredContent.sp500Index.replace("%", "")}</strong><br></br> Portfolio <strong>{hoveredContent.portfolioIndex.replace("%", "")}</strong></>
          </div>
        </div>
      )}

      {/* Chart */}
      <ReactECharts option={chartOptions} style={{ height: 400 }} />
    </div>
  );
};

export default ReturnChart;
