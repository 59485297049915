import React, { useState, useEffect } from "react";
import useStockData from "../backend/useStockData";
import ReactECharts from "echarts-for-react";
import './StockChart.css'; // Importiere die CSS-Datei

function StockChart() {
  const { stockData } = useStockData();
  const [chartData, setChartData] = useState([]);
  const [markPoints, setMarkPoints] = useState([]);
  const [hoveredData, setHoveredData] = useState({
    quarter: "Lade Daten...",
    value: "Lade Daten...",
  });


  useEffect(() => {
    if (stockData.length === 0) return;

    const groupedByQuarter = stockData.reduce((acc, d) => {
      acc[d.YEAR_QUARTER] = acc[d.YEAR_QUARTER] || { value: 0, shares: 0 };
      acc[d.YEAR_QUARTER].value += Number(d.value);
      acc[d.YEAR_QUARTER].shares += Number(d.sshPrnamt);
      return acc;
    }, {});

    const historyData = Object.entries(groupedByQuarter)
      .sort(([q1], [q2]) => q1.localeCompare(q2))
      .map(([quarter, data]) => ({
        quarter: quarter.replace("_", " "),
        value: data.value,
        shares: data.shares,
        price: data.value / data.shares,
      }));

    setChartData(historyData);
  
    // MarkPoints für Veränderungen in den Shares
    const changes = [];
    for (let i = 1; i < historyData.length; i++) {
      const prev = historyData[i - 1];
      const current = historyData[i];
  
      if (prev.shares !== current.shares) {
        changes.push({
          coord: [current.quarter, current.price],
          value: "",
          symbol: "pin",
          symbolSize: 20,
          itemStyle: {
            color: current.shares > prev.shares ? "rgba(0, 190, 60, 0.8)" : "rgba(255, 0, 60, 0.8)",
          },
          label: { show: false },
        });
      }
    }
  
    setMarkPoints(changes);
  
    // Setze initial hoveredData
    if (historyData.length > 0) {
      const lastIndex = historyData.length - 1;
      setHoveredData({
        quarter: historyData[lastIndex].quarter,
        value: `${new Intl.NumberFormat('de-DE').format(historyData[lastIndex].value.toFixed(0))} $`,
        price: `${new Intl.NumberFormat('de-DE').format(historyData[lastIndex].price.toFixed(2))} $`,
      });
    }
  }, [stockData]);
  

  const chartOptions = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
        lineStyle: {
          color: "rgba(0, 0, 0, 0.5)",
          width: 2,
          type: "solid",
        },
      },
      formatter: (params) => {
        const index = params[0].dataIndex;
        const price = `${new Intl.NumberFormat('de-DE').format(chartData[index].price.toFixed(2))} $`;
        const quarter = chartData[index].quarter;
        const value = `${new Intl.NumberFormat('de-DE').format(chartData[index].value.toFixed(0))} $`;
        setHoveredData({ quarter, value, price });
        return ""; // Tooltip-Inhalt leer lassen, da wir die Werte separat anzeigen
      },
    },
    grid: {
      left: 10,
      right: 35,
      top: 30,
      bottom: 20,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: chartData.map(d => d.quarter),
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        margin: 30,
        color: "#444",
        fontSize: 12,
      },
      axisTick: {
        show: false,
        alignWithLabel: true,
      },
    },
    yAxis:[{
      type: "value",
      axisLabel: {
        margin: 30,
        color: "#444",
        fontSize: 12,
        formatter: (value) => `${new Intl.NumberFormat('de-DE').format(value / 1000000000)} Mrd. $`,
      },
      axisTick: {
        show: false,
        alignWithLabel: true,
      },
      splitLine: {
        show: false,
      },
      
    },
    {
      type: "value",
      axisLabel: {
        margin: 30,
        color: "#444",
        fontSize: 12,
        formatter: (value) => `${new Intl.NumberFormat('de-DE').format(value)} $`,
      },
      axisTick: {
        show: false,
        alignWithLabel: true,
      },
      splitLine: {
        show: false,
      },
    },
  ],
    series: [
      {
        data: chartData.map(d => d.value),
        type: "line",
        showSymbol: false,
        smooth: true,
        lineStyle: {
          width: 2,
          color: "rgb(100, 150, 200)",
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: "rgb(70, 150, 230)" },
              { offset: 1, color: "rgba(255, 255, 255, 0)" },
            ],
          },
        },

      },
      {
        name: "Aktienkurs",
        data: chartData.map(d => d.price),
        type: "line",
        yAxisIndex: 1,
        showSymbol: false,
        smooth: true,
        lineStyle: {
          width: 2,
          color: "rgb(200, 100, 100)",
        },
        markPoint: {
          data: markPoints,
        },
      },
    ],
  };

  // Anpassungen für mobile Geräte
  const isMobile = window.innerWidth <= 1000;
  if (isMobile) {
    chartOptions.grid = {
      left: 5,
      right: 5,
      top: 20,
      bottom: 10,
      containLabel: true,
    };
    chartOptions.xAxis.axisLabel.fontSize = 12;
    chartOptions.yAxis.forEach(axis => {
      axis.axisLabel.fontSize = 10;
      axis.axisLabel.margin = 20;
    });
  }

  return (
    <>
      {/* <p style={{ width: "95%", margin: "20px auto", maxWidth: "1400px" }}>
        Anteil von {selectedTicker} am aktuellen Portfolio (Q4 2024): <strong>{portfolioShare}%</strong>
      </p> */}
      <div className="stock-chart-container">
        <div className="hovered-container">
          <strong>{hoveredData.quarter} </strong>
          <strong>{hoveredData.value} | {hoveredData.price}</strong>
        </div>
        <ReactECharts option={chartOptions}           style={{ height: 400, width: "100%" }}/>
      </div>
    </>
  );
}

export default StockChart;