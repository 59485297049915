import { useState, useEffect } from 'react';

const useMainData = () => {
  const url = "https://www.gowiththegurus.de/data/data_summary.json";
  const [guruData, setGuruData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetch(url);
  
        if (!response.ok) {
          throw new Error(`HTTP-Fehler! Status: ${response.status}`);
        }
  
        const data = await response.json();
  
        setGuruData(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
  
    loadData();
  }, []); // `url` kann entfernt werden, da es konstant ist.
  


  return { guruData, loading, error };
};

export default useMainData;
