import "./LandingPage.css";
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import arrowRed from '../assets/arrow_red.svg';
import arrowOrange from '../assets/arrow_orange.svg';
import Newsletter from "./Newsletter";
import { Helmet } from 'react-helmet-async'; // Neuer Import
import LastIn from "../GuruPage/LastIn";

function LandingPage() {

  console.log("Looking for some Alpha?")


    useEffect(() => {
      document.title = "Go With The Gurus | Tracke die Portfolios der Super Investoren";
      window.scrollTo(0, 0); 
    }); 

  return (
    <div className="landing-page-container">

          <Helmet>
              <title>Go With The Gurus | Tracke die Portfolios der Super Investoren</title>
              <meta name="description" content="Verfolge die Portfolios führender Investoren. Entdecke die Strategien von Gurus wie Warren Buffett, Bill Gates und mehr!" />
              <meta name="keywords" content="Portfolio, Gurus, 13F Filings, Super Investoren, Investments, Aktien, Börse, Go With The Gurus" />
              <meta property="og:title" content="Go With The Gurus | Tracke die Portfolios der Super Investoren" />
              <meta property="og:description" content="Verfolge die Portfolios führender Investoren. Entdecke die Strategien von Gurus wie Warren Buffett, Bill Gates und mehr!" />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://gowiththegurus.com/" />
              <link rel="canonical" href="https://gowiththegurus.com/" />
          </Helmet>



      <div className="landing-page-first-row-container">
        <div className="section-one">
          <div className="landingpage-iphone">
            <div className="landingpage-iphone-text-container">
            <h1 style={{ margin:"10px"}}>Go with the Gurus</h1>
            <h5 style={{fontWeight:"300", margin:"0"}}>Tracke die Portfolios bekannter Investoren</h5>
            </div>
            </div>

        </div>

        <div className='just-in-section'>
        <div className="headline-just-in">Just in</div>
        <div className="subline-just-in-one">
            Die neuesten Updates
          </div>
        <LastIn/>
        </div>
        <div className="blog-content">
        <div className='just-in-section'>
        <div className="headline-just-in"  style={{color:"rgb(50, 240, 170)"}}>Blog</div>
        <div className="subline-just-in-one">
            Die neuesten Blogbeiträge
          </div>
          <br></br>
          <br></br>
          <div className="subline-three-one">

          <Link to={`/blog/Dev%20Kantesaria%20–%20Vom%20Harvard-Mediziner%20zum%20Hedgefonds-Manager/`} className="button-text" style={{color:"white"}}>
            <div className="blog-title-text">Dev Kantesaria - Vom Harvard-Mediziner zum Hedgefonds-Manager</div>
            <div className="blog-date-text"> 22.03.2025</div>
            </Link>


            <br></br>
            <Link to={`/blog/13F-filings-aktueller-Forschungsstand`} className="button-text" style={{color:"white"}}>
            <div className="blog-title-text">13F Filings. Was sagt die Wissenschaft? </div>
            <div className="blog-date-text">01.03.2025</div>
            </Link>

            <br></br>
            <Link to={`/blog/was-kaufen-die-gurus-q4-2024`} className="button-text" style={{color:"white"}}>
            <div className="blog-title-text">Was ist im vierten Quartal 2024 passiert? </div>
            <div className="blog-date-text">07.02.2025</div>
            </Link>
            <br></br>
            <br></br>
          </div>

        </div>
        </div>

        <div className="section-one">
          <div className="headline-one">Gurus</div>
          <div className="subline-one">
            Entdecke alle Guru-Investoren und verfolge, wie sie investieren.
          </div>
          <div className="subline-one-one">
          Analysiere die Strategien und Portfolios herausragender Investoren wie Warren Buffett, Bill Gates oder Bill Ackman, um fundierte Einblicke in bewährte Investmentansätze zu gewinnen.<br /><br />
            <Link to={`/gurus`} className="button-text">
              Zur Guru-Liste <img src={arrowRed} className="sort-arrow-right" alt="Pfeil" />
            </Link>
          </div>

        </div>

        <div className="section-two">
          <div className="landingpage-iphone-2"></div>
          <div className="headline-two">Portfolios</div>
          <div className="subline-two">
            Entdecke die Portfolios der Gurus.
          </div>
          <div className="subline-two-one">
          Gewinne fundierte Einblicke in die Strategien der Meisterinvestoren und analysiere die Struktur ihrer Portfolios. Erfahre Details zur aktuellen Zusammensetzung, den Gewichtungen einzelner Positionen, historischen Veränderungen, sektoralen Verteilungen sowie zu den jüngsten Transaktionen. <br /><br />
            <Link to={`/watchaguru/1067983/Warren%20Buffett`} className="button-text">
              Warren Buffets Porftolio <img src={arrowRed} className="sort-arrow-right" alt="Pfeil" />
            </Link>
          </div>
        </div>

        <div className="section-three">
          <div className="headline-three">Grand Portfolio</div>
          <div className="grand-portfolio-logos-container">
            <div className="logo-glass-container"><img className="grand-portfolio-logo" src="https://www.gowiththegurus.de/images/logos/AAPL_ROUND_WHITE.svg" alt="Logo AAPL" /></div>
            <div className="logo-glass-container"><img className="grand-portfolio-logo" src="https://www.gowiththegurus.de/images/logos/AXP_ROUND_WHITE.svg" alt="Logo AXP" /></div>
            <div className="logo-glass-container"><img className="grand-portfolio-logo" src="https://www.gowiththegurus.de/images/logos/BAC_ROUND_WHITE.svg" alt="Logo BAC" /></div>
            <div className="logo-glass-container"><img className="grand-portfolio-logo" src="https://www.gowiththegurus.de/images/logos/KO_ROUND_WHITE.svg" alt="Logo KO" /></div>
            <div className="logo-glass-container"><img className="grand-portfolio-logo" src="https://www.gowiththegurus.de/images/logos/MSFT_ROUND_WHITE.svg" alt="Logo MSFT" /></div>
            <div className="logo-glass-container"><img className="grand-portfolio-logo" src="https://www.gowiththegurus.de/images/logos/MCO_ROUND_WHITE.svg" alt="Logo MCO" /></div>
            <div className="logo-glass-container"><img className="grand-portfolio-logo" src="https://www.gowiththegurus.de/images/logos/CVX_ROUND_WHITE.svg" alt="Logo CVX" /></div>
            <div className="logo-glass-container"><img className="grand-portfolio-logo" src="https://www.gowiththegurus.de/images/logos/OXY_ROUND_WHITE.svg" alt="Logo OXY" /></div>
            <div className="logo-glass-container"><img className="grand-portfolio-logo" src="https://www.gowiththegurus.de/images/logos/KHC_ROUND_WHITE.svg" alt="Logo GE" /></div>
            <div className="logo-glass-container"><img className="grand-portfolio-logo" src="https://www.gowiththegurus.de/images/logos/BRK_ROUND_WHITE.svg" alt="Logo KHC" /></div>
          </div>
          <div className="subline-three">
            Entdecke den Konsens der Gurus.
          </div>
          <div className="subline-three-one">
          Erhalte eine umfassende Bestandsaufnahme der gebündelten Portfolios führender Investoren. Entdecke, welche Positionen aktuell von den bekanntesten Gurus gehalten werden, und nutze diese Einblicke als Grundlage für fundierte Entscheidungen.

          </div>
          <div className="guru-button-three-text">
          <Link to={`/portfolio`} style={{color:"rgb(255, 123, 0)"}} >
              Zum Grand Portolio <img src={arrowOrange} className="sort-arrow-right" alt="Pfeil" />
            </Link>
            </div>
          <div className="wishlist-container">
            <div className="wishlist-headline">Dir fehlt ein Guru?</div>
            <div className="wishlist-headline-2">Trage hier den Namen ein und schlage einen Investor vor, den wir hinzufügen sollen.</div>
          <form name="wishlist" method="POST" data-netlify="true" className="wishlist-form">

          <input type="hidden" name="form-name" value="wishlist" />
          <input 
            name="pleaseTrack" 
            placeholder="Aerodyne Int." 
            required 
            className="wishlist-input" 
          /><br></br>
          <button type="submit" className="wishlist-button"> Wunsch senden <img src={arrowOrange} className="sort-arrow-right" alt="Pfeil" /></button>
        </form>
      </div>
        </div>
      </div>

      <Newsletter />
    </div>
  );
}

export default LandingPage;
