import "./css/Post1.css";
import { Helmet } from 'react-helmet-async';
import { useEffect } from "react";

function Post3() {



          // Tab-Titel mit document.title setzen
          useEffect(() => {
            document.title = "Go with the Gurus | 13F Filings und was wir wissen";
            window.scrollTo(0, 0); // Scrollt beim Laden der Seite nach oben
          }, []); // Leeres Array sorgt dafür, dass der Effekt nur einmal bei Mount ausgeführt wird


    return (
        
        <>

            <Helmet>
                <title>Go with the Gurus | 13F Filings und was wir wissen</title>
                <meta name="description" content="Aktueller Forschungsstand der 13F Filings. Wo stehen wir und was sind die Ergebnisse?" />
            </Helmet>
        

        


        <div className="blog-post-header-image-post-3">
            <div className="landingpage-iphone-text-container">
            <h1 style={{ margin:"10px"}}>Go with the Gurus</h1>
            <h5 style={{fontWeight:"300", margin:"0", fontSize:"16px"}}>Was ist das eigentlich?</h5>
            <h5 style={{fontWeight:"300", margin:"5px", color:"rgba(255, 255, 255, 0.5)"}}>08. März 2025</h5>
            </div>  
        </div>

        <div className="post-container-header-background">
        <div className="post-container-header">

<h2 style={{margin:"0", padding:"20px 0"}}>Inhalt</h2>
<p><a className="index-list" href="#The-Idea">Die Idee</a></p>
<p><a className="index-list" href="#Alternative-sources">Alternative Quellen</a></p>
</div>
</div>

        <div className="post-container">

            
            <div id="The-Idea" className="post-content">


            <h3>Die Idee</h3>
            Hi! 
            <br></br>
            Cool, dass du auf diese Seite gelangt bist.
            Im Folgenden will ich kurz auf die Idee von Go with the Gurus eingehen, ein paar Hinweise mitgeben und erklären, wie das ganze hier entstanden ist.
            <br></br>
            <br></br>
            Da ich schon länger die Portfolios bekannter Investoren verfolge und mir Finanzthemen, Data Science und Web-Programmierung eine Menge Freude bereiten,
            habe ich angefangen, die Daten dieser Gurus genauer anzuschauen. Auf der Suche nach der Antwort, ob die Gurus Outperformance erzielen, entstanden jede Menge Excel Makros und Dashboards. 
            Das wurde jedoch schnell zu undynamisch, sodass ich zu Python gewechselt bin. Die UX Möglichkeiten sind hier leider etwas beschränkt bzw. sehr wissenschaftlich, 
            sodass ich mich für ein visualisierung im Browser entschieden habe.  
            Und da ich mit Sicherheit nicht der Einzige bin, der die 13F Daten spannend finded, ist das Internet ein super Ort diese zu Teilen. 
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Was hat es mit dem Namen auf sich?</h4>
            Ich brauchte eine Domain um die Seite zu hosten. Aufgrund meiner mangelnden Kreativität, fragte ich meine Freundin nach einem Namen, auf welche Sie spontan mit "Go with the Gurus" antwortete. Ich sagte "ok das ist gut" und reservierte die Domain.<br></br>
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Sind eure Daten verlässlich?</h4>
            Wir geben uns alle Mühe, die Daten sorgfältig zu prüfen und von Fehlern auszuschließen. Dennoch können wir nicht garantieren, frei von Fehlern zu sein.<br></br>
            Wenn du einen Fehler entdeckst, würden wir uns freuen, wenn du uns diesen mitteilst, sodass wir schnellstmöglich reagieren können.
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Warum unterscheiden sich Eure Daten zu anderen Plattformen?</h4>
            In der Regel sollten unsere Daten identisch sein. Wir sehen jedoch, dass manche Anbieter ihre Daten filtern. Unsere Daten spiegeln 1:1 das bei der SEC eingereichte Filing wider.<br></br>
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Nutzt ihr KI?</h4>
            Ja zum Programmieren, Korrektur lesen, Zusammenfassen, Recherche, Prüfen von Berechnungen und der Formulierung.<br></br>
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Wie verdient ihr Geld?</h4>
            Wir sind werbefrei, verkaufen keine Daten und es gibt kein Abomodell. Sämtliche Daten sind frei zugänglich. <br></br>
            Go with the Gurus ist ein reines Hobbyprojekt und nicht kommerziell. <br></br>
            Es gibt jedoch eine Funktion, bei der ein digitaler Kaffee hinterlassen werden kann.<br></br>
            <br></br>
            <br></br>
            <h4 style={{margin:"10px 0px"}}>Wie weit reichen eure Daten zurück?</h4>
            Wir erweitern unseren Datenbestand ständig.<br></br>
            Das Ziel ist, alle Daten eines Gurus, die bei der SEC hinterlegt sind, abzubilden. Die Daten unterliegen einem händischen Qualitätscheck, weswegen wir keine 100% Automatisierung verwenden und nicht seit Tag 1 alle Daten zur Verfügung haben.<br></br>
            <br></br>
            <br></br>
       
               </div>

               <div id="Alternative-sources" className="post-content">


<h3>Alternative Quellen</h3>
Seiten, die wir empfehlen.
<br></br>
<a style={{fontWeight:900}} target="blank" href="https://www.sec.gov/data-research/sec-markets-data/form-13f-data-sets">SEC</a><br></br>
<a style={{fontWeight:900}} target="blank" href="https://www.dataroma.com">Dataroma</a><br></br>
<a style={{fontWeight:900}} target="blank" href="https://whalewisdom.com/">WhaleWisdom</a><br></br>
<a style={{fontWeight:900}} target="blank" href="https://gurufocus.com/">Gurufocus</a><br></br>
<a style={{fontWeight:900}} target="blank" href="https://13f.info/">13F.info</a><br></br>
<br></br>


        </div>
        </div>


        </>
    );

}

export default Post3;
