import React, { useState, useEffect } from 'react';
import './GuruCurrentPortfolioTable.css';
import usePortfolioData from '../backend/usePortfolioData';
import arrow from '../assets/arrow.svg';

const GuruCurrentPortfolioTable = () => {
  const { completePortfolioData} = usePortfolioData();

  const [dataIndex, setDataIndex] = useState(0); // Start mit den aktuellen Daten (Index 0)
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'value', direction: 'desc' });
  const [isExpanded, setIsExpanded] = useState(false); // Neuer Zustand für Aufklapp-/Zuklapp-Funktion
  const [expandedRow, setExpandedRow] = useState(null); // Zustand für die erweiterte Zeile
  const [isMobile, setIsMobile] = useState(false); // Zustand für die mobile Ansicht

  
  const portfolioData = completePortfolioData[dataIndex]; // Dynamische Auswahl basierend auf dataIndex
  const year = completePortfolioData[dataIndex]?.[0]?.YEAR_QUARTER?.split("_")[0] || "Unbekannt";
  const quarter = completePortfolioData[dataIndex]?.[0]?.YEAR_QUARTER?.split("_")[1] || "Unbekannt";


  // Prüfen, ob es sich um ein mobiles Gerät handelt
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000); // "Mobile" definiert als Bildschirmbreite <= 1000px
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleRowExpand = (index) => {
    if (!isMobile) return; // Keine Aktion in der Desktop-Version
    setExpandedRow(expandedRow === index ? null : index); // Toggle der Zeile
  };

  const parseValue = (value) => {
    if (typeof value === 'string') {
      if (value.includes('%')) {
        return parseFloat(value.replace('%', '').trim());
      } else if (value.includes('$')) {
        return parseFloat(value.replace('$', '').replace(',', '').trim());
      } else if (!isNaN(parseFloat(value))) {
        return parseFloat(value.trim());
      }
      return value.toLowerCase();
    }
    return value;
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatCurrency2Digits = (value) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const formatPercentage = (value) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  // Berechnung und Sortierung der Daten
  useEffect(() => {
    if (portfolioData && portfolioData.length > 0) {
      const totalValue = portfolioData.reduce((acc, item) => acc + parseValue(item.value || 0), 0);

      const withCalculatedData = portfolioData.map((item) => {
        const value = parseValue(item.value || 0).toFixed(2);
        const valuePerShare = item.sshPrnamt ? value / item.sshPrnamt : 0;

        return {
          ...item,
          valuePerShare: valuePerShare,
          valuePerShareFormatted: formatCurrency2Digits(valuePerShare),
          valueFormatted: formatCurrency(value),
          share: (value / totalValue), // Anteil als Dezimalzahl mit zwei Nachkommastellen
          sshPrnamtFormatted: formatCurrency(parseValue(item.sshPrnamt || 0)),
        };
      });

      const sorted = [...withCalculatedData].sort((a, b) => {
        const aValue = parseValue(a[sortConfig.key]);
        const bValue = parseValue(b[sortConfig.key]);

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        return typeof aValue === 'number' ? -1 : 1;
      });

      setSortedData(sorted);
    }
  }, [portfolioData, sortConfig]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };


  const displayedData = isExpanded ? sortedData : sortedData.slice(0, 10);


  let portfolioValue = 0;
  let portfolioHoldings = 0;
  if (portfolioData && Array.isArray(portfolioData)) {
      portfolioValue = portfolioData.reduce((accumulator, currentValue) => {
          return accumulator + Number(currentValue.value); // Konvertiere den Wert zu einer Zahl
      }, 0);
      portfolioHoldings = portfolioData.length
  }

  

  return (
    <>
      <div className="big-portfolio-info-container">
        <div className="big-portfolio-info-spot-container">
          <p style={{fontSize:"24px"}}><strong>AUM</strong> </p>
          <p style={{fontSize:"16px", fontWeight:"900", color:"rgba(255,255,255,1)"}}>{formatCurrency(portfolioValue / 1000000)} Mio. $</p>
        </div>
        <div className="big-portfolio-info-spot-container">
          <p style={{fontSize:"24px"}}><strong>HOLDINGS</strong> </p>
          <p style={{fontSize:"16px", fontWeight:"900", color:"rgba(255,255,255,1)"}}>{portfolioHoldings}</p>
        </div>
      </div>

      <div className="year-navigation">
        <span>
          <p style={{minWidth:"70px"}}><strong>{quarter}  {year} </strong></p>
        </span>
        <input 
          type="range" 
          min="0" 
          max={completePortfolioData.length - 1} 
          value={completePortfolioData.length - 1 - dataIndex} 
          onChange={(e) => setDataIndex(completePortfolioData.length - 1 - Number(e.target.value))} 
          className="year-slider"
        />
      </div>

      <div className="guru-current-table">
        <table>
          <thead>
            <tr>
              <th>Logo</th>
              <th onClick={() => handleSort('CUSIP_NAME')}>Name {sortConfig.key === 'CUSIP_NAME' && (sortConfig.direction === 'desc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('TICKER')}>SYMBOL {sortConfig.key === 'TICKER' && (sortConfig.direction === 'desc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
              <th onClick={() => handleSort('share')}>Anteil {sortConfig.key === 'share' && (sortConfig.direction === 'desc' ? <img src={arrow} className="sort-arrow-down" /> : <img src={arrow} className="sort-arrow-up" />)}</th>
              <th onClick={() => handleSort('value')}>AUM {sortConfig.key === 'value' && (sortConfig.direction === 'desc' ? <img src={arrow} className="sort-arrow-down" /> : <img src={arrow} className="sort-arrow-up" />)}</th>
              <th onClick={() => handleSort('sshPrnamt')}>Aktien {sortConfig.key === 'sshPrnamt' && (sortConfig.direction === 'desc' ? <img src={arrow} className="sort-arrow-down" /> : <img src={arrow} className="sort-arrow-up" />)}</th>
              <th onClick={() => handleSort('valuePerShare')}>Preis pro Aktie {sortConfig.key === 'valuePerShare' && (sortConfig.direction === 'desc' ? <img src={arrow} className="sort-arrow-down" /> : <img src={arrow} className="sort-arrow-up" />)}</th>
              <th onClick={() => handleSort('GICS_SECTOR')}>GICS {sortConfig.key === 'GICS_SECTOR' && (sortConfig.direction === 'desc' ? <img src={arrow} className="sort-arrow-up" /> : <img src={arrow} className="sort-arrow-down" />)}</th>
            </tr>
          </thead>
          <tbody>
            {displayedData.map((item, index) => (
              <React.Fragment key={index}>
                <tr
                  className="table-row"
                  onClick={() => toggleRowExpand(index)} // Beim Klicken auf Zeile expandieren
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    <img
                      src={`https://www.gowiththegurus.de/images/logos/${item.TICKER}.svg`}
                      className="holding-logo"
                      alt="Logo"
                      onError={(e) => (e.target.src = 'https://www.gowiththegurus.de/images/LOGO_BLACK.svg')}
                    />
                  </td>
                  <td>{item.CUSIP_NAME}</td>
                  <td>{item.TICKER}</td>
                  <td>{formatPercentage(item.share)}</td>
                  <td>{item.valueFormatted} $</td>
                  <td>{item.sshPrnamtFormatted}</td>
                  <td>{item.valuePerShareFormatted} $</td>
                  <td>{item.GICS_SECTOR}</td>
                </tr>
                {isMobile && expandedRow === index && (
                  <tr className="expanded-row mobile-only">
                    <td colSpan="3">
                      <div className="expanded-content">
                        <p><strong style={{opacity:0.8}}>Name</strong> {item.CUSIP_NAME}</p>
                        <p><strong style={{opacity:0.8}}>Anteil</strong> {formatPercentage(item.share)}</p>
                        <p><strong style={{opacity:0.8}}>Preis pro Aktie</strong> {item.valuePerShareFormatted} $</p>
                        <p><strong style={{opacity:0.8}}>Aktien</strong> {item.sshPrnamtFormatted}</p>
                        <p><strong style={{opacity:0.8}}>AUM</strong> {item.valueFormatted} $</p>
                        <p><strong style={{opacity:0.8}}>GICS Sektor</strong> {item.GICS_SECTOR}</p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {/* Expand/Collapse Controls */}
        <div className="expand-controls" style={{ display: portfolioHoldings <= 10 ? 'none' : 'flex' }}>
          <button onClick={handleToggleExpand} className={isExpanded ? 'expanded' : ''}>
            <div className="plus-icon"></div>
          </button>
        </div>
      </div>
    </>
  );
};

export default GuruCurrentPortfolioTable;
