import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Importiere useNavigate
import './Header.css';
import searchIcon from '../assets/searchIcon.svg';
import useMainData from '../backend/useMainData';

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const whiteLogoUrl = 'https://www.gowiththegurus.de/images/GWTG_LOGO.png';
    const { guruData } = useMainData();

    const navigate = useNavigate(); // useNavigate-Hook initialisieren

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    };

    const openSearchPopup = () => {
        setIsPopupOpen(true);
    };

    const closeSearchPopup = () => {
        setIsPopupOpen(false);
        setSearchInput("");
        setFilteredSuggestions([]);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        setShowPopup(!menuOpen);
    };

    const handleSearchInputChange = (e) => {
        const input = e.target.value;
        setSearchInput(input);

        if (input.trim() !== "") {
            const suggestions = guruData.filter((guru) =>
                guru.NAME.toLowerCase().includes(input.toLowerCase())
            );
            setFilteredSuggestions(suggestions);
        } else {
            setFilteredSuggestions([]);
        }
    };

    useEffect(() => {
        let lastScrollY = window.scrollY;
        let scrollTimeout;

        const handleScroll = () => {
            setScrolling(true);
            clearTimeout(scrollTimeout);

            if (window.scrollY > lastScrollY) {
                setShowButton(false);
                if (menuOpen) setShowPopup(false);
            } else {
                setShowButton(true);
                if (menuOpen) setShowPopup(true);
            }

            scrollTimeout = setTimeout(() => {
                setScrolling(false);
            }, 200);

            lastScrollY = window.scrollY;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(scrollTimeout);
        };
    }, [menuOpen]);

    return (
        <header className="header">
            <div className="header-content">
                <Link to={`/`}> <img src={whiteLogoUrl} alt="Company Logo" className="logo" /></Link>
                <ul className="menu-list">

                    <li className="nav-item"><Link to="/watchaguru/1067983/Warren%20Buffett" onClick={toggleMenu}>Guru Portfolio</Link></li>
                    <li className="nav-item"><Link to="/gurus" onClick={toggleMenu}>Alle Gurus</Link></li>
                    <li className="nav-item"><Link to="/portfolio" onClick={toggleMenu}>Grand Portfolio</Link></li>
                    <li className="nav-item"><Link to="/stockpage/AAPL" onClick={toggleMenu}>Aktien</Link></li>
                </ul>
                <div className="search-container">
                    <span className="search-icon" onClick={openSearchPopup}>
                        <img src={searchIcon} alt="Search Icon"></img>
                    </span>
                </div>
                {isPopupOpen && (
                    <div className="popup-overlay" onClick={closeSearchPopup}>
                        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                            <div>Suche nach einem Guru</div>
                            <input
                                className="popup-search"
                                type="text"
                                placeholder="Suche"
                                value={searchInput}
                                onChange={handleSearchInputChange}
                                style={{ position: "relative" }}
                            />
                        </div>

                        {filteredSuggestions.length > 0 && (
                            <ul className="suggestions-list">
                                {filteredSuggestions.map((guru, index) => (
                                    <li
                                        key={index}
                                        className="suggestion-item"
                                        onClick={() => {
                                            // Navigiere zur entsprechenden Seite mit der CIK
                                            navigate(`/watchaguru/${guru.CIK}/${guru.NAME}`);
                                            closeSearchPopup(); // Popup schließen
                                        }}
                                    >
                                        <p style={{ display: "flex", width:"100%", justifyContent: "space-between" }}>
                                            <strong>{guru.NAME}</strong>{formatCurrency(guru.VALUE / 1000000)} Mio. USD
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            </div>

            <button
                className={`menu-toggle ${menuOpen ? 'open' : ''} ${showButton ? 'visible' : 'hidden'} ${scrolling ? 'scrolling' : ''}`}
                onClick={toggleMenu}
            >
                <span></span>
                <span></span>
            </button>
            {menuOpen && (
                <>
                    <div className="menu-overlay" onClick={toggleMenu}></div>
                    <div className="menu-popup">
                        <ul className="nav-list">
                        <li className="nav-item"><Link to="/" onClick={toggleMenu}>Home</Link></li>
                            <li className="nav-item"><Link to="/gurus" onClick={toggleMenu}>Gurus</Link></li>
                            <li className="nav-item"><Link to="/watchaguru/1067983/Warren%20Buffett" onClick={toggleMenu}>Guru Portfolio</Link></li>
                            <li className="nav-item"><Link to="/portfolio" onClick={toggleMenu}>Grand Portfolio</Link></li>
                            <li className="nav-item"><Link to="/stockpage/AAPL" onClick={toggleMenu}>Aktien</Link></li>
                        </ul>
                    </div>
                </>
            )}
        </header>
    );
}

export default Header;