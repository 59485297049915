import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import NotFoundPage from './NotFoundPage';
import GuruOverviewPage from './GuruOverview/GuruOverviewPage';
import BigPortfolioPage from './BigPortfolioPage/BigPortfolioPage';
import GuruPage from './GuruPage/GuruPage';
import LandingPage from './LandingPage/LandingPage';
import Impressum from './Impressum/Impressum';
import StockPage from './Stocks/StockPage';
import FaqPage from './FaqPage/FaqPage';
import Datenschutz from './Datenschutz/Datenschutz';

import Post1 from './blog/blogposts/Post1';
import Post2 from './blog/blogposts/Post2';
import Post3 from './blog/blogposts/Post3';
import DevKantesaria from './blog/blogposts/DevKantesaria';

import Header from './Header/Header';
import Footer from './Footer/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/gurus" element={<GuruOverviewPage />} />
          <Route path="/portfolio" element={<BigPortfolioPage />} />
          <Route path="/watchaguru/:cik/:name" element={<GuruPage />} /> {/* Dynamische Route */}
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/stockpage/:ticker" element={<StockPage />} />
          <Route path="/blog/was-kaufen-die-gurus-q4-2024" element={<Post1 />} />
          <Route path="/blog/13F-filings-aktueller-Forschungsstand" element={<Post2 />} />
          <Route path="/blog/" element={<Post3 />} />
          <Route path="/blog/Dev Kantesaria – Vom Harvard-Mediziner zum Hedgefonds-Manager/" element={<DevKantesaria />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
