import './Footer.css';
import KofiWidget from '../widgets/KofiWidget/KofiWidget';
import { Link } from 'react-router-dom';


function Footer() {

    const whiteLogoUrl = 'https://www.gowiththegurus.de/images/GWTG_LOGO.png';
    const instagramIcon = 'https://www.gowiththegurus.de/images/icons/instagram_white.svg';
    const xIcon = 'https://www.gowiththegurus.de/images/icons/x_white.svg';
    const threadsIcon = 'https://www.gowiththegurus.de/images/icons/threads_white.svg';

    return (
        <>
        <footer className="footer">
            <KofiWidget/>
            <div className="footer-content">
                {/* Über uns */}
                <div className="footer-section">
                    <h4>ABOUT</h4>
                    <p>
                    Hier findest du übersichtlich aufbereitete 13F-Daten, die dir einen Blick in die Strategien großer Investoren ermöglichen. Ziel ist es, die Daten verständlich und visuell ansprechend darzustellen, damit du die relevanten Informationen schnell erfassen kannst.

Dieses Projekt ist ein Hobby, das aus der Begeisterung für Finanzmärkte entstanden ist. Es soll dir helfen, die Entscheidungen der „Big Player“ besser nachzuvollziehen und dich bei deiner eigenen Strategie zu unterstützen. Viel Freude beim Nutzen der Seite.</p>
                </div>

                {/* Wichtige Links */}
                <div className="footer-section">
                    <h4>Meistgesuchte Investoren</h4>
                    <ul>
                    <li><Link to="watchaguru/1067983/Warren%20Buffett">Warren Buffett</Link></li>
                    <li><Link to="watchaguru/1166559/Bill%20Gates">Bill and Melinda Gates</Link></li>
                    <li><Link to="watchaguru/1336528/Bill%20Ackman">Bill Ackman</Link></li>
                    <li><Link to="watchaguru/1649339/Michael%20Burry">Michael Burry</Link></li>
                    <li><Link to="watchaguru/1647251/Chris%20Hohn">Chris Hohn</Link></li>
                    </ul>
                </div>

                {/* Kontakt */}
                <div className="footer-section">
                <h4>LEGAL</h4>
                <ul>
                    <li><a href="mailto:hi@gowiththegurus.de">hi@gowiththegurus.de</a></li>
                    <li><Link to="/faq">Häufig gestellte Fragen</Link></li>
                    <li><Link to="/impressum">Impressum</Link></li>
                    <li><Link to="/datenschutz">Datenschutz</Link></li>
                    </ul>
                </div> 


            </div>
            <div className='disclaimer'>
Die Informationen, die Du auf dieser Webseite findest, basieren auf öffentlich zugänglichen 13F-Daten der SEC. Ich habe mir große Mühe gegeben, diese Daten sorgfältig aufzubereiten, zu überprüfen und visuell darzustellen, damit Du sie möglichst einfach und verständlich nutzen kannst. Trotz aller implementierten automatischen und manuellen Prüfungen können jedoch Fehler passieren. Es ist möglich, dass Daten falsch verarbeitet, unvollständig oder ungenau dargestellt werden.
Bitte bedenke, dass die Inhalte auf dieser Webseite ausschließlich zu Informationszwecken dienen. Sie sind nicht dazu gedacht, Dir eine konkrete Anlageberatung zu bieten, Dich zu Investitionen zu verleiten oder Dir Handlungsempfehlungen für den Kauf oder Verkauf von Finanzinstrumenten zu geben. Wenn Du finanzielle Entscheidungen triffst, solltest Du immer mehrere Informationsquellen nutzen und im Idealfall einen professionellen Berater hinzuziehen, der Deine persönliche finanzielle Situation und Deine Ziele kennt.
Ich möchte außerdem betonen, dass ich keine Gewähr für die Richtigkeit, Vollständigkeit oder Aktualität der hier dargestellten Daten übernehmen kann. Die Informationen sind mit bestem Wissen und Gewissen erstellt, aber da ich mit großen Datenmengen arbeite und auch technische Systeme ihre Grenzen haben, können Fehler oder Unstimmigkeiten nie vollständig ausgeschlossen werden. Du solltest die Daten daher immer kritisch hinterfragen und nicht blind darauf vertrauen.
Bitte sei Dir auch bewusst, dass die auf dieser Webseite dargestellten historischen Daten und Informationen keine Garantie oder verlässlichen Indikatoren für zukünftige Entwicklungen darstellen. Finanzmärkte sind komplex und können von vielen unvorhersehbaren Faktoren beeinflusst werden.
Ich übernehme keine Haftung für Schäden oder Verluste, die Dir möglicherweise durch die Nutzung der Inhalte auf dieser Webseite entstehen. Du nutzt diese Informationen auf eigenes Risiko, und ich empfehle Dir, immer mit Bedacht und Vorsicht zu handeln.
Falls Du Fragen oder Bedenken zu den dargestellten Daten hast, stehe ich Dir gerne zur Verfügung. Transparenz und Genauigkeit sind mir wichtig, und ich bin stets bemüht, eventuelle Fehler so schnell wie möglich zu korrigieren, wenn sie bekannt werden.
<br></br>
<br></br>
Die Informationen, die Du durch 13F-Filings erhältst, basieren auf öffentlich zugänglichen Berichten, die von institutionellen Investoren vierteljährlich bei der SEC eingereicht werden. Diese Berichte bieten Dir wertvolle Einblicke in die Portfolios von großen Fondsmanagern, Hedgefonds und anderen institutionellen Investoren. Sie können Dir helfen, Strategien zu erkennen, langfristige Trends zu analysieren und von der Expertise der Profis zu lernen. Allerdings solltest Du bedenken, dass 13F-Filings nicht ohne Einschränkungen sind.

Ein großer Vorteil von 13F-Filings ist, dass sie Dir einen Einblick in die Anlagestrategien institutioneller Investoren ermöglichen. Du kannst nachvollziehen, welche Aktien und Wertpapiere von den großen Akteuren bevorzugt werden und wie sich deren Portfolios über die Zeit entwickeln. Zudem bieten sie Transparenz am Markt, da sie einen Teil der Aktivitäten dieser Investoren offenlegen und Dir damit ein besseres Verständnis der Kapitalmärkte vermitteln.

Ein weiterer Vorteil ist, dass die Daten kostenlos zugänglich sind und eine erschwingliche Möglichkeit darstellen, wertvolle Informationen zu gewinnen. Für langfristige Anleger bieten 13F-Filings die Chance, über mehrere Quartale hinweg Entwicklungen zu beobachten und Trends zu erkennen, die für Deine eigene Anlagestrategie hilfreich sein können.

Trotz dieser Vorteile haben 13F-Filings auch Nachteile und Limitationen, die Du beachten solltest. Die Berichte geben keine Auskunft über alle Arten von Wertpapieren. Zum Beispiel werden Short-Positionen, Derivate, Devisen oder private Investments nicht offengelegt. Dadurch ergibt sich ein unvollständiges Bild der tatsächlichen Strategie der institutionellen Investoren. Außerdem sind die Daten nicht aktuell, da sie mit einer Verzögerung von bis zu 45 Tagen nach Quartalsende veröffentlicht werden. In schnelllebigen Märkten kann dies bedeuten, dass die Informationen bereits veraltet sind, wenn sie öffentlich werden.

Ein weiterer wichtiger Punkt ist, dass 13F-Filings keine Begründungen oder Kontext für die getätigten Investments liefern. Du siehst zwar, welche Positionen aufgebaut oder abgebaut wurden, aber nicht, warum diese Entscheidungen getroffen wurden. Ohne das „Warum“ kann es schwierig sein, die Entscheidungen der Profis vollständig zu verstehen oder auf Deine eigene Strategie zu übertragen.

Schließlich solltest Du Dir bewusst sein, dass große institutionelle Investoren oft eine langfristige Perspektive haben, die sich von Deinen eigenen Zielen und Möglichkeiten unterscheiden kann. Nur weil ein Fonds eine bestimmte Aktie hält, bedeutet das nicht automatisch, dass sie für Dich ebenfalls geeignet ist.

Die Nutzung von 13F-Filings kann eine hilfreiche Ergänzung für Deine Investment-Recherche sein, aber sie sollte niemals Deine einzige Informationsquelle sein. Ich empfehle Dir, diese Daten immer kritisch zu hinterfragen, mit anderen Informationsquellen abzugleichen und gegebenenfalls professionelle Beratung in Anspruch zu nehmen.


</div>

<div className='footer-container'>
<div className='footer-logo'>
<Link to={`/`}> <img src={whiteLogoUrl} alt="Company Logo" className="logo" /></Link>
</div>
<div className='social-container'>
<a target='blank' href='https://www.instagram.com/gwtgurus/'>
<div className='social-icon'>
<img style={{height:"20px"}} src={instagramIcon} />
</div>
</a>
<a target='blank' href='https://x.com/gowiththegurus'>
<div className='social-icon'>
<img style={{height:"20px"}} src={xIcon} />
</div>
</a>
<a target='blank' href='https://www.threads.net/@gwtgurus'>
<div className='social-icon'>
<img style={{height:"20px"}} src={threadsIcon} />
</div>
</a>
</div>
</div>


        </footer>
        </>
    );
}

export default Footer;
