import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Für Parameter aus der URL

const useGuruInfoData = () => {
  const { cik } = useParams(); // Holt die CIK aus der URL
  const url = `https://www.gowiththegurus.de/data/${cik}/${cik}_guru_info.json`;

  const [guruInfoData, setGuruInfoData] = useState(null); // Initialer Zustand angepasst
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const controller = new AbortController(); // Um Abbruchsignale zu ermöglichen
    const { signal } = controller;

    const loadData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(url, { signal });

        if (!response.ok) {
          throw new Error(`HTTP-Fehler! Status: ${response.status}`);
        }

        const data = await response.json();
        setGuruInfoData(data);
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError(`Fehler beim Laden der Daten: ${err.message}`);
        }
      } finally {
        setLoading(false);
      }
    };

    loadData();

    // Cleanup: Abbrechen, wenn die Komponente unmounted wird
    return () => controller.abort();
  }, [url]);

  return { guruInfoData, loading, error };
};

export default useGuruInfoData;
