import React, { useEffect } from "react";
import "./BigPortfolioPage.css";
import useMainData from "../backend/useMainData";
import BigPortfolioTable from "./BigPortfolioTable";

function BigPortfolioPage() {
  const { guruData } = useMainData();

  // Tab-Titel mit document.title setzen
  useEffect(() => {
    document.title = "Grand Portfolio der Guru Investoren";
    window.scrollTo(0, 0); // Scrollt beim Laden der Seite nach oben
  }, []); // Leeres Array sorgt dafür, dass der Effekt nur einmal bei Mount ausgeführt wird

  return (
    <div className="main-container">
      <BigPortfolioTable guruData={guruData} shownRows={10} headerAdd={""} />
    </div>
  );
}

export default BigPortfolioPage;
